import React from "react";
import ExclusiveBenefitsCard from "./ExclusiveBenefitsCard";
import { SharedPageTitle } from "../../shared/Text";
import DepositBonusSvg from "../../../assets/DepositBonus";
import SpendBonusSvg from "../../../assets/SpendBonus";
import PriorityOnboardingSvg from "../../../assets/PriorityOnboarding";
import SeamlessIntegrationSvg from "../../../assets/SeamlessIntegration";

function ExclusiveBenefits() {
    return (
        <div className="p-[60px]">
            <div className="mb-[40px] text-center">
                <SharedPageTitle>
                    Exclusive Benefits Only Available To Flychain Clients
                </SharedPageTitle>
            </div>
            <div className="flex flex-col gap-y-[50px] w-full">
                <div className="flex flex-row justify-center w-full gap-x-[70px]">
                    <ExclusiveBenefitsCard
                        icon={
                            <DepositBonusSvg
                                height={30}
                                width={30}
                                fill="#8D58FF"
                            />
                        }
                        title="$500 Cash Bonus"
                        body="Enjoy a $500 cash bonus after depositing $10K into Mercury within 90 days of opening your account."
                    />
                    <ExclusiveBenefitsCard
                        icon={
                            <SpendBonusSvg
                                height={30}
                                width={30}
                                fill="#8D58FF"
                            />
                        }
                        title="Additional $500 Cash Bonus"
                        body="Receive an additional $500 cash bonus after spending $10K on the Mercury IO credit card within 90 days of receiving the card."
                    />
                </div>
                <div className="flex flex-row justify-center w-full gap-x-[70px]">
                    <ExclusiveBenefitsCard
                        icon={
                            <PriorityOnboardingSvg
                                height={30}
                                width={30}
                                fill="#8D58FF"
                            />
                        }
                        title="Priority Onboarding and Customer Support"
                        body="Mercury will help you migrate your payments, payroll, and other services from your previous bank to Mercury."
                    />
                    <ExclusiveBenefitsCard
                        icon={
                            <SeamlessIntegrationSvg
                                height={30}
                                width={30}
                                fill="#8D58FF"
                            />
                        }
                        title="Seamless Integration With Flychain"
                        body="Mercury has a native integration with the Flychain platform enabling a seamless exchange of data."
                    />
                </div>
            </div>
        </div>
    );
}

export default ExclusiveBenefits;
