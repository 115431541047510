import React from "react";
import PropTypes from "prop-types";
import useGetBusinessEntityQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntityQuery";

function BusinessEntityName({ businessEntityId, providerId, className }) {
    const { data: businessEntity } = useGetBusinessEntityQuery({
        businessEntityId,
        providerId,
    });

    if (!businessEntity) return null;

    return <div className={className}>{businessEntity.legal_name}</div>;
}

BusinessEntityName.propTypes = {
    businessEntityId: PropTypes.string.isRequired,
    providerId: PropTypes.string,
    className: PropTypes.string,
};

BusinessEntityName.defaultProps = {
    providerId: undefined,
    className: "",
};

export default BusinessEntityName;
