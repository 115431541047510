import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import SelectedProvider from "../../FinancialReports/SelectedProvider";
import AccountMappingTable from "./AccountMappingTable";
import { SharedAlert, SharedButton } from "../../../shared";
import { SharedPageTitle } from "../../../shared/Text";
import TriangleAlertSvg from "../../../../assets/TriangleAlert";
import useGetProviderPath from "../../../../hooks/useGetProviderPath";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import { useGetPlaidAccountMappingsQuery } from "../../../../redux/slices/adminApi";

function OnboardingAccountMapping() {
    const { providerId } = useParams();
    const providerPath = useGetProviderPath();
    const navigate = useNavigate();
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        includeIncomplete: true,
    });
    const { data: plaidAccountMappings } =
        useGetPlaidAccountMappingsQuery(providerId);

    if (!businessEntities || !plaidAccountMappings) {
        return null;
    }

    const navigateToFinalChecks = () => {
        navigate(`${providerPath}/onboard/finalChecks`);
    };

    return (
        <div>
            <SelectedProvider providerId={providerId} />
            <div className="flex flex-col px-[60px] pb-[60px] pt-[40px] gap-y-[30px]">
                <SharedPageTitle>Account Mapping</SharedPageTitle>
                <SharedAlert
                    icon={
                        <TriangleAlertSvg
                            fill="#8D58FF"
                            width={18}
                            height={18}
                        />
                    }
                    message="Ensure each Plaid account is linked to the correct business entity per the bookkeepers' spreadsheet. Failure to do so will break future linking."
                    borderColor="border-flychainPurple-light"
                />
                <AccountMappingTable
                    title="Active Accounts"
                    defaultOpen
                    businessEntities={businessEntities}
                    plaidAccountMappings={plaidAccountMappings.filter(
                        (plaidAccountMapping) => plaidAccountMapping.is_active
                    )}
                />
                <AccountMappingTable
                    title="Inactive Accounts"
                    defaultOpen={false}
                    businessEntities={businessEntities}
                    plaidAccountMappings={plaidAccountMappings.filter(
                        (plaidAccountMapping) => !plaidAccountMapping.is_active
                    )}
                />
                <SharedButton onClick={navigateToFinalChecks}>
                    Proceed to Final Checks
                </SharedButton>
            </div>
        </div>
    );
}

export default OnboardingAccountMapping;
