import React from "react";
import UnconfirmedOffersTable from "./UnconfirmedOffersTable";
import ConfirmedOffersTable from "./ConfirmedOffersTable";
import UnfundedOffersTable from "./UnfundedOffersTable";

function ViewOffers() {
    return (
        <div className="flex flex-col gap-y-[30px] pb-[60px]">
            <UnconfirmedOffersTable />
            <ConfirmedOffersTable />
            <UnfundedOffersTable />
        </div>
    );
}

export default ViewOffers;
