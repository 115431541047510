import React from "react";
import PropTypes from "prop-types";
import { SharedButton } from "../../shared";
import { SharedPageTitle } from "../../shared/Text";

function HeaderSection({ title, body, buttonText, onClick, graphic }) {
    return (
        <div className="flex flex-row bg-flychainOffWhite p-[60px]">
            <div className="flex flex-col gap-y-[30px] flex-1">
                <SharedPageTitle>{title}</SharedPageTitle>
                <div className="font-normal text-[14px]">{body}</div>
                <SharedButton onClick={onClick}>{buttonText}</SharedButton>
            </div>
            <div className="flex flex-row flex-1 justify-center items-center">
                {graphic}
            </div>
        </div>
    );
}

HeaderSection.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    graphic: PropTypes.node.isRequired,
};

export default HeaderSection;
