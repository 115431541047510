import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getCurrencyString, MMMM_D_YYYY_FORMAT } from "../../../utils";
import { OfferStatus } from "../../../constants";
import { SharedButton, SharedCard, SharedBackIconButton } from "../../shared";
import { SharedPageTitle, SharedSectionTitle } from "../../shared/Text";
import OfferConfirmationModal from "./OfferConfirmationModal";
import BusinessEntityName from "../../BusinessEntity/BusinessEntityName";
import useGetOfferQuery from "../../../hooks/queriesAndMutations/useGetOfferQuery";
import useConfirmOfferMutation from "../../../hooks/queriesAndMutations/useConfirmOfferMutation";
import { useGetOrCreateUserQuery } from "../../../redux/slices/api";

const ADMIN_EMAIL = "admin@flychain.us";

dayjs.extend(utc);

function ReviewOffer() {
    const { offerId, providerId } = useParams();
    const navigate = useNavigate();
    const [confirmOffer] = useConfirmOfferMutation();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const { data: offer } = useGetOfferQuery({ offerId });
    const { data: user } = useGetOrCreateUserQuery();

    if (!offer || !user) {
        return null;
    }

    // If the user is not associated with the offer and is not an admin, redirect to the capital page
    if (user.providerId !== offer.provider_id && user.email !== ADMIN_EMAIL) {
        navigate("/capital");
    }

    const onConfirmOffer = () => {
        confirmOffer({ offer_id: offerId });
        setShowSuccessModal(true);
    };

    const renderPaymentTableRows = () =>
        offer.payment_dates.map((paymentDate, index) => {
            const paymentAmount = offer.payment_amounts_cents[index];
            return (
                <div
                    key={index}
                    className="grid grid-cols-[1fr_2fr_1fr] gap-x-4 text-sm items-center border-b border-gray-200 py-[12px]"
                >
                    <div>{index + 1}</div>
                    <div>
                        {dayjs(paymentDate).utc().format(MMMM_D_YYYY_FORMAT)}
                    </div>
                    <div>{getCurrencyString(paymentAmount)}</div>
                </div>
            );
        });

    return (
        <div className="flex flex-col p-[60px] gap-y-[40px]">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row items-center gap-x-[20px] ">
                    {providerId && <SharedBackIconButton />}
                    <SharedPageTitle>
                        Review Your Financing Offer
                    </SharedPageTitle>
                </div>
                {offer.status === OfferStatus.UNCONFIRMED ? (
                    <SharedButton onClick={onConfirmOffer}>
                        Confirm Offer
                    </SharedButton>
                ) : (
                    <div className="flex flex-row gap-x-[10px] items-center">
                        <div className="text-[16px] font-semibold text-flychainGray">
                            Offer Status:
                        </div>
                        <div className="text-[18px] font-semibold">
                            {offer.status}
                        </div>
                    </div>
                )}
            </div>
            <SharedCard className="flex flex-col gap-y-[20px]">
                <SharedSectionTitle className="text-flychainGray">
                    Offer Details
                </SharedSectionTitle>
                <div className="flex flex-row w-full px-[30px] justify-between">
                    <div className="flex flex-col gap-y-[10px] items-center w-1/4">
                        <p className="font-semibold text-flychainGray text-[16px]">
                            Business Entity
                        </p>
                        <BusinessEntityName
                            businessEntityId={offer.business_entity_id}
                            className="font-semibold text-[24px] text-center"
                        />
                    </div>
                    <div className="flex flex-col gap-y-[10px] items-center w-1/4">
                        <p className="font-semibold text-flychainGray text-[16px]">
                            Principal
                        </p>
                        <p className="font-semibold text-[24px]">
                            {getCurrencyString(offer.principal_cents)}
                        </p>
                    </div>
                    <div className="flex flex-col gap-y-[10px] items-center w-1/4">
                        <p className="font-semibold text-flychainGray text-[16px]">
                            Interest ({(offer.fee_percentage * 100).toFixed(2)}
                            %)
                        </p>
                        <p className="font-semibold text-[24px]">
                            {getCurrencyString(offer.fee_cents)}
                        </p>
                    </div>
                    <div className="flex flex-col gap-y-[10px] items-center w-1/4">
                        <p className="font-semibold text-flychainGray text-[16px]">
                            Total to Repay
                        </p>
                        <p className="font-semibold text-[24px]">
                            {getCurrencyString(
                                offer.principal_cents + offer.fee_cents
                            )}
                        </p>
                    </div>
                </div>
            </SharedCard>
            <SharedCard className="flex flex-col gap-y-[20px]">
                <SharedSectionTitle className="text-flychainGray">
                    Repayment Schedule
                </SharedSectionTitle>
                <div className="flex flex-col gap-y-[10px]">
                    <div className="flex flex-row gap-x-[16px] items-center">
                        <div className="font-semibold text-flychainGray text-[16px]">
                            Total repayments:
                        </div>
                        <div className="font-semibold text-[20px]">
                            {offer.payment_dates.length}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="grid grid-cols-[1fr_2fr_1fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                            <div>No.</div>
                            <div>Repayment Date</div>
                            <div>Repayment Amount</div>
                        </div>
                        {renderPaymentTableRows()}
                    </div>
                </div>
            </SharedCard>
            {showSuccessModal && <OfferConfirmationModal />}
        </div>
    );
}

export default ReviewOffer;
