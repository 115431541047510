import React from "react";
import { SharedBanner } from "../shared";
import { MERTZ_TAGGART_INTRO_CALL_URL } from "./ValuationEstimate";

function ValuationCall() {
    const onClick = () => {
        // TODO: replace this with a Calendly link once Michael creates it.
        window.open(MERTZ_TAGGART_INTRO_CALL_URL, "_blank");
    };
    return (
        <SharedBanner
            title="Schedule a Free Valuation"
            body="If you'd like a comprehensive valuation of your business,
            please schedule some time with one of our M&A experts. We'll work
            with you to develop a strategic playbook to best position
            yourself for a future sale. Every valuation is performed
            using real time data from the healthcare marketplace."
            buttonText="Schedule a Free Valuation"
            buttonOnClick={onClick}
        />
    );
}

export default ValuationCall;
