import React from "react";
import { PropTypes } from "prop-types";
import PlusSvg from "../../../../../assets/Plus.svg";

function DrawsTablePlusButton({ onClick }) {
    return (
        <div className="cursor-pointer" onClick={onClick}>
            <img className="w-[15px]" src={PlusSvg} alt="Plus" />
        </div>
    );
}

DrawsTablePlusButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default DrawsTablePlusButton;
