import React from "react";
import { useDispatch } from "react-redux";
import raiseError from "../../redux/slices/error/thunks/raiseError";
import { useGetOrCreateUserQuery } from "../../redux/slices/api";
import ProviderAuthenticated from "../ProviderAuthenticated";
import AdminDashboard from "../AdminDashboard";
import PartnerAdminDashboard from "../PartnerAdminDashboard";

const ADMIN_EMAIL = "admin@flychain.us";
const ADMIN_EMAIL_SUFFIX = "@admin.flychain.us";

const getAdminOrProviderDashboard = (user) => {
    if (user.email === ADMIN_EMAIL) {
        return <AdminDashboard />;
    }
    if (user.email.endsWith(ADMIN_EMAIL_SUFFIX)) {
        return <PartnerAdminDashboard />;
    }
    return <ProviderAuthenticated />;
};

function FirebaseAuthenticated() {
    const { data: user } = useGetOrCreateUserQuery();

    const dispatch = useDispatch();

    const triggerError = () => {
        const err = new Error("Triggering test error");
        dispatch(raiseError(err));
    };

    return user ? (
        <>
            {getAdminOrProviderDashboard(user)}
            <div
                className="fixed right-0 bottom-0 w-[20px] h-[20px]"
                onClick={triggerError}
                aria-hidden="true"
            />
        </>
    ) : null;
}

export default FirebaseAuthenticated;
