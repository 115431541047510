import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../utils";
import { SharedLabeledText } from "../../../../shared/Text";

function Summary({ merchantDetail }) {
    return (
        <div className="flex flex-row gap-x-[100px]">
            <SharedLabeledText
                label="Past 6 Months Total"
                text={getCurrencyString(merchantDetail.six_month_total_cents)}
            />
            <SharedLabeledText
                label="Recurring"
                text={merchantDetail.recurring_period || "Not Recurring"}
            />
        </div>
    );
}

Summary.propTypes = {
    merchantDetail: PropTypes.object.isRequired,
};

export default Summary;
