/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import InstitutionHeader from "../../../PlaidItemHeader/InstitutionHeader";
import AccountsConnected from "../../../PlaidItemHeader/AccountsConnected";
import PlaidAccountRadio from "./PlaidAccountRadio";

function PlaidAccountsRadioGroup({ plaidItems, onChange, currentValue }) {
    return (
        <>
            {plaidItems.map((plaidItem) => {
                const {
                    item: { item_id },
                    accounts,
                } = plaidItem;
                return (
                    <div
                        key={item_id}
                        className="flex flex-col justify-start my-[30px] gap-y-[10px]"
                    >
                        <InstitutionHeader
                            plaidItem={plaidItem}
                            plaidItemStatus={
                                <AccountsConnected
                                    numberOfAccounts={accounts.length}
                                />
                            }
                        />
                        {accounts.map((account) => {
                            const { account_id } = account;
                            return (
                                <PlaidAccountRadio
                                    key={account_id}
                                    account={account}
                                    onChange={onChange}
                                    currentValue={currentValue}
                                />
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
}

PlaidAccountsRadioGroup.propTypes = {
    plaidItems: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    onChange: PropTypes.func.isRequired,
    currentValue: PropTypes.string.isRequired,
};

export default PlaidAccountsRadioGroup;
