import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationSubtab from "./NavigationSubtab";

function NavigationTab({ path, label, icon, subtabs }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(path);
    };

    const location = useLocation();

    const showSubtabs = location.pathname.startsWith(path);

    return (
        <div className="flex flex-col">
            <div
                className={`h-[45px] flex flex-row items-center justify-start cursor-pointer pl-[40px] gap-x-[10px] ${
                    path === location.pathname
                        ? "bg-gradient-to-tr from-flychainBlue to-flychainPurple-light"
                        : ""
                }`}
                onClick={onClick}
            >
                <div className="flex flex-col justify-center w-[15px]">
                    {icon}
                </div>
                <div className="text-white text-[18px] font-[400]">{label}</div>
            </div>
            {showSubtabs
                ? subtabs.map((subtab) => (
                      <NavigationSubtab
                          key={subtab.path}
                          label={subtab.label}
                          path={`${path}${subtab.path}`}
                      />
                  ))
                : null}
        </div>
    );
}

NavigationTab.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    subtabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        }).isRequired
    ),
};

NavigationTab.defaultProps = {
    subtabs: [],
};

export default NavigationTab;
