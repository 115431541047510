import React from "react";
import PropTypes from "prop-types";
import { SharedCard } from "../../shared";
import { SharedSectionTitle } from "../../shared/Text";
import BusinessEntity from "./BusinessEntity";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";

function Provider({ provider }) {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId: provider.id,
        includeIncomplete: false,
    });

    if (!businessEntities) {
        return null;
    }

    return (
        <SharedCard padding={15} className="flex flex-col gap-y-[15px]">
            <div className="flex flex-row justify-between items-center gap-x-[10px]">
                <SharedSectionTitle>{provider.legal_name}</SharedSectionTitle>
                <div className="text-sm text-flychainGray">{provider.id}</div>
            </div>
            <div className="flex flex-col gap-y-[15px]">
                {businessEntities.map((businessEntity) => (
                    <BusinessEntity
                        key={businessEntity.id}
                        businessEntity={businessEntity}
                        provider={provider}
                    />
                ))}
            </div>
        </SharedCard>
    );
}

Provider.propTypes = {
    provider: PropTypes.object.isRequired,
};

export default Provider;
