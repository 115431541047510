import React from "react";
import PropTypes from "prop-types";

const TAB_COLORS = {
    green: "linear-gradient(35.22deg, #CCE6F0 0%, #DBEAC3 100%)",
    yellow: "linear-gradient(56.11deg, #DBEAC3 0%, #F8CE39 129.84%)",
    purple: "linear-gradient(35.34deg, #EAE5F2 0%, #BF9FFF 100%)",
    "blue-purple": "linear-gradient(288.15deg, #BF9FFF -8.11%, #CDE6F0 62.55%)",
    grey: "#F2F2F2",
    blue: "#CDE6F0",
};

function Card({
    children,
    showTab,
    tabText,
    tabColor,
    className,
    padding,
    tabContainerClassName,
}) {
    const paddingClass = `p-[${padding}px]`;

    if (showTab) {
        return (
            <div className={`flex flex-col ${tabContainerClassName}`}>
                <div
                    className="self-end rounded-t-xl text-sm font-semibold p-[8px]"
                    style={{ background: TAB_COLORS[tabColor] || tabColor }}
                >
                    {tabText}
                </div>
                <div
                    className={`shadow-lg border-[1px] rounded-[12px_0px_12px_12px] ${paddingClass} ${className}`}
                >
                    {children}
                </div>
            </div>
        );
    }

    return (
        <div
            className={`rounded-xl shadow-lg border-[1px] ${paddingClass} ${className}`}
        >
            {children}
        </div>
    );
}

Card.propTypes = {
    children: PropTypes.node.isRequired,
    showTab: PropTypes.bool,
    tabText: PropTypes.string,
    tabColor: PropTypes.string,
    className: PropTypes.string,
    padding: PropTypes.number,
    tabContainerClassName: PropTypes.string,
};

Card.defaultProps = {
    showTab: false,
    tabText: undefined,
    tabColor: undefined,
    className: "",
    padding: 24,
    tabContainerClassName: "",
};

export default Card;
