import { useParams } from "react-router-dom";
import { useGetBusinessEntityQuery as useAdminGetBusinessEntityQuery } from "../../../redux/slices/adminApi";
import { useGetBusinessEntityQuery as useProviderGetBusinessEntityQuery } from "../../../redux/slices/api";

const useGetBusinessEntityQuery = (params) => {
    // Get the providerId and businessEntityId from the params
    let providerId = params.providerId || null;
    const { businessEntityId } = params;

    const urlParams = useParams();

    // If providerId is not provided in function params, try to get it from the url params
    if (!providerId) {
        providerId = urlParams.providerId || null;
    }

    // If providerId is provided via function params or url params, use the admin query
    if (providerId) {
        return useAdminGetBusinessEntityQuery({
            providerId,
            businessEntityId,
        });
    }

    // If providerId is not provided at all, use the provider query. Provider id will be passed in via the request's user context
    return useProviderGetBusinessEntityQuery({
        businessEntityId,
    });
};

export default useGetBusinessEntityQuery;
