import React from "react";
import { SharedSectionTitle, SharedSubheading } from "../shared/Text";

function Mobile() {
    return (
        <div className="flex flex-col justify-center h-[100vh] w-[100vw]">
            <SharedSectionTitle>
                Our application is currently only supported on desktop.
            </SharedSectionTitle>
            <SharedSubheading>
                Our application currently doesn&apos;t support mobile or tablet.
                Please try again from a desktop computer.
            </SharedSubheading>
        </div>
    );
}

export default Mobile;
