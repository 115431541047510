import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";

function StateTextInput({
    name,
    label,
    value,
    onChange,
    error,
    className,
    placeholder,
}) {
    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <FormControl
            name={name}
            className={className}
            variant="standard"
            error={!!error}
        >
            <TextField
                variant="standard"
                onChange={handleChange}
                value={value}
                label={label}
                error={!!error}
                helperText={error}
                placeholder={placeholder}
                sx={{
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "#8D58FF",
                    },
                }}
                InputLabelProps={{
                    sx: {
                        "&.Mui-focused": {
                            color: "#8D58FF",
                        },
                    },
                }}
            />
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

StateTextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    error: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
};

StateTextInput.defaultProps = {
    label: undefined,
    error: "",
    className: "",
    placeholder: "",
};

export default StateTextInput;
