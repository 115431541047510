import React from "react";
import PropTypes from "prop-types";
import DrawsTable, { drawShape } from "../DrawsTable";
import RepaymentsTable from "./RepaymentsTable";
import { SharedModal } from "../../../../shared";
import { SharedPageTitle, SharedSectionTitle } from "../../../../shared/Text";

function DrawModal({ draw, onClose }) {
    return (
        <SharedModal onClose={onClose}>
            <div className="flex flex-col justify-start max-h-[85vh] overflow-y-auto scrollbar scrollbar-thumb-flychainPurple-light gap-y-[30px] pb-[20px]">
                <SharedPageTitle>{`Draw on ${draw.draw_funding_date}`}</SharedPageTitle>
                <SharedSectionTitle className="mt-[20px]">
                    Summary of draw
                </SharedSectionTitle>
                <DrawsTable draws={[draw]} includeSeeRepaymentsColumn={false} />
                <SharedSectionTitle className="mt-[20px]">
                    Repayments for draw
                </SharedSectionTitle>
                <RepaymentsTable repayments={draw.draw_payments} />
            </div>
        </SharedModal>
    );
}

DrawModal.propTypes = {
    draw: drawShape.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DrawModal;
