import React from "react";
import PropTypes from "prop-types";
import { SharedButton } from "../shared";
import { SharedPageTitle } from "../shared/Text";

function ConfirmSuccessfulSchedule({ onClose }) {
    return (
        <div className="flex flex-col justify-start gap-x-[20px]">
            <SharedPageTitle>Thank you for scheduling a call!</SharedPageTitle>
            <div className="text-[16px]">
                A calendar invite has been sent to your email address - we look
                forward to chatting with you soon. If you need to reschedule,
                you can use the reschedule link in the confirmation email or
                calendar invite.
            </div>
            <div className="flex flex-row justify-end mt-[20px]">
                <SharedButton onClick={onClose}>Done</SharedButton>
            </div>
        </div>
    );
}

ConfirmSuccessfulSchedule.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ConfirmSuccessfulSchedule;
