import React, { useState } from "react";
import CalendlyModal from "../../CalendlyModal";
import { SharedButton } from "../../shared";
import { SharedPageTitle } from "../../shared/Text";
import TreasuryGraphic from "../../../assets/TreasuryGraphic.svg";
import { CALENDLY_URLS } from "../../CalendlyModal/constants";

function TreasuryHeaderSection() {
    const [showCalendlyModal, setShowCalendlyModal] = useState(false);

    const onClickSignUp = () => {
        window.open("https://app.treasurefi.com/sign-up/Flychain", "_blank");
    };

    const onClickLogin = () => {
        window.open("https://app.treasurefi.com/sign-in", "_blank");
    };

    return (
        <div className="flex flex-row p-[60px] bg-flychainOffWhite">
            <div className="flex flex-col flex-1 gap-y-[30px]">
                <SharedPageTitle>
                    Institutional Treasury Management
                </SharedPageTitle>
                <div className="text-[14px]">
                    Traditional financial institutions typically don&apos;t
                    offer sophisticated treasury services to small to
                    medium-sized healthcare providers. Our treasury management
                    service offers the yield, safety, and liquidity of
                    institutional-grade solutions within a simple platform.
                    Treasury management and investments are provided by Treasure
                    Investment Management (“Treasure”).
                </div>
                <div className="flex flex-row gap-x-[10px]">
                    <SharedButton onClick={() => setShowCalendlyModal(true)}>
                        Learn More
                    </SharedButton>
                    <SharedButton onClick={onClickSignUp} color="secondary">
                        Sign Up
                    </SharedButton>
                    <SharedButton onClick={onClickLogin} color="secondary">
                        Login
                    </SharedButton>
                </div>
            </div>
            <div className="flex flex-row flex-1 justify-center items-center">
                <img
                    className="w-[25vw]"
                    src={TreasuryGraphic}
                    alt="TreasurySvg"
                />
            </div>
            {showCalendlyModal && (
                <CalendlyModal
                    onClose={() => setShowCalendlyModal(false)}
                    calendlyUrl={CALENDLY_URLS.TREASURY_CALL}
                />
            )}
        </div>
    );
}

export default TreasuryHeaderSection;
