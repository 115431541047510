import React from "react";
import PropTypes from "prop-types";

const getInstitutionNameAndLogo = (institution) => {
    if (institution) {
        return {
            institutionName: institution.name,
            institutionLogo: institution.logo,
        };
    }
    return {
        institutionName: "Unknown Institution",
        institutionLogo: null,
    };
};

function InstitutionHeader({ plaidItem, plaidItemStatus }) {
    const {
        item: { institution },
    } = plaidItem;

    const { institutionName, institutionLogo } =
        getInstitutionNameAndLogo(institution);

    return (
        <div className="flex flex-row items-center w-fit gap-x-[10px]">
            {institutionLogo && (
                <img
                    className="w-[40px] h-[40px]"
                    src={`data:image/png;base64,${institutionLogo}`}
                    alt="Instituton Logo"
                />
            )}
            <div className="flex flex-col">
                <div className="text-[18px] font-[500]">{institutionName}</div>
                {plaidItemStatus}
            </div>
        </div>
    );
}

InstitutionHeader.propTypes = {
    plaidItem: PropTypes.object.isRequired,
    plaidItemStatus: PropTypes.node.isRequired,
};

export default InstitutionHeader;
