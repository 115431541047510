import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

function NavigationSubtab({ path, label }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(path);
    };

    const location = useLocation();

    return (
        <div
            className={`flex flex-row justify-start items-center cursor-pointer h-[35px] pl-[80px] ${
                location.pathname.startsWith(path)
                    ? "bg-gradient-to-tr from-flychainBlue to-flychainPurple-light"
                    : ""
            }`}
            onClick={onClick}
        >
            <div className="text-white text-[14px] font-[400]">{label}</div>
        </div>
    );
}

NavigationSubtab.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default NavigationSubtab;
