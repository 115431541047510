import React from "react";
import PropTypes from "prop-types";

const getBackgroundColor = (color) => {
    switch (color) {
        case "green":
            return "bg-flychainGreen";
        case "grey":
            return "bg-flychainGrey";
        case "red":
            return "bg-flychainError";
        default:
            throw new Error("Invalid color specified");
    }
};

function Badge({ color, children }) {
    return (
        <div
            className={`inline-block rounded-full px-[8px] py-[4px] ${getBackgroundColor(
                color
            )}`}
        >
            {children}
        </div>
    );
}

Badge.propTypes = {
    color: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
        .isRequired,
};

export default Badge;
