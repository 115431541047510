import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import TriangleAlertSvg from "../../../assets/TriangleAlert";
import { MMMM_D_YYYY_FORMAT } from "../../../utils";

function UnverifiedDataWarningDropdown({ validUpUntil }) {
    return (
        <div className="flex flex-row w-full items-start bg-[#F2F2F2] shadow-md p-[12px] rounded-b-[10px] gap-x-[8px]">
            <div>
                <TriangleAlertSvg fill="#8D58FF" width={16} height={16} />
            </div>
            <div className="text-[10px] text-flychainGray">
                This data includes unreviewed transactions. Please exercise
                caution when looking at data after{" "}
                {dayjs(validUpUntil).format(MMMM_D_YYYY_FORMAT)}.
            </div>
        </div>
    );
}

UnverifiedDataWarningDropdown.propTypes = {
    validUpUntil: PropTypes.string.isRequired,
};

export default UnverifiedDataWarningDropdown;
