import { createApi } from "@reduxjs/toolkit/query/react";
import createBaseQuery from "../apiUtils";
import {
    transformGetOrCreateUserResponse,
    getPlaidLinkTokenUrl,
    getTopExpensesGraphUrl,
    getRevenueByCategoryUrl,
    getFinancialReportsSummaryUrl,
    getIncomeStatementUrl,
    getBalanceSheetUrl,
    getCashFlowReportUrl,
    getLedgerLinesUrl,
    getDownloadIncomeStatementAsyncUrl,
    getDownloadBalanceSheetAsyncUrl,
    getDownloadCashFlowReportAsyncUrl,
    getMonthlyLedgerTotalsUrl,
} from "./utils";

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: createBaseQuery(false),
    tagTypes: ["provider", "plaidItems", "businessEntity"],
    endpoints: (builder) => ({
        getOrCreateUser: builder.query({
            query: () => "/v1/user",
            transformResponse: (data) => transformGetOrCreateUserResponse(data),
        }),
        getProvider: builder.query({
            query: () => "/v1/provider",
            providesTags: ["provider"],
        }),
        upsertProvider: builder.mutation({
            query: (body) => ({
                url: "/v1/provider",
                method: "POST",
                body,
            }),
            invalidatesTags: ["provider"],
        }),
        getPlaidLinkToken: builder.query({
            query: (args) => getPlaidLinkTokenUrl(args),
        }),
        getPlaidItems: builder.query({
            query: () => "/v1/plaid/items",
            providesTags: ["plaidItems"],
        }),
        savePlaidItems: builder.mutation({
            query: () => ({
                url: "/v1/plaid/items",
                method: "POST",
            }),
            invalidatesTags: ["provider"],
        }),
        getPlaidItemsWithOnlyCheckingAccounts: builder.query({
            query: () => "/v1/plaid/items/checking_accounts",
            /*
                getPlaidItemsWithOnlyCheckingAccounts uses a separate endpoint from getPlaidItems so the results returned
                will be stored in separate caches. See the link below for more detail on the behavior of providesTags and
                invalidatesTags and how tags affect cached data:
                https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
            */
            providesTags: ["plaidItems"],
        }),
        addPlaidItem: builder.mutation({
            query: (body) => ({
                url: "/v1/plaid/link",
                method: "POST",
                body,
            }),
            invalidatesTags: ["plaidItems"],
        }),
        updatePlaidItem: builder.mutation({
            query: (body) => ({
                url: "/v1/plaid/item",
                method: "PUT",
                body,
            }),
            invalidatesTags: ["plaidItems"],
        }),
        addProviderBankAccount: builder.mutation({
            query: (body) => ({
                url: "/v1/provider/bank_account",
                method: "POST",
                body,
            }),
            invalidatesTags: ["provider"],
        }),
        getOffer: builder.query({
            query: ({ offerId }) => `/v1/offer/${offerId}`,
        }),
        updateOffer: builder.mutation({
            query: (body) => ({
                url: "/v1/provider/offer",
                method: "PUT",
                body,
            }),
            invalidatesTags: ["provider"],
        }),
        confirmOffer: builder.mutation({
            query: (body) => ({
                url: "/v1/offer/confirm",
                method: "PATCH",
                body,
            }),
        }),
        getDataForAdvancedPaymentOnClaims: builder.query({
            query: () => "/v1/platform/capital",
        }),
        getLineForAdvancedPaymentOnClaims: builder.query({
            query: () => "/v1/platform/capital/line",
        }),
        getDataForNetCashFlowGraph: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/cash_flow_graph?business_entity_id=${businessEntityId}`,
        }),
        getDataForCashBalanceGraph: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/cash_balance_graph?business_entity_id=${businessEntityId}`,
        }),
        getDataForNetProfitGraph: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/net_profit_graph?business_entity_id=${businessEntityId}`,
        }),
        getDataForTopExpensesGraph: builder.query({
            query: (args) => getTopExpensesGraphUrl(args),
        }),
        getDataForFinancialReportsSummary: builder.query({
            query: (args) => getFinancialReportsSummaryUrl(args),
        }),
        getBalanceSheet: builder.query({
            query: (args) => getBalanceSheetUrl(args),
        }),
        getCashFlowReport: builder.query({
            query: (args) => getCashFlowReportUrl(args),
        }),
        getDataForLedgerLines: builder.query({
            query: (args) => getLedgerLinesUrl(args),
        }),
        getSavings: builder.query({
            query: ({ businessEntityId }) =>
                `/v1/platform/savings?business_entity_id=${businessEntityId}`,
        }),
        getPlatformSubscription: builder.query({
            query: () => "/v1/platform/subscription",
        }),
        getBusinessEntities: builder.query({
            query: ({ includeIncomplete }) =>
                `/v1/provider/business_entities?include_incomplete=${includeIncomplete}`,
            providesTags: ["businessEntities"],
        }),
        getBusinessEntity: builder.query({
            query: ({ businessEntityId }) =>
                `/v1/provider/business_entity/${businessEntityId}`,
            providesTags: (result, error, { businessEntityId }) => [
                { type: "businessEntity", businessEntityId },
            ],
        }),
        updateBusinessEntity: builder.mutation({
            query: ({ businessEntityId, ...body }) => ({
                url: `/v1/provider/business_entities/${businessEntityId}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result, error, { businessEntityId }) => [
                { type: "businessEntity", businessEntityId },
                "businessEntities",
            ],
        }),
        createBusinessEntity: builder.mutation({
            query: (body) => ({
                url: "/v1/provider/business_entities",
                method: "POST",
                body,
            }),
            invalidatesTags: ["businessEntities"],
        }),
        getFinancialReportsValidUpUntil: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/valid_up_until?business_entity_id=${businessEntityId}`,
        }),
        downloadIncomeStatementAsync: builder.query({
            query: (args) => ({
                url: getDownloadIncomeStatementAsyncUrl(args),
            }),
        }),
        downloadBalanceSheetAsync: builder.query({
            query: (args) => ({
                url: getDownloadBalanceSheetAsyncUrl(args),
            }),
        }),
        downloadCashFlowReportAsync: builder.query({
            query: (args) => ({
                url: getDownloadCashFlowReportAsyncUrl(args),
            }),
        }),
        getExpensesByMerchant: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/expenses_by_merchant?business_entity_id=${businessEntityId}`,
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
        getRevenueByCategory: builder.query({
            query: (args) => getRevenueByCategoryUrl(args),
        }),
        getMonthlyRevenueByCategory: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/revenue_by_category/stacked_graph?business_entity_id=${businessEntityId}`,
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
        getMonthlyExpensesByCategory: builder.query({
            query: (businessEntityId) =>
                `/v1/platform/financial_reports/expenses_by_category/stacked_graph?business_entity_id=${businessEntityId}`,
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
        getMonthlyLedgerTotals: builder.query({
            query: (args) => getMonthlyLedgerTotalsUrl(args),
        }),
        getPartner: builder.query({
            query: () => "/v1/partner",
        }),
        getValuation: builder.query({
            query: () => "/v1/platform/valuation",
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
        getIncomeStatement: builder.query({
            query: (args) => getIncomeStatementUrl(args),
        }),
        getMercurySignupLink: builder.mutation({
            query: (body) => ({
                url: "/v1/platform/banking/mercury_signup_link",
                method: "POST",
                body,
            }),
        }),
        getFinancingReportForBusinessEntity: builder.query({
            query: ({ businessEntityId }) =>
                `/v1/platform/capital/business_entity?business_entity_id=${businessEntityId}`,
        }),
        getLineForBusinessEntity: builder.query({
            query: ({ businessEntityId }) =>
                `/v1/platform/capital/line/v2?business_entity_id=${businessEntityId}`,
        }),
        getUsers: builder.query({
            query: () => "/v1/users",
        }),
    }),
});

export const {
    useGetOrCreateUserQuery,
    useGetProviderQuery,
    useUpsertProviderMutation,
    useLazyGetPlaidLinkTokenQuery,
    useGetPlaidItemsQuery,
    useSavePlaidItemsMutation,
    useGetPlaidItemsWithOnlyCheckingAccountsQuery,
    useAddPlaidItemMutation,
    useUpdatePlaidItemMutation,
    useAddProviderBankAccountMutation,
    useGetOfferQuery,
    useUpdateOfferMutation,
    useConfirmOfferMutation,
    useGetDataForAdvancedPaymentOnClaimsQuery,
    useGetLineForAdvancedPaymentOnClaimsQuery,
    useGetDataForNetCashFlowGraphQuery,
    useGetDataForCashBalanceGraphQuery,
    useGetDataForNetProfitGraphQuery,
    useGetDataForTopExpensesGraphQuery,
    useGetRevenueByCategoryQuery,
    useGetDataForFinancialReportsSummaryQuery,
    useGetBalanceSheetQuery,
    useGetCashFlowReportQuery,
    useGetDataForLedgerLinesQuery,
    useGetSavingsQuery,
    useGetPlatformSubscriptionQuery,
    useGetBusinessEntitiesQuery,
    useGetBusinessEntityQuery,
    useUpdateBusinessEntityMutation,
    useCreateBusinessEntityMutation,
    useGetFinancialReportsValidUpUntilQuery,
    useLazyDownloadIncomeStatementAsyncQuery,
    useLazyDownloadBalanceSheetAsyncQuery,
    useLazyDownloadCashFlowReportAsyncQuery,
    useLazyDownloadExpensesByCategoryAsyncQuery,
    useGetExpensesByMerchantQuery,
    useGetMonthlyRevenueByCategoryQuery,
    useGetMonthlyExpensesByCategoryQuery,
    useGetMonthlyLedgerTotalsQuery,
    useGetPartnerQuery,
    useGetValuationQuery,
    useGetIncomeStatementQuery,
    useGetMercurySignupLinkMutation,
    useGetFinancingReportForBusinessEntityQuery,
    useGetLineForBusinessEntityQuery,
    useGetUsersQuery,
} = apiSlice;

export const { getBusinessEntities, getFinancialReportsValidUpUntil } =
    apiSlice.endpoints;
