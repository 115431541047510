/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { SharedButton } from "../shared";
import usePlaidLink from "../../hooks/usePlaidLink";

function PlaidLinkButton({ plaidItemId, color }) {
    const { openPlaidLink } = usePlaidLink(plaidItemId);

    const buttonText = plaidItemId ? "Restore Connection" : "Add Connection";

    return (
        <SharedButton color={color} onClick={openPlaidLink}>
            {buttonText}
        </SharedButton>
    );
}

PlaidLinkButton.propTypes = {
    plaidItemId: PropTypes.string,
    color: PropTypes.string,
};

PlaidLinkButton.defaultProps = {
    plaidItemId: undefined,
    color: "primary",
};

export default PlaidLinkButton;
