import { useParams } from "react-router-dom";
import { useGetOfferQuery as useAdminGetOfferQuery } from "../../../redux/slices/adminApi";
import { useGetOfferQuery as useProviderGetOfferQuery } from "../../../redux/slices/api";

const useGetOfferQuery = ({ offerId }) => {
    const urlParams = useParams();
    const providerId = urlParams.providerId || null;

    // If providerId is provided via url params, use the admin query
    if (providerId) {
        return useAdminGetOfferQuery({ offerId });
    }

    // If providerId is not provided, use the provider query. Provider id will be passed in via the request's user context
    return useProviderGetOfferQuery({ offerId });
};

export default useGetOfferQuery;
