import React from "react";
import { Outlet, useParams } from "react-router-dom";
import Content from "../../Content";
import SideBar from "../../SideBar";
import NavigationTab from "../../SideBar/NavigationTab";
import CapitalSvg from "../../../assets/Capital";
import ProviderSelected from "./ProviderSelected";

// TODO: change specific icons for each tab.
function SideBarAndContent() {
    const { providerId } = useParams();

    return (
        <>
            <SideBar>
                <NavigationTab
                    path="/providers"
                    label="Providers"
                    icon={<CapitalSvg fill="white" />}
                />
                {providerId ? (
                    <ProviderSelected />
                ) : (
                    <>
                        <NavigationTab
                            path="/collectionFlow"
                            label="Collection"
                            icon={<CapitalSvg fill="white" />} // TODO: replace picture
                        />
                        <NavigationTab
                            path="/draws"
                            label="Draws"
                            icon={<CapitalSvg fill="white" />} // TODO: replace picture
                        />
                        <NavigationTab
                            path="/ExpiredPlaidItems"
                            label="Expired Plaid Items"
                            icon={<CapitalSvg fill="white" />} // TODO: replace picture
                        />
                        <NavigationTab
                            path="/testUsers"
                            label="Test Users"
                            icon={<CapitalSvg fill="white" />} // TODO: replace picture
                        />
                        <NavigationTab
                            path="/bookkeepingDates"
                            label="Bookkeeping Dates"
                            icon={<CapitalSvg fill="white" />} // TODO: replace picture
                        />
                        <NavigationTab
                            path="/generalLedgerDownload"
                            label="General Ledger Download"
                            icon={<CapitalSvg fill="white" />} // TODO: replace picture
                        />
                    </>
                )}
            </SideBar>
            <Content>
                <Outlet />
            </Content>
        </>
    );
}

export default SideBarAndContent;
