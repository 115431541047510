import React from "react";
import dayjs from "dayjs";
import { SharedAlert } from "../../shared";
import TriangleAlertSvg from "../../../assets/TriangleAlert";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import { MMMM_D_YYYY_FORMAT } from "../../../utils";

function ValidUpUntil() {
    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    if (!validUpUntilData) {
        return null;
    }

    const validUpUntilDate = dayjs(validUpUntilData.valid_up_until);
    const formattedDate = validUpUntilDate.format(MMMM_D_YYYY_FORMAT);
    const title = `Your books are valid up until ${formattedDate}`;
    const body = `Our bookkeepers have reviewed your books up until ${formattedDate}. Please exercise caution when looking at data after this date.`;

    return (
        <SharedAlert
            icon={<TriangleAlertSvg fill="#8D58FF" width={20} height={20} />}
            message={
                <div className="flex flex-col gap-y-[4px]">
                    <div className="text-[16px] font-semibold">{title}</div>
                    <div>{body}</div>
                </div>
            }
            borderColor="border-flychainPurple-light"
        />
    );
}

export default ValidUpUntil;
