import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../utils";
import { SharedLabeledText } from "../../../../shared/Text";

function Summary({ data }) {
    const startingCashBalance = data.starting_cash_balance.record;
    const endingCashBalance = data.ending_cash_balance.record;
    const netCashFlow = data.net_cash_flow.record;
    return (
        <div className="flex flex-row gap-x-[100px]">
            <SharedLabeledText
                label={startingCashBalance.name}
                text={getCurrencyString(startingCashBalance.total_amount_cents)}
            />
            <SharedLabeledText
                label={endingCashBalance.name}
                text={getCurrencyString(endingCashBalance.total_amount_cents)}
            />
            <SharedLabeledText
                label={netCashFlow.name}
                text={getCurrencyString(netCashFlow.total_amount_cents)}
            />
        </div>
    );
}

Summary.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Summary;
