/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import plaidErrorRequiresUserRelink from "./utils";
import InstitutionHeader from "./InstitutionHeader";
import AccountsConnected from "./AccountsConnected";
import PlaidLinkButton from "../PlaidLinkButton";

const getPlaidItemStatus = (plaidItem) => {
    const { is_admin_linked, plaid_error, accounts } = plaidItem;

    if (!plaid_error || !plaid_error.error_code || is_admin_linked) {
        return <AccountsConnected numberOfAccounts={accounts.length} />;
    }

    if (plaidErrorRequiresUserRelink(plaid_error)) {
        return (
            <div className="text-flychainError text-[14px] font-[400]">
                Broken Connection
            </div>
        );
    }

    return <AccountsConnected numberOfAccounts={accounts.length} />;
};

const getCallToAction = (plaidItem) => {
    const { is_admin_linked, plaid_error, internal_plaid_item_id } = plaidItem;

    if (!plaid_error || !plaid_error.error_code || is_admin_linked) {
        return null;
    }

    if (plaidErrorRequiresUserRelink(plaid_error)) {
        return <PlaidLinkButton plaidItemId={internal_plaid_item_id} />;
    }

    return null;
};

function ProviderPlaidItemHeader({ plaidItem }) {
    return (
        <div className="flex flex-row items-center gap-x-[20px] justify-between">
            <InstitutionHeader
                plaidItem={plaidItem}
                plaidItemStatus={getPlaidItemStatus(plaidItem)}
            />
            {getCallToAction(plaidItem)}
        </div>
    );
}

ProviderPlaidItemHeader.propTypes = {
    plaidItem: PropTypes.object.isRequired,
};

export default ProviderPlaidItemHeader;
