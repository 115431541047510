import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MONTHS, STATES } from "./constants";
import { blankStringToNull, notBlankString } from "../../utils";
import { SharedAlert, SharedButton, SharedCard } from "../shared";
import { SharedPageTitle, SharedSectionTitle } from "../shared/Text";
import {
    SharedFormTextInput,
    SharedFormSelectInput,
    SharedRequiredMessage,
} from "../shared/Inputs";
import TriangleAlertSvg from "../../assets/TriangleAlert";
import { useUpsertProviderMutation } from "../../redux/slices/api";
import {
    entityTypeOptions,
    specialtyOptions,
} from "../BusinessEntity/BuisnessEntityCreator";

const schema = yup
    .object({
        legalName: yup
            .string()
            .required(SharedRequiredMessage)
            .test(
                "not-whitespace-only",
                "Legal name cannot contain only whitespace",
                notBlankString
            ),
        dba: yup.string().nullable(),
        taxId: yup
            .string()
            .matches(/^\d{9}$/, "Must be 9 numbers (no hyphens)"),
        npi: yup
            .string()
            .matches(/^\d{10}$/, "Must be 10 numbers (no hyphens)"),
        specialty: yup.string().required(SharedRequiredMessage),
        businessStartDateMonth: yup.string().required(SharedRequiredMessage),
        businessStartDateYear: yup.string().matches(/^\d{4}$/, "Must be YYYY"),
        businessEntityType: yup.string().required(SharedRequiredMessage),
        streetAddress: yup
            .string()
            .required(SharedRequiredMessage)
            .test(
                "not-whitespace-only",
                "Street address cannot contain only whitespace",
                notBlankString
            ),
        city: yup
            .string()
            .required(SharedRequiredMessage)
            .test(
                "not-whitespace-only",
                "City cannot contain only whitespace",
                notBlankString
            ),
        state: yup.string().required(SharedRequiredMessage),
        postalCode: yup.string().matches(/^\d{5}$/, "Must be 5 numbers"),
        contactFullName: yup
            .string()
            .required(SharedRequiredMessage)
            .test(
                "not-whitespace-only",
                "Contact name cannot contain only whitespace",
                notBlankString
            ),
        contactEmail: yup.string().email("Invalid email address"),
        contactPhone: yup
            .string()
            .matches(
                /^\d{10,11}$/,
                "Must be 10 or 11 numbers (no parentheses or hypens)"
            ),
    })
    .required();

const defaultValues = {
    legalName: "",
    dba: null,
    taxId: "",
    npi: "",
    specialty: null,
    businessStartDateMonth: "",
    businessStartDateYear: "",
    businessEntityType: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    contactFullName: "",
    contactEmail: "",
    contactPhone: "",
};

function ProviderDetails() {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const [upsertProvider] = useUpsertProviderMutation();

    const onSubmit = (formData) => {
        const body = {
            legal_name: formData.legalName,
            dba: blankStringToNull(formData.dba),
            tax_id: formData.taxId,
            npi: formData.npi,
            specialty: formData.specialty,
            business_start_date_month: formData.businessStartDateMonth,
            business_start_date_year: formData.businessStartDateYear,
            business_entity_type: formData.businessEntityType,
            street_address: formData.streetAddress,
            city: formData.city,
            state: formData.state,
            postal_code: formData.postalCode,
            contact_full_name: formData.contactFullName,
            contact_email: formData.contactEmail,
            contact_phone: formData.contactPhone,
            partner_internal_name: "FLYCHAIN",
        };
        upsertProvider(body);
    };

    const triggerSubmit = () => {
        handleSubmit(onSubmit)();
    };

    return (
        <div className="flex flex-col p-[60px] gap-y-[30px]">
            <SharedPageTitle>Provide Business Details</SharedPageTitle>
            <SharedAlert
                icon={
                    <TriangleAlertSvg fill="#8D58FF" width={20} height={20} />
                }
                message="If you have more than one business entity, please fill out the information below for the parent entity."
                borderColor="border-flychainPurple-light"
            />
            <SharedCard padding={15} className="flex flex-col gap-y-[10px]">
                <SharedSectionTitle>Business Overview</SharedSectionTitle>
                <div className="flex flex-col gap-y-[10px]">
                    <SharedFormTextInput
                        name="legalName"
                        label="Business legal name *"
                        control={control}
                        error={errors.legalName}
                    />
                    <SharedFormTextInput
                        name="dba"
                        label="Doing business as (DBA)"
                        control={control}
                        error={errors.dba}
                    />
                    <SharedFormTextInput
                        name="taxId"
                        label="Business Tax ID/EIN *"
                        control={control}
                        error={errors.taxId}
                        placeholder="XXXXXXXXX"
                    />
                    <SharedFormTextInput
                        name="npi"
                        label="National Provider Identifier (NPI) *"
                        control={control}
                        error={errors.npi}
                        placeholder="XXXXXXXXXX"
                    />
                    <SharedFormSelectInput
                        name="businessStartDateMonth"
                        label="Business start date (month) *"
                        options={MONTHS}
                        control={control}
                        error={errors.businessStartDateMonth}
                    />
                    <SharedFormTextInput
                        name="businessStartDateYear"
                        label="Business start date (year) *"
                        control={control}
                        error={errors.businessStartDateYear}
                    />
                    <SharedFormSelectInput
                        name="businessEntityType"
                        label="Business entity type *"
                        options={entityTypeOptions}
                        control={control}
                        error={errors.businessEntityType}
                    />
                    <SharedFormSelectInput
                        name="specialty"
                        label="Specialty *"
                        options={specialtyOptions}
                        control={control}
                        error={errors.specialty}
                    />
                </div>
            </SharedCard>
            <SharedCard padding={15} className="flex flex-col gap-y-[10px]">
                <SharedSectionTitle>Business Address</SharedSectionTitle>
                <div className="flex flex-col gap-y-[10px]">
                    <SharedFormTextInput
                        name="streetAddress"
                        label="Street address *"
                        control={control}
                        error={errors.streetAddress}
                    />
                    <SharedFormTextInput
                        name="city"
                        label="City *"
                        control={control}
                        error={errors.city}
                    />
                    <SharedFormSelectInput
                        name="state"
                        label="State *"
                        options={STATES}
                        control={control}
                        error={errors.state}
                    />
                    <SharedFormTextInput
                        name="postalCode"
                        label="Postal code *"
                        control={control}
                        error={errors.postalCode}
                    />
                </div>
            </SharedCard>
            <SharedCard padding={15} className="flex flex-col gap-y-[10px]">
                <SharedSectionTitle>Contact Information</SharedSectionTitle>
                <div className="flex flex-col gap-y-[10px]">
                    <SharedFormTextInput
                        name="contactFullName"
                        label="Contact full name *"
                        control={control}
                        error={errors.contactFullName}
                    />
                    <SharedFormTextInput
                        name="contactEmail"
                        label="Contact email *"
                        control={control}
                        error={errors.contactEmail}
                    />
                    <SharedFormTextInput
                        name="contactPhone"
                        label="Contact phone number *"
                        control={control}
                        error={errors.contactPhone}
                    />
                </div>
            </SharedCard>
            <div className="flex flex-col gap-y-[10px]">
                <div className="flex flex-row justify-center">
                    <SharedButton onClick={triggerSubmit}>Submit</SharedButton>
                </div>
                {Object.keys(errors).length > 0 && (
                    <div className="flex flex-row justify-center">
                        <div className="text-[15px] font-[300] text-flychainError">
                            There was an error in the form. Errors are shown
                            under each field.
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProviderDetails;
