import React from "react";
import PropTypes from "prop-types";
import PaywallContent from "./PaywallContent";
import { useGetPlatformSubscriptionQuery } from "../../redux/slices/api";

function Paywall({ children }) {
    const { data: platformSubscription } = useGetPlatformSubscriptionQuery();

    if (platformSubscription === undefined) {
        return null;
    }

    if (!platformSubscription.has_platform_subscription) {
        return <PaywallContent />;
    }

    return children;
}

Paywall.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Paywall;
