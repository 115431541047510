import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePickerDropdown from "./DatePickerDropdown";
import DatePickerDisplay from "./DatePickerDisplay";
import useClickOutside from "../../../../hooks/useClickOutside";

function DatePicker({ date, onChange, maxDate, validUpUntil = maxDate }) {
    const [showDropdown, setShowDropdown] = useState(false);

    const onClickOutside = () => {
        setShowDropdown(false);
    };

    const containerRef = useClickOutside(onClickOutside);

    const onClick = () => {
        setShowDropdown(!showDropdown);
    };

    const onDateChange = (newDate) => {
        onChange(newDate);
        setShowDropdown(false);
    };

    return (
        <div ref={containerRef} className="inline-block relative">
            <DatePickerDisplay date={date} onClick={onClick} />
            {showDropdown && (
                <DatePickerDropdown
                    date={date}
                    onChange={onDateChange}
                    maxDate={maxDate}
                    validUpUntil={validUpUntil}
                />
            )}
        </div>
    );
}

DatePicker.propTypes = {
    date: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string,
};

DatePicker.defaultProps = {
    validUpUntil: undefined,
};

export default DatePicker;
