const REPORT_TYPES = {
    INCOME_STATEMENT: "INCOME_STATEMENT",
    BALANCE_SHEET: "BALANCE_SHEET",
    CASH_FLOW: "CASH_FLOW",
    EXPENSES_BY_CATEGORY: "EXPENSES_BY_CATEGORY",
    REVENUE_BY_CATEGORY: "REVENUE_BY_CATEGORY",
    GENERAL_LEDGER: "GENERAL_LEDGER",
};

export default REPORT_TYPES;
