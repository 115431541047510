import React from "react";
import PropTypes from "prop-types";
import FlychainLogoSvg from "../../assets/FlychainLogo2.svg";
import SideBarBottomMenu from "./SideBarBottomMenu";

function SideBar({ children, showLogo }) {
    return (
        <div className="flex flex-col h-full bg-flychainPurple-dark">
            {showLogo && (
                <div className="flex flex-row my-[20px] ml-[20px]">
                    <img className="w-4/5" src={FlychainLogoSvg} alt="Logo" />
                </div>
            )}
            <div className="flex overflow-y-auto flex-col flex-grow scrollbar scrollbar-thumb-flychainPurple-light scrollbar-track-flychainPurple-dark">
                {children}
                <div className="mt-auto">
                    <SideBarBottomMenu />
                </div>
            </div>
        </div>
    );
}

SideBar.propTypes = {
    children: PropTypes.node,
    showLogo: PropTypes.bool,
};

SideBar.defaultProps = {
    children: undefined,
    showLogo: true,
};

export default SideBar;
