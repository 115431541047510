import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ProviderPlatformState } from "../../../constants";
import SelectedProvider from "../FinancialReports/SelectedProvider";
import { SharedButton, SharedCard } from "../../shared";
import { SharedPageTitle, SharedSectionTitle } from "../../shared/Text";
import { SharedStateTextInput } from "../../shared/Inputs";
import useGetProviderPath from "../../../hooks/useGetProviderPath";
import {
    useGetProviderQuery,
    useSubmitSignedSaasAgreementMutation,
    useUpdatePlatformStateMutation,
} from "../../../redux/slices/adminApi";

function OnboardingFinalSteps() {
    const [signedSaasAgreementUrl, setSignedSaasAgreementUrl] = useState("");
    const { providerId } = useParams();
    const navigate = useNavigate();
    const providerPath = useGetProviderPath();
    const [submitSignedSaasAgreement] = useSubmitSignedSaasAgreementMutation();
    const [updatePlatformState] = useUpdatePlatformStateMutation();
    const { data: provider } = useGetProviderQuery(providerId);

    if (!provider) {
        return null;
    }

    const navigateToFinancialReports = () => {
        navigate(providerPath);
    };

    const handleSubmitSignedSaasAgreement = () => {
        submitSignedSaasAgreement({
            providerId,
            body: {
                signed_saas_agreement_url: signedSaasAgreementUrl,
            },
        });
    };

    const handleActivatePlatform = () => {
        updatePlatformState({
            providerId,
            body: { platform_state: ProviderPlatformState.PLATFORM_ACTIVE },
        });
    };

    return (
        <div>
            <SelectedProvider providerId={providerId} />
            <div className="flex flex-col px-[60px] pb-[60px] pt-[40px] gap-y-[30px]">
                <SharedPageTitle>Final Steps</SharedPageTitle>
                <SharedCard>
                    <div className="flex flex-col gap-y-[20px]">
                        <SharedSectionTitle>
                            1. Ensure all business entities and accounts are
                            connected
                        </SharedSectionTitle>
                        <div>
                            View the provider&apos;s financial reports and
                            ensure all entities and accounts look connected. Run
                            debug calls to see all transaction source accounts
                            etc.
                        </div>
                        <SharedButton
                            color="secondary"
                            onClick={navigateToFinancialReports}
                        >
                            Go to Financial Reports
                        </SharedButton>
                    </div>
                </SharedCard>
                <SharedCard>
                    <div className="flex flex-col gap-y-[20px]">
                        <SharedSectionTitle>
                            2. Begin bookkeeping for the provider
                        </SharedSectionTitle>
                        <div>
                            Inform Blubooks that bookkeeping can begin for the
                            provider. Once bookkeeping is complete then proceed
                            to step 3.
                        </div>
                    </div>
                </SharedCard>
                <SharedCard>
                    <div className="flex flex-col gap-y-[20px]">
                        <SharedSectionTitle>
                            3. Add signed SAAS agreement URL
                        </SharedSectionTitle>
                        {!provider.signed_saas_agreement_url ? (
                            <div className="flex flex-row justify-between w-full gap-x-[40px]">
                                <SharedStateTextInput
                                    className="w-full"
                                    name="signed_saas_agreement_url"
                                    label="Signed SAAS Agreement URL"
                                    value={signedSaasAgreementUrl}
                                    onChange={(newValue) =>
                                        setSignedSaasAgreementUrl(newValue)
                                    }
                                />
                                <SharedButton
                                    disabled={!signedSaasAgreementUrl}
                                    onClick={handleSubmitSignedSaasAgreement}
                                >
                                    Submit Signed SAAS Agreement
                                </SharedButton>
                            </div>
                        ) : (
                            <div className="flex flex-row w-full gap-x-[10px]">
                                <div className="font-semibold text-flychainGray">
                                    Signed SAAS Agreement URL:
                                </div>
                                <a
                                    href={provider.signed_saas_agreement_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="underline text-flychainPurple-light"
                                >
                                    {provider.signed_saas_agreement_url}
                                </a>
                            </div>
                        )}
                    </div>
                </SharedCard>
                <SharedCard>
                    <div className="flex flex-col gap-y-[20px]">
                        <SharedSectionTitle>
                            4. Activate platform for the provider
                        </SharedSectionTitle>
                        {provider.platform_state !== "PLATFORM_ACTIVE" ? (
                            <SharedButton
                                disabled={!provider.signed_saas_agreement_url}
                                onClick={handleActivatePlatform}
                            >
                                Activate Platform
                            </SharedButton>
                        ) : (
                            <div className="flex flex-row gap-x-[10px] items-center">
                                <div className="font-semibold text-flychainGray">
                                    Platform State:
                                </div>
                                <div className="font-bold text-flychainGreen">
                                    {provider.platform_state}
                                </div>
                            </div>
                        )}
                    </div>
                </SharedCard>
            </div>
        </div>
    );
}

export default OnboardingFinalSteps;
