import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

function FormTextInput({
    name,
    label,
    control,
    error,
    placeholder,
    className,
}) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                    className={className}
                    variant="standard"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || ""}
                    label={label}
                    error={error.message !== undefined}
                    helperText={error.message}
                    placeholder={placeholder}
                    sx={{
                        "& .MuiInput-underline:after": {
                            borderBottomColor: "#8D58FF",
                        },
                    }}
                    InputLabelProps={{
                        sx: {
                            "&.Mui-focused": {
                                color: "#8D58FF",
                            },
                        },
                    }}
                />
            )}
        />
    );
}

FormTextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    control: PropTypes.object.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

FormTextInput.defaultProps = {
    error: {},
    placeholder: "",
    label: undefined,
    className: "",
};

export default FormTextInput;
