import React from "react";
import PropTypes from "prop-types";
import AdminPlaidItemHeader from "./AdminPlaidItemHeader";
import ProviderPlaidItemHeader from "./ProviderPlaidItemHeader";
import useIsAdminDashboard from "../../hooks/useIsAdminDashboard";

function PlaidItemHeader({ plaidItem }) {
    const isAdminDashboard = useIsAdminDashboard();

    return isAdminDashboard ? (
        <AdminPlaidItemHeader plaidItem={plaidItem} />
    ) : (
        <ProviderPlaidItemHeader plaidItem={plaidItem} />
    );
}

PlaidItemHeader.propTypes = {
    plaidItem: PropTypes.object.isRequired,
};

export default PlaidItemHeader;
