import React from "react";
import PropTypes from "prop-types";

function Label({ children }) {
    return (
        <span className="font-[600] text-[13px] text-flychainGray">
            {children}
        </span>
    );
}

Label.propTypes = {
    children: PropTypes.string.isRequired,
};

export default Label;
