import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

function FormSelectInput({ name, label, options, control, error }) {
    const menuItems = options.map((option) => {
        const value = typeof option === "string" ? option : option.value;
        const itemLabel = typeof option === "string" ? option : option.label;

        return (
            <MenuItem key={value} value={value}>
                {itemLabel}
            </MenuItem>
        );
    });

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <FormControl
                    variant="standard"
                    error={error.message !== undefined}
                >
                    <InputLabel
                        sx={{
                            "&.Mui-focused": { color: "#8D58FF" },
                        }}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        label={label}
                        onChange={onChange}
                        value={value || ""}
                        sx={{
                            "&:after": {
                                borderBottomColor: "#8D58FF",
                            },
                        }}
                    >
                        {menuItems}
                    </Select>
                    <FormHelperText>{error.message}</FormHelperText>
                </FormControl>
            )}
        />
    );
}

FormSelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            }),
        ]).isRequired
    ).isRequired,
    control: PropTypes.object.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
};

FormSelectInput.defaultProps = {
    error: {},
};

export default FormSelectInput;
