/* eslint-disable camelcase */
import React, { useMemo } from "react";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../utils";
import { SharedTable } from "../shared";

const HEADERS = ["Category", "Description", "Date", "Amount"];

const CATEGORY_TO_GRADIENT = {
    FINANCING: "bg-gradient-to-tr from-flychainGreen-light to-[#F8CE39]",
    INSURANCE:
        "bg-gradient-to-tr from-flychainBlue-light to-flychainGreen-light",
    SUPPLIES: "bg-gradient-to-tr from-[#EAE5F2] to-[#BF9FFF]",
};

function getCategoryGradient(category) {
    let gradientClass = CATEGORY_TO_GRADIENT[category];
    if (!gradientClass) {
        Sentry.captureException(
            new Error(
                `No specified linear gradient for savings category ${category}`
            )
        );
        gradientClass = CATEGORY_TO_GRADIENT.FINANCING;
    }
    return gradientClass;
}

function SavingsTable({ savings }) {
    const rows = useMemo(
        () =>
            savings.map((saving) => [
                <div className="flex flex-row items-center gap-x-[10px]">
                    <div
                        className={`w-[36px] h-[36px] rounded-[100px] ${getCategoryGradient(
                            saving.category
                        )}`}
                    />
                    <div className="font-semibold text-[14px] text-flychainGray no-wrap">
                        {saving.category}
                    </div>
                </div>,
                saving.description,
                <div className="no-wrap">
                    {dayjs(saving.created_at).format("MMM D, YYYY")}
                </div>,
                getCurrencyString(saving.amount_cents),
            ]),
        [savings]
    );
    return <SharedTable headers={HEADERS} rows={rows} />;
}

SavingsTable.propTypes = {
    savings: PropTypes.arrayOf(
        PropTypes.shape({
            category: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            created_at: PropTypes.string.isRequired,
            amount_cents: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
};

export default SavingsTable;
