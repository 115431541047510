import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SharedButton } from "../../../../shared";
import { useUpdateLastPaymentMutation } from "../../../../../redux/slices/adminApi";
import { getCurrencyString, MMMM_D_YYYY_FORMAT } from "../../../../../utils";

dayjs.extend(utc);

const handleButtonClick = (
    paymentId,
    drawOutstandingBalanceCents,
    updateLastPayment,
    setSelectedPayments
) => {
    const body = {
        payment_id: paymentId,
    };

    updateLastPayment(body)
        .unwrap()
        .then(() => {
            setSelectedPayments((prevPayments) =>
                prevPayments.map((prevPayment) => {
                    if (prevPayment.id === paymentId) {
                        return {
                            ...prevPayment,
                            payment_amount: drawOutstandingBalanceCents,
                        };
                    }
                    return prevPayment; // Return the unchanged payment if there's no ID match
                })
            );
        });
};

function LastPaymentsTable({
    selectedPayments,
    setSelectedPayments,
    lastPaymentData,
}) {
    const [updateLastPayment] = useUpdateLastPaymentMutation();

    const renderTableRows = () =>
        lastPaymentData.map((lastPayment) => {
            const payment = selectedPayments.find(
                (p) => p.id === lastPayment.payment_id
            );

            if (!payment) {
                throw new Error(
                    `Payment not found for last payment: ${lastPayment.payment_id}`
                );
            }

            return (
                <div
                    key={payment.id}
                    className="grid grid-cols-[2.5fr_1fr_1.5fr_2.5fr_1fr_1fr] gap-x-[10px] text-sm items-center border-b border-gray-200 py-[12px]"
                >
                    <div>{payment.id}</div>
                    <div>{getCurrencyString(payment.payment_amount)}</div>
                    <div>
                        {dayjs(payment.earliest_payment_date)
                            .utc()
                            .format(MMMM_D_YYYY_FORMAT)}
                    </div>
                    <div>{payment.draw_id}</div>
                    <div>
                        {getCurrencyString(
                            lastPayment.draw_outstanding_balance_cents
                        )}
                    </div>
                    <div className="flex justify-center w-full">
                        <SharedButton
                            className="rounded-full w-fit px-[10px] py-[5px]"
                            onClick={() =>
                                handleButtonClick(
                                    payment.id,
                                    lastPayment.draw_outstanding_balance_cents,
                                    updateLastPayment,
                                    setSelectedPayments
                                )
                            }
                            disabled={
                                lastPayment.draw_outstanding_balance_cents ===
                                payment.payment_amount
                            }
                        >
                            Update Amount
                        </SharedButton>
                    </div>
                </div>
            );
        });

    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-[2.5fr_1fr_1.5fr_2.5fr_1fr_1fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                <div>Payment ID</div>
                <div>Amount</div>
                <div>Earliest Payment Date</div>
                <div>Draw ID</div>
                <div>Draw Balance</div>
                <div className="flex justify-center w-full">Update Payment</div>
            </div>
            {renderTableRows()}
        </div>
    );
}

LastPaymentsTable.propTypes = {
    selectedPayments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setSelectedPayments: PropTypes.func.isRequired,
    lastPaymentData: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default LastPaymentsTable;
