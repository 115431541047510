import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import getNumericRegex from "./utils";

function FormNumericInput({
    name,
    label,
    control,
    error,
    placeholder,
    className,
    decimal,
    currency,
}) {
    const handleChange = (e, onChange) => {
        const regex = getNumericRegex(decimal, currency);
        if (e.target.value === "") {
            onChange("");
        } else if (regex.test(e.target.value)) {
            onChange(e.target.value);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                    className={className}
                    variant="standard"
                    onChange={(e) => handleChange(e, onChange)}
                    onBlur={onBlur}
                    value={value || ""}
                    label={label}
                    error={error.message !== undefined}
                    helperText={error.message}
                    placeholder={placeholder}
                    sx={{
                        "& .MuiInput-underline:after": {
                            borderBottomColor: "#8D58FF",
                        },
                    }}
                    InputLabelProps={{
                        sx: {
                            "&.Mui-focused": {
                                color: "#8D58FF",
                            },
                        },
                    }}
                />
            )}
        />
    );
}

FormNumericInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    control: PropTypes.object.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    className: PropTypes.string,
    decimal: PropTypes.bool,
    currency: PropTypes.bool,
};

FormNumericInput.defaultProps = {
    error: {},
    placeholder: "",
    label: undefined,
    className: "",
    decimal: false,
    currency: false,
};

export default FormNumericInput;
