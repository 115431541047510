import React, { useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { SharedButton } from "../../../shared";
import {
    useGetProvidersQuery,
    useGetActiveBookkeeperSubscriptionsQuery,
} from "../../../../redux/slices/adminApi";

function PlaidItemsTable({ plaidItems }) {
    const navigate = useNavigate();
    const [copiedId, setCopiedId] = useState(null); // Add this state
    const { data: providerIdToProvider } = useGetProvidersQuery();
    const { data: activeBookkeeperSubscriptions } =
        useGetActiveBookkeeperSubscriptionsQuery();
    // eventually, fetching all subscriptions to do this check may become expensive so might need to refactor to just check a subscription for the specific set of providers
    const onClickUpdate = (itemId) => {
        // Add visual feedback
        setCopiedId(itemId);
        setTimeout(() => setCopiedId(null), 100);
        const updateUrl =
            "https://application.flychain.us/#/settings?tab=connections";
        navigator.clipboard.writeText(updateUrl);
    };

    const onClickNavigate = (providerId) => {
        navigate(`/provider/${providerId}/settings?tab=connections`);
    };

    return providerIdToProvider && activeBookkeeperSubscriptions ? (
        <div className="grow overflow-y-auto pl-[20px] pr-[20px] mr-[10px] scrollbar scrollbar-thumb-flychainPurple-light">
            <table className="w-full border-separate border-spacing-x-0 border-spacing-y-0">
                <thead className="sticky top-0 bg-white">
                    <tr>
                        <th>
                            <div className="text-left">
                                Internal Plaid Item ID
                            </div>
                        </th>
                        <th className="pl-[12px]">
                            <div className="text-left">Provider Name</div>
                        </th>
                        <th className="pl-[12px]">
                            <div className="text-left">Provider ID</div>
                        </th>
                        <th className="pl-[12px]">
                            <div className="text-left">Error</div>
                        </th>
                        <th className="pl-[12px]">
                            <div>Full Service Bookkeeping?</div>
                        </th>
                        <th className="pl-[12px]">
                            <div className="text-left">Email</div>
                        </th>
                        <th className="pl-[20px]">
                            <div>Update Plaid</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {plaidItems.map((plaidItem) => (
                        <tr key={plaidItem.id}>
                            <td className="border-b border-gray-200">
                                <div>{plaidItem.id}</div>
                            </td>
                            <td className="border-b border-gray-200 pl-[12px]">
                                <div>
                                    {
                                        providerIdToProvider[
                                            plaidItem.provider_id
                                        ].legal_name
                                    }
                                </div>
                            </td>
                            <td className="border-b border-gray-200 pl-[12px]">
                                <div>{plaidItem.provider_id}</div>
                            </td>
                            <td className="border-b border-gray-200 pl-[12px]">
                                <div>
                                    {
                                        plaidItem.plaid_error?.raw_plaid_error
                                            .error_message
                                    }
                                </div>
                            </td>
                            <td className="border-b border-gray-200 pl-[12px]">
                                <div className="flex justify-center">
                                    <Checkbox
                                        checked={activeBookkeeperSubscriptions.some(
                                            (subscription) =>
                                                subscription.provider_id ===
                                                plaidItem.provider_id
                                        )}
                                        sx={{
                                            "&, & .MuiSvgIcon-root": {
                                                zIndex: -1,
                                            },
                                        }}
                                    />
                                </div>
                            </td>
                            <td className="border-b border-gray-200 pl-[12px]">
                                <div>
                                    {
                                        providerIdToProvider[
                                            plaidItem.provider_id
                                        ].contact_email
                                    }
                                </div>
                            </td>
                            <td className="border-b border-gray-200 pl-[20px]">
                                <div className="flex flex-col items-center gap-y-[8px] py-[8px]">
                                    <SharedButton
                                        className={`rounded-full w-fit px-[10px] py-[5px] bg-flychainPurple-light ${
                                            copiedId === plaidItem.id &&
                                            "opacity-[75%]"
                                        }`}
                                        onClick={() =>
                                            onClickUpdate(plaidItem.id)
                                        }
                                    >
                                        Copy Update URL
                                    </SharedButton>
                                    <SharedButton
                                        className="rounded-full w-fit px-[10px] py-[5px]"
                                        onClick={() =>
                                            onClickNavigate(
                                                plaidItem.provider_id
                                            )
                                        }
                                    >
                                        Go To Connection
                                    </SharedButton>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    ) : null;
}

PlaidItemsTable.propTypes = {
    plaidItems: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default PlaidItemsTable;
