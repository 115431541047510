import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import YearPicker from "../DatePicker/YearPicker";
import Periods from "./Periods";
import PERIODS from "./Periods/constants";
import MonthPicker from "../DatePicker/MonthPicker";
import QuarterPicker from "./QuarterPicker";
import {
    getDateRangeForAnnual,
    getDateRangeForMonth,
    getDateRangeForQuarter,
} from "./utils";
import SharedUnverifiedDataWarningDropdown from "../../UnverifiedDataWarning/UnverifiedDataWarningDropdown";

function ShortcutsDropdown({
    dateRange,
    onChange,
    maxDate,
    validUpUntil = maxDate,
}) {
    const [hoverUnvalidatedToggle, setHoverUnvalidatedToggle] = useState(false);
    const [period, setPeriod] = useState(PERIODS.MONTHLY);
    const [year, setYear] = useState(dayjs(dateRange.startDate).year());

    const onPeriodChange = (newPeriod) => {
        if (newPeriod === PERIODS.ANNUAL) {
            const newDateRange = getDateRangeForAnnual(year, validUpUntil);
            onChange({
                startDate: newDateRange.startDate,
                endDate: newDateRange.endDate,
            });
        } else {
            setPeriod(newPeriod);
        }
    };

    const onMonthClick = (newMonth) => {
        const newDateRange = getDateRangeForMonth(year, newMonth, maxDate);
        onChange({
            startDate: newDateRange.startDate,
            endDate: newDateRange.endDate,
        });
    };

    const onQuarterClick = (newQuarter) => {
        const newDateRange = getDateRangeForQuarter(year, newQuarter, maxDate);
        onChange({
            startDate: newDateRange.startDate,
            endDate: newDateRange.endDate,
        });
    };

    return (
        <div className="flex flex-col items-center absolute z-[1] top-[50px] left-0 shadow-[0px_2px_8px_0px_#0000001f] rounded-xl bg-white">
            <div className="flex flex-col items-center p-[15px]">
                <YearPicker year={year} onChange={setYear} maxDate={maxDate} />
                <div className="mt-[20px]">
                    <Periods period={period} onChange={onPeriodChange} />
                </div>
                {period === PERIODS.MONTHLY && (
                    <div className="mt-[10px]">
                        <MonthPicker
                            year={year}
                            onChange={onMonthClick}
                            maxDate={maxDate}
                            validUpUntil={validUpUntil}
                            setHoverUnvalidatedToggle={
                                setHoverUnvalidatedToggle
                            }
                        />
                    </div>
                )}
                {period === PERIODS.QUARTERLY && (
                    <div className="mt-[10px]">
                        <QuarterPicker
                            year={year}
                            onChange={onQuarterClick}
                            maxDate={maxDate}
                            validUpUntil={validUpUntil}
                            setHoverUnvalidatedToggle={
                                setHoverUnvalidatedToggle
                            }
                        />
                    </div>
                )}
            </div>
            {hoverUnvalidatedToggle && (
                <SharedUnverifiedDataWarningDropdown
                    validUpUntil={validUpUntil}
                />
            )}
        </div>
    );
}

ShortcutsDropdown.propTypes = {
    dateRange: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string,
};

ShortcutsDropdown.defaultProps = {
    validUpUntil: undefined,
};

export default ShortcutsDropdown;
