import { useParams } from "react-router-dom";
import { useLazyGetPlaidLinkTokenQuery as useAdminLazyGetPlaidLinkTokenQuery } from "../../../redux/slices/adminApi";
import { useLazyGetPlaidLinkTokenQuery as useProviderLazyGetPlaidLinkTokenQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useLazyGetPlaidLinkTokenQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const [adminTrigger, adminResult] = useAdminLazyGetPlaidLinkTokenQuery();

    const [providerTrigger, providerResult] =
        useProviderLazyGetPlaidLinkTokenQuery();

    const { providerId } = useParams();

    const preloadParams = isAdminDashboard
        ? { providerId }
        : { theme: "FLYCHAIN" };

    const triggerWithParams = (plaidItemId) => {
        const finalParams = { ...preloadParams, plaidItemId };
        if (isAdminDashboard) {
            return adminTrigger(finalParams);
        }
        return providerTrigger(finalParams);
    };

    return [triggerWithParams, isAdminDashboard ? adminResult : providerResult];
};

export default useLazyGetPlaidLinkTokenQuery;
