import React from "react";
import PropTypes from "prop-types";
import { SharedButton, SharedModal } from "../../../shared";
import { SharedSectionTitle, SharedSubheading } from "../../../shared/Text";

function DeleteUserModal({ user, provider, onClose, onClick }) {
    return (
        <SharedModal onClose={onClose}>
            <div className="flex flex-col">
                <SharedSectionTitle>Confirm user delete</SharedSectionTitle>
                <SharedSubheading>{`Please confirm that you want to delete ${user.email} with ID ${user.id}.`}</SharedSubheading>
                {provider && (
                    <>
                        <SharedSubheading>{`This user is associated with the provider, ${provider.legal_name}, with ID ${provider.id}.`}</SharedSubheading>
                        <SharedSubheading>
                            When you delete the user please make sure to delete
                            the provider (if safe) and any other documents
                            related to the provider (e.g. PlaidItems)
                        </SharedSubheading>
                    </>
                )}
                <div className="mt-[20px]">
                    <SharedButton onClick={onClick}>Confirm</SharedButton>
                </div>
            </div>
        </SharedModal>
    );
}

DeleteUserModal.propTypes = {
    user: PropTypes.object.isRequired,
    provider: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};

DeleteUserModal.defaultProps = {
    provider: null,
};

export default DeleteUserModal;
