import React, { useState } from "react";
import PropTypes from "prop-types";
import FundingAmountSlider from "./FundingAmountSlider";
import { SharedButton, SharedModal } from "../../../../shared";
import { SharedPageTitle } from "../../../../shared/Text";
import { getCurrencyString } from "../../../../../utils";
import STATES from "./states";

function SelectFundingAmountModal({
    onClose,
    availableBalanceCents,
    setRequestedFundingAmountCents,
    setState,
}) {
    const [fundingAmountCents, setFundingAmountCents] = useState(
        availableBalanceCents
    );

    const onButtonClick = () => {
        setRequestedFundingAmountCents(fundingAmountCents);
        setState(STATES.SCHEDULE_FUNDING_CALL);
    };

    return (
        <SharedModal onClose={onClose}>
            <div className="flex flex-col justify-start gap-y-[80px]">
                <div className="flex flex-col justify-start gap-y-[20px]">
                    <SharedPageTitle>Request funding</SharedPageTitle>
                    <div className="text-[16px]">
                        {`Drag the slider to select any funding amount up to ${getCurrencyString(
                            availableBalanceCents
                        )}. `}
                        Before we fund your bank account, we&apos;ll always have
                        a funding call with you to discuss the amount, interest
                        rate, and repayment terms of your offer.
                    </div>
                </div>
                <FundingAmountSlider
                    value={fundingAmountCents}
                    onChange={setFundingAmountCents}
                    max={availableBalanceCents}
                />
                <div className="flex flex-row justify-end">
                    <SharedButton onClick={onButtonClick}>
                        Schedule funding call
                    </SharedButton>
                </div>
            </div>
        </SharedModal>
    );
}

SelectFundingAmountModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    availableBalanceCents: PropTypes.number.isRequired,
    setRequestedFundingAmountCents: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
};

export default SelectFundingAmountModal;
