import React from "react";
import PropTypes from "prop-types";

function EyeSvg({ fill, width, height }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-eye"
        >
            <path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0" />
            <circle cx="12" cy="12" r="3" />
        </svg>
    );
}

EyeSvg.propTypes = {
    fill: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
};

EyeSvg.defaultProps = {
    width: undefined,
    height: undefined,
};

export default EyeSvg;
