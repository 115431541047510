import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

// Helper function for the useGetTabIndex hook. It gets the index of the tab from the URL query param tab string.
const getTabIndex = (tabs, tab) => {
    if (!tab) return 0;
    const tabIndex = tabs.findIndex((t) => t.key === tab);
    return tabIndex === -1 ? 0 : tabIndex;
};

// Hook to get the index of the tab from the URL query param tab string.
// Returns the current tab index and a function to set the search params.
// This should be used whenever HorizontalTabs component needs to be based on a URL query param.
const useGetTabIndex = (tabs) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tabParam = searchParams.get("tab");
    const currentTabIndex = getTabIndex(tabs, tabParam);
    return { currentTabIndex, setSearchParams };
};

function HorizontalTabs({ tabs, onChange, initialIndex, className }) {
    const [activeTabIndex, setActiveTabIndex] = useState(initialIndex);

    useEffect(() => {
        setActiveTabIndex(initialIndex);
    }, [initialIndex]);

    const handleTabClick = (index) => {
        setActiveTabIndex(index);
        onChange(index);
    };

    return (
        <div className={`flex flex-row gap-x-[15px] ${className}`}>
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`px-[5px] py-[5px] cursor-pointer font-normal text-base ${
                        activeTabIndex === index &&
                        "font-medium border-b-2 border-flychainPurple-light"
                    }`}
                    onClick={() => handleTabClick(index)}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
}

HorizontalTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    initialIndex: PropTypes.number,
    className: PropTypes.string,
};

HorizontalTabs.defaultProps = {
    initialIndex: 0,
    className: "",
};

const SharedHorizontalTabs = {
    Component: HorizontalTabs,
    useGetTabIndex,
};

export default SharedHorizontalTabs;
