import React from "react";
import PropTypes from "prop-types";
import ChevronRightSvg from "../../../../assets/ChevronRight";
import CircleCheckSvg from "../../../../assets/CircleCheck";
import CircleDashedSvg from "../../../../assets/CircleDashed";
import CircleSvg from "../../../../assets/Circle";

export const COLLECTION_TABS = [
    { name: "SELECTION", text: "Select Payments to Collect" },
    { name: "LAST_PAYMENT_CHECK", text: "Perform Last Payment Check" },
    { name: "BALANCE_CHECK", text: "Perform Bank Balance Check" },
    { name: "COLLECTION", text: "Initiate Oatfi Collection" },
    { name: "POST_COLLECTION", text: "Perform Post-Collection Checks" },
];

const getTabIcon = (index, currentTabIndex) => {
    if (index < currentTabIndex) {
        return <CircleCheckSvg fill="#8D58FF" width={20} height={20} />;
    }

    if (index === currentTabIndex) {
        return (
            <CircleDashedSvg
                fill="#8D58FF"
                className="animate-spin-slow"
                width={20}
                height={20}
            />
        );
    }

    return <CircleSvg fill="#8D58FF" width={20} height={20} />;
};

function CollectionTabs({ currentTabIndex }) {
    return (
        <div className="flex flex-row gap-x-[10px] items-center p-[30px] cursor-default">
            {COLLECTION_TABS.map((tab, index) => (
                <div
                    className="flex flex-row gap-x-[10px] items-center"
                    key={tab.name}
                >
                    <div
                        key={tab.name}
                        className={`flex flex-row items-center gap-x-[8px] py-[4px]  ${
                            index === currentTabIndex &&
                            " border-b-[2px] border-flychainPurple-light"
                        }`}
                    >
                        {getTabIcon(index, currentTabIndex)}
                        {tab.text}
                    </div>
                    {index < COLLECTION_TABS.length - 1 && (
                        <ChevronRightSvg
                            fill="#757575"
                            width={24}
                            height={24}
                        />
                    )}
                </div>
            ))}
        </div>
    );
}

CollectionTabs.propTypes = {
    currentTabIndex: PropTypes.number.isRequired,
};

export default CollectionTabs;
