import React from "react";
import MainBenefitsCard from "../MainBenefits/MainBenefitsCard";
import MercuryEmailExampleSvg from "../../../assets/MercuryEmailExample.svg";
import MercuryPaymentDetailsExampleSvg from "../../../assets/MercuryPaymentDetailsExample.svg";
import MercuryDeleteDuplicateExampleSvg from "../../../assets/MercuryDeleteDuplicateExample.svg";
import MercuryPaymentMethodExampleSvg from "../../../assets/MercuryPaymentMethodExample.svg";
import MercuryReviewTransferExampleSvg from "../../../assets/MercuryReviewTransferExample.svg";
import MercuryHoldMoneyExampleSvg from "../../../assets/MercuryHoldMoneyExample.svg";
import { SharedPageTitle } from "../../shared/Text";

function MainBenefits() {
    return (
        <div className="p-[60px]">
            <div className="mb-[40px] text-center">
                <SharedPageTitle>
                    Handle Your Bills With Precision
                </SharedPageTitle>
            </div>
            <div className="grid grid-cols-2 gap-[40px] px-[100px] pb-[40px]">
                <MainBenefitsCard
                    imageSrc={MercuryEmailExampleSvg}
                    title="Designated email to recieve bills"
                    body="Recieve a bill in your designated email and it'll appear in your bill inbox, automatically organized so you know what bills to produce."
                />
                <MainBenefitsCard
                    imageSrc={MercuryPaymentDetailsExampleSvg}
                    title="Extract details automatically"
                    body="As soon as a bill arrives in your inbox, its details will automatically populate - allowing you to easily cross-check info and eliminate manual errors."
                />
                <MainBenefitsCard
                    imageSrc={MercuryDeleteDuplicateExampleSvg}
                    title="Detect and avoid duplicate bills"
                    body="Mercury automatically detects duplicate bills, giving you an extra set of eyes and preventing you from overpaying."
                />
                <MainBenefitsCard
                    imageSrc={MercuryPaymentMethodExampleSvg}
                    title="Pay the way you want"
                    body="Schedule and send payments directly from your Mercury account via ACH, domestic, and international wire."
                />
                <MainBenefitsCard
                    imageSrc={MercuryReviewTransferExampleSvg}
                    title="Review and approve bills"
                    body="Set up multi-layered approval rules and approve bills right inside Slack or Mercury's mobile or desktop app."
                />
                <MainBenefitsCard
                    imageSrc={MercuryHoldMoneyExampleSvg}
                    title="Hold on to your money longer"
                    body="By paying bills directly from your bank, you pay bills the moment you need to - allowing you to hold onto your cash for as long as possible."
                />
            </div>
        </div>
    );
}

export default MainBenefits;
