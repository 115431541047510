import React from "react";
import PropTypes from "prop-types";
import { SharedCard } from "../../shared";

function TaxBenefitsCard({ title, body, icon }) {
    return (
        <SharedCard
            showTab={false}
            className="flex flex-col items-center gap-y-[16px]"
        >
            <div className="flex flex-row items-center gap-x-[16px] justify-center ">
                <div className="flex-[1]">{icon}</div>
                <div className="text-xl font-semibold text-center">{title}</div>
            </div>
            <div className="text-sm font-normal text-center text-flychainGray">
                {body}
            </div>
        </SharedCard>
    );
}

TaxBenefitsCard.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
};

export default TaxBenefitsCard;
