import React, { useEffect, useState } from "react";
import useGlobalLoading from "./hooks/useGlobalLoading";

function Loading() {
    const isLoading = useGlobalLoading();
    const [dots, setDots] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prev) => (prev.length >= 3 ? "" : `${prev}.`));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    if (!isLoading) {
        return null;
    }

    return (
        <div className="fixed inset-0 w-[100vw] h-[100vh] flex flex-row justify-center items-center bg-transparent z-[9999] pl-[250px] gap-x-[20px]">
            <div className="w-[40px] h-[40px] rounded-full border-[6px] border-flychainPurple-light animate-spin border-t-transparent border-b-transparent" />
            <div className="text-[20px] font-bold text-flychainPurple-light">
                Loading{dots}
            </div>
        </div>
    );
}

export default Loading;
