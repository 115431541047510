import React from "react";
import SignupAndScheduleDemo from "../SignupAndScheduleDemo";
import { SharedSectionTitle } from "../../shared/Text";
import OnboardingSvg from "./Onboarding.svg";

function Onboarding() {
    const circleStyling =
        "flex w-[27px] h-[27px] rounded-full bg-white text-flychainPurple-light items-center justify-center font-semibold text-xs flex-shrink-0";

    return (
        <div className="flex flex-row bg-[#e5e7f2] p-[60px]">
            <div className="flex-[3]">
                <img src={OnboardingSvg} alt="Onboarding" className="w-full" />
            </div>
            <div className="flex-[1]" />
            <div className="flex flex-col flex-[6]">
                <div className="mb-[24px]">
                    <SharedSectionTitle>
                        A White-Glove Onboarding Experience
                    </SharedSectionTitle>
                </div>
                <div className="flex flex-col gap-y-[20px]">
                    <div className="flex items-center gap-x-[16px]">
                        <div className={circleStyling}>1</div>
                        <p className="font-normal text-[16px]">
                            Apply online within 10 minutes or less.
                        </p>
                    </div>
                    <div className="flex items-center gap-x-[16px]">
                        <div className={circleStyling}>2</div>
                        <p className="font-normal text-[16px]">
                            Get approved in as fast as 24 hours.
                        </p>
                    </div>
                    <div className="flex items-center gap-x-[16px]">
                        <div className={circleStyling}>3</div>
                        <p className="font-normal text-[16px]">
                            Mercury will help you migrate your payments,
                            payroll, and other services from your previous bank
                            to Mercury.
                        </p>
                    </div>
                    <div className="flex items-center gap-x-[16px]">
                        <div className={circleStyling}>4</div>
                        <p className="font-normal text-[16px]">
                            Receive special bonuses on deposits and card spend.
                        </p>
                    </div>
                </div>
                <div className="mt-[30px] w-[80%]">
                    <SignupAndScheduleDemo />
                </div>
            </div>
        </div>
    );
}

export default Onboarding;
