import React from "react";
import { getCurrencyString } from "../../../../../utils";
import { SharedLabeledText } from "../../../../shared/Text";
import useGetDataForFinancialReportsSummaryQuery from "../../../../../hooks/queriesAndMutations/useGetDataForFinancialReportsSummaryQuery";

function Summary() {
    const { data } = useGetDataForFinancialReportsSummaryQuery();

    return data ? (
        <div className="flex flex-row gap-x-[100px]">
            <SharedLabeledText
                label="Total Revenue"
                text={getCurrencyString(data.total_revenue_cents)}
            />
            <SharedLabeledText
                label="Total Expenses"
                text={getCurrencyString(data.total_expenses_cents)}
            />
            <SharedLabeledText
                label="Net Profit"
                text={getCurrencyString(data.net_profit_cents)}
            />
            {data.total_revenue_cents !== 0 && (
                <SharedLabeledText
                    label="Net Profit Margin"
                    text={`${data.percent_profit_margin.toFixed(2)}%`}
                />
            )}
        </div>
    ) : null;
}

export default Summary;
