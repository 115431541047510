import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SharedSectionTitle } from "../../../../shared/Text";
import { getCurrencyString, MMMM_D_YYYY_FORMAT } from "../../../../../utils";

dayjs.extend(utc);

function PostCollectionTable({
    selectedPayments,
    tableTitle,
    emptyArrayMessage,
}) {
    const renderTableRows = () =>
        selectedPayments.map((payment) => (
            <div
                key={payment.id}
                className="grid grid-cols-[2.5fr_1fr_1.5fr_2.5fr] gap-x-[10px] text-sm items-center border-b border-gray-200 py-[12px]"
            >
                <div>{payment.id}</div>
                <div>{getCurrencyString(payment.payment_amount)}</div>
                <div>
                    {dayjs(payment.earliest_payment_date)
                        .utc()
                        .format(MMMM_D_YYYY_FORMAT)}
                </div>
                <div>{payment.draw_id}</div>
            </div>
        ));

    return (
        <div className="flex flex-col gap-y-[10px]">
            <SharedSectionTitle className="text-flychainGray">
                {tableTitle}
            </SharedSectionTitle>
            {selectedPayments.length > 0 ? (
                <div className="flex flex-col">
                    <div className="grid grid-cols-[2.5fr_1fr_1.5fr_2.5fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                        <div>Payment ID</div>
                        <div>Amount</div>
                        <div>Earliest Payment Date</div>
                        <div>Draw ID</div>
                    </div>
                    {renderTableRows()}
                </div>
            ) : (
                emptyArrayMessage || "No payments found"
            )}
        </div>
    );
}

PostCollectionTable.propTypes = {
    selectedPayments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    tableTitle: PropTypes.string.isRequired,
    emptyArrayMessage: PropTypes.string,
};

PostCollectionTable.defaultProps = {
    emptyArrayMessage: undefined,
};

export default PostCollectionTable;
