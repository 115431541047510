import React from "react";
import PlaidItemsTable from "./PlaidItemsTable";
import { SharedAlert } from "../../shared";
import { SharedSectionTitle } from "../../shared/Text";
import TriangleAlertSvg from "../../../assets/TriangleAlert";
import { useGetExpiredPlaidItemsQuery } from "../../../redux/slices/adminApi";

function ExpiredPlaidItems() {
    const { data: expiredPlaidItems } = useGetExpiredPlaidItemsQuery();

    if (!expiredPlaidItems) {
        return null;
    }

    return (
        <div className="flex flex-col h-full gap-y-[10px]">
            <div className="flex flex-col px-[20px] pt-[20px] gap-y-[20px]">
                <SharedAlert
                    icon={
                        <TriangleAlertSvg
                            fill="#8D58FF"
                            width={20}
                            height={20}
                        />
                    }
                    message="Plaid Errors only reset after Plaid Balance is Processed (9AM ET). To avoid any issues, best to do this check right after the Balance script is run. In the future, we should update the Plaid Error after succesful plaid link update via webhook."
                    borderColor="border-flychainPurple-light"
                />
                <SharedSectionTitle>Expired Plaid Items</SharedSectionTitle>
            </div>
            <PlaidItemsTable plaidItems={expiredPlaidItems} />
        </div>
    );
}

export default ExpiredPlaidItems;
