import React from "react";
import PropTypes from "prop-types";
import { SharedModal } from "../shared";
import { SharedSectionTitle, SharedSubheading } from "../shared/Text";

function SupportModal({ onClose }) {
    return (
        <SharedModal onClose={onClose}>
            <div className="flex flex-col justify-start">
                <SharedSectionTitle>Contact us</SharedSectionTitle>
                <SharedSubheading>
                    If you need any help, please don&apos;t hesitate to reach
                    out to{" "}
                    <a href="mailto:jaime@flychain.us">jaime@flychain.us</a>
                    and we&apos;ll get back to you as soon as possible.
                </SharedSubheading>
            </div>
        </SharedModal>
    );
}

SupportModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default SupportModal;
