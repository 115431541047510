import React from "react";
import { useNavigate } from "react-router-dom";
import useGetProviderPath from "../../../hooks/useGetProviderPath";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import { SharedPageTitle } from "../../shared/Text";
import ImproveFinancialHealthCard from "./ImproveFinancialHealthCard";
import { SharedButton } from "../../shared";
import AudioWaveformSvg from "../../../assets/AudioWaveform";
import ArrowDownWideNarrowSvg from "../../../assets/ArrowDownWideNarrow";
import PercentSvg from "../../../assets/Percent";
import RefreshCWSvg from "../../../assets/RefreshCW";

function ImproveFinancialHealth() {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: false,
    });
    const navigate = useNavigate();
    const providerPath = useGetProviderPath();

    if (!businessEntities) return null;

    const businessEntityId =
        businessEntities.length > 0 ? businessEntities[0].id : null;

    const noBusinessEntitiesAction = (
        <SharedButton
            onClick={() =>
                navigate(`${providerPath}/settings?tab=businessEntities`)
            }
        >
            Add A Business Entity
        </SharedButton>
    );

    return (
        <div className="bg-gradient-to-b from-flychainPurple-white to-white p-[60px]">
            <div className="flex flex-col gap-y-[50px] w-full">
                <div className="flex flex-row justify-center w-full gap-x-[50px] mb-[30px]">
                    <SharedPageTitle className="w-[40%]">
                        Increase your valuation by improving your financial
                        health.
                    </SharedPageTitle>
                    <div className="w-[40%] text-base font-normal">
                        The financial health of your business is the number one
                        factor that affects your valuation. Take the following
                        steps on our platform to improve your financial health.
                    </div>
                </div>
                <div className="flex flex-row justify-center w-full gap-x-[50px]">
                    <ImproveFinancialHealthCard
                        icon={
                            <AudioWaveformSvg
                                fill="#8D58FF"
                                height={30}
                                width={30}
                            />
                        }
                        title="Track net profit"
                        body="Buyers strongly consider the monthly and yearly net profit of your business, as it determines how long it will take them to recoup their investment. In addition, net profit is a good gauge of how efficiently you run your business when compared to similar businesses in your industry."
                        action={
                            businessEntityId ? (
                                <SharedButton
                                    onClick={() =>
                                        navigate(
                                            `${providerPath}/businessEntity/${businessEntityId}/financialReports/incomeStatement`
                                        )
                                    }
                                >
                                    See Income Statement
                                </SharedButton>
                            ) : (
                                noBusinessEntitiesAction
                            )
                        }
                    />
                    <ImproveFinancialHealthCard
                        icon={
                            <ArrowDownWideNarrowSvg
                                fill="#8D58FF"
                                height={30}
                                width={30}
                            />
                        }
                        title="Reduce expenses"
                        body="Expenses as a percentage of revenue is an important metric that buyers consider. Buyers want to know that your expenses are in line with industry standards. If your expenses are too high, this could indicate inefficiencies in your business."
                        action={
                            businessEntityId ? (
                                <SharedButton
                                    onClick={() =>
                                        navigate(
                                            `${providerPath}/businessEntity/${businessEntityId}/financialReports/expenses`
                                        )
                                    }
                                >
                                    See Expenses
                                </SharedButton>
                            ) : (
                                noBusinessEntitiesAction
                            )
                        }
                    />
                </div>
                <div className="flex flex-row justify-center w-full gap-x-[50px]">
                    <ImproveFinancialHealthCard
                        icon={
                            <PercentSvg fill="#8D58FF" height={30} width={30} />
                        }
                        title="Refinance debt"
                        body="Buyers consider your debt-to-income ratio to ensure that your business is not over-leveraged. If you have outstanding debt with high interest, you should consider refinancing to more affordable options with a longer repayment period."
                        action={
                            <SharedButton
                                onClick={() =>
                                    navigate(`${providerPath}/capital/termLoan`)
                                }
                            >
                                Apply Now
                            </SharedButton>
                        }
                    />
                    <ImproveFinancialHealthCard
                        icon={
                            <RefreshCWSvg
                                fill="#8D58FF"
                                height={30}
                                width={30}
                            />
                        }
                        title="Improve revenue cycle"
                        body="Average days in AR, net collection rates, denial rates, payer mixes, and your contracted rates with these payers are critical metrics that buyers weigh heavily. A healthy revenue cycle indicates a healthy cash flow cycle and smooth internal porocesses, which is a strong positive signal."
                        action={
                            <SharedButton disabled>Coming Soon!</SharedButton>
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default ImproveFinancialHealth;
