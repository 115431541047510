import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import getNumericRegex from "./utils";

function StateNumericInput({
    name,
    label,
    currentValue,
    onChange,
    error,
    placeholder,
    decimal,
    currency,
    className,
}) {
    const handleChange = (e) => {
        const regex = getNumericRegex(decimal, currency);
        if (e.target.value === "") {
            onChange("");
        } else if (regex.test(e.target.value)) {
            onChange(e.target.value);
        }
    };

    return (
        <FormControl
            name={name}
            className={className}
            variant="standard"
            error={!!error}
        >
            <TextField
                variant="standard"
                onChange={handleChange}
                value={currentValue}
                label={label}
                error={!!error}
                helperText={error}
                placeholder={placeholder}
                InputProps={{
                    startAdornment: currency ? (
                        <InputAdornment position="start">$</InputAdornment>
                    ) : undefined,
                    inputMode: decimal ? "decimal" : "numeric",
                }}
                sx={{
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "#8D58FF",
                    },
                }}
                InputLabelProps={{
                    sx: {
                        "&.Mui-focused": {
                            color: error.message ? "error.main" : "#8D58FF",
                        },
                    },
                }}
                FormHelperTextProps={{
                    sx: { margin: error.message ? "3px 0 0 0" : "0" },
                }}
            />
        </FormControl>
    );
}

StateNumericInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    currentValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    decimal: PropTypes.bool,
    currency: PropTypes.bool,
    className: PropTypes.string,
};

StateNumericInput.defaultProps = {
    error: "",
    placeholder: "",
    decimal: false,
    currency: false,
    className: "",
};

export default StateNumericInput;
