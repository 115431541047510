import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SharedButton } from "../../../../../shared";
import ChevronDownSvg from "../../../../../../assets/ChevronDown";
import HandCoinsSvg from "../../../../../../assets/HandCoins";
import EyeSvg from "../../../../../../assets/Eye";
import { useFundOfferMutation } from "../../../../../../redux/slices/adminApi";

function UnfundedOfferActions({ offer }) {
    const navigate = useNavigate();
    const [fundOffer] = useFundOfferMutation();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleClick = (event) => {
        if (event && event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFundOffer = () => {
        fundOffer({ draw_id: offer.draw.id });
    };

    const reviewOffer = () => {
        navigate(`/provider/${offer.provider_id}/capital/offer/${offer.id}`);
    };

    return (
        <div className="flex justify-center w-full">
            <SharedButton
                className="rounded-[6px] px-[10px] py-[8px]"
                onClick={handleClick}
            >
                <div className="flex flex-row items-center gap-[5px] ">
                    Actions
                    <ChevronDownSvg fill="#FFFFFF" width={20} height={20} />
                </div>
            </SharedButton>
            <Menu
                id="admin-actions"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{
                    "&:after": {
                        borderBottomColor: "#8D58FF",
                    },
                }}
            >
                <MenuItem
                    key="fund_offer"
                    value="fund_offer"
                    className="flex flex-row items-center gap-[8px] hover:text-flychainPurple-light"
                    onClick={handleFundOffer}
                >
                    <HandCoinsSvg fill="currentColor" width={16} height={16} />
                    Fund Offer
                </MenuItem>
                <MenuItem
                    key="review_offer"
                    value="review_offer"
                    className="flex flex-row items-center gap-[8px] hover:text-flychainPurple-light"
                    onClick={reviewOffer}
                >
                    <EyeSvg fill="currentColor" width={16} height={16} />
                    Review Offer
                </MenuItem>
            </Menu>
        </div>
    );
}

UnfundedOfferActions.propTypes = {
    offer: PropTypes.object.isRequired,
};

export default UnfundedOfferActions;
