import React, { useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { YYYY_MM_DD_FORMAT } from "../../../../utils";
import SharedUnverifiedDataWarningDropdown from "../../UnverifiedDataWarning/UnverifiedDataWarningDropdown";
import YearPicker from "./YearPicker";
import Periods from "./Periods";
import PERIODS from "./Periods/constants";
import MonthPicker from "./MonthPicker";
import DayPicker from "./DayPicker";

function DatePickerDropdown({
    date,
    onChange,
    maxDate,
    validUpUntil = maxDate,
}) {
    const parsedDate = dayjs(date);
    const [hoverUnvalidatedToggle, setHoverUnvalidatedToggle] = useState(false);
    const [year, setYear] = useState(parsedDate.year());
    const [month, setMonth] = useState(parsedDate.month()); // Zero-indexed
    const [, setDay] = useState(parsedDate.date()); // One-indexed

    const [period, setPeriod] = useState(PERIODS.MONTH);

    const onYearChange = (newYear) => {
        setYear(newYear);
    };

    const onMonthChange = (newMonth) => {
        setMonth(newMonth);
        setPeriod(PERIODS.DAY);
    };

    const onDayChange = (newDay) => {
        setDay(newDay);
        // Selecting the day finalizes the date and therefore we call onChange, which closes the Menu
        onChange(
            dayjs()
                .year(year)
                .month(month)
                .date(newDay)
                .format(YYYY_MM_DD_FORMAT)
        );
    };

    return (
        <div className="flex flex-col items-center absolute z-[1] top-[50px] left-0  shadow-[0px_2px_8px_0px_#0000001f] rounded-[12px] bg-white">
            <div className="flex flex-col items-center p-[15px]">
                <YearPicker
                    year={year}
                    onChange={onYearChange}
                    maxDate={maxDate}
                />
                <div className="mt-[20px]">
                    <Periods period={period} onChange={setPeriod} />
                </div>
                {period === PERIODS.MONTH && (
                    <div className="mt-[10px]">
                        <MonthPicker
                            year={year}
                            onChange={onMonthChange}
                            maxDate={maxDate}
                            validUpUntil={validUpUntil}
                        />
                    </div>
                )}
                {period === PERIODS.DAY && (
                    <div className="mt-[10px]">
                        <DayPicker
                            year={year}
                            month={month}
                            onChange={onDayChange}
                            maxDate={maxDate}
                            validUpUntil={validUpUntil}
                            setHoverUnvalidatedToggle={
                                setHoverUnvalidatedToggle
                            }
                        />
                    </div>
                )}
            </div>
            {hoverUnvalidatedToggle && (
                <SharedUnverifiedDataWarningDropdown
                    validUpUntil={validUpUntil}
                />
            )}
        </div>
    );
}

DatePickerDropdown.propTypes = {
    date: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.string.isRequired,
    validUpUntil: PropTypes.string,
};

DatePickerDropdown.defaultProps = {
    validUpUntil: undefined,
};

export default DatePickerDropdown;
