import React from "react";
import PropTypes from "prop-types";
import SharedLabel from "./Label";

function LabeledText({ label, text }) {
    return (
        <div className="flex flex-col gap-y-[5px]">
            <SharedLabel>{label}</SharedLabel>
            <div className="font-[600] text-[19px]">{text}</div>
        </div>
    );
}

LabeledText.propTypes = {
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default LabeledText;
