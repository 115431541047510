import React from "react";
import PropTypes from "prop-types";
import { SharedPageTitle } from "../shared/Text";
import { SharedButton } from "../shared";
import CashFlowGraphicSvg from "../../assets/CashFlowGraphic.svg";
import NetProfitGraphicSvg from "../../assets/NetProfitGraphic.svg";
import TopExpensesGraphicSvg from "../../assets/TopExpensesGraphic.svg";

function TaxesHeaderSection({ onClick }) {
    return (
        <div className="flex flex-row p-[60px] bg-flychainOffWhite gap-x-[60px] items-center">
            <div className="flex flex-col jusify-center flex-[2] gap-[30px]">
                <SharedPageTitle>
                    File Your Taxes With Confidence
                </SharedPageTitle>
                <div className="text-[14px] font-[400]">
                    <a
                        href="https://www.rivet.tax/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Rivet
                    </a>
                    , our tax partner, is a tax prep firm built by
                    technologists. Rivet is designed to make tax season as
                    seamless as possible while minimizing your tax burden. Rivet
                    bills for fixed price engagements so that costs are upfront,
                    transparent, and low.
                </div>
                <div>
                    <SharedButton onClick={onClick}>
                        Schedule a Free Intro Call
                    </SharedButton>
                </div>
            </div>
            <div className="relative flex-[1]">
                <div className="w-full aspect-square rounded-[50%] bg-gradient-to-tr from-flychainBlue-light to-flychainGreen-light" />
                {
                    // TODO: replace these graphics - Christine to send.
                }
                <img
                    className="absolute w-[60%] z-[1] top-[5%] left-[5%]"
                    src={CashFlowGraphicSvg}
                    alt="CashFlowGraphic"
                />
                <img
                    className="absolute w-[60%] z-[2] top-[50%] left-[5%]"
                    src={NetProfitGraphicSvg}
                    alt="NetProfitGraphic"
                />
                <img
                    className="absolute w-[60%] z-[3] top-[25%] left-[50%]"
                    src={TopExpensesGraphicSvg}
                    alt="TopExpensesGraphic"
                />
            </div>
        </div>
    );
}

TaxesHeaderSection.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default TaxesHeaderSection;
