import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function SearchableBusinessEntitySelect({
    formData,
    setFormData,
    defaultValues,
    businessEntities,
    error,
}) {
    const businessEntityFilter = (options, { inputValue }) => {
        const searchStr = inputValue.toLowerCase();

        if (searchStr === "") {
            return options;
        }

        return options.filter((option) => {
            const { businessEntity } = option;
            const legalNameStr = businessEntity.legal_name.toLowerCase();
            const displayNameStr = businessEntity.display_name
                ? businessEntity.display_name.toLowerCase()
                : "";
            return (
                legalNameStr.includes(searchStr) ||
                displayNameStr.includes(searchStr)
            );
        });
    };

    const handleEntitySelect = (event, selection) => {
        setFormData({
            ...defaultValues,
            businessEntityLegalName: selection?.value || "", // Handle null when cleared
        });
    };

    return (
        <Autocomplete
            value={
                formData.businessEntityLegalName === ""
                    ? null
                    : formData.businessEntityLegalName
            }
            onChange={handleEntitySelect}
            options={businessEntities.map((businessEntity) => ({
                label: businessEntity.legal_name,
                value: businessEntity.legal_name,
                businessEntity,
            }))}
            isOptionEqualToValue={(option, value) => {
                if (value === null) {
                    return false;
                }
                return option.value === value;
            }}
            filterOptions={businessEntityFilter}
            getOptionKey={(option) => option.businessEntity.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Business Entity *"
                    error={!!error}
                    helperText={error}
                    variant="standard"
                    sx={{
                        "& .MuiInput-underline:after": {
                            borderBottomColor: "#8D58FF", // Custom border color on focus
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: "#8D58FF", // Custom label color on focus
                        },
                    }}
                />
            )}
        />
    );
}

SearchableBusinessEntitySelect.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
    businessEntities: PropTypes.array.isRequired,
    error: PropTypes.string,
};

SearchableBusinessEntitySelect.defaultProps = {
    error: undefined,
};

export default SearchableBusinessEntitySelect;
