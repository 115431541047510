import React from "react";
import PropTypes from "prop-types";
import SharedButton from "../Button";

function Banner({
    title,
    body,
    buttonText,
    buttonOnClick,
    backgroundColor,
    titleColor,
    bodyColor,
}) {
    return (
        <div
            className={`flex flex-col items-center p-[60px] ${backgroundColor}`}
        >
            {title && (
                <div
                    className={`text-3xl font-semibold text-center mb-[30px] ${titleColor}`}
                >
                    {title}
                </div>
            )}
            {body && (
                <div
                    className={`text-sm font-normal text-center mb-[30px] ${bodyColor} px-[100px]`}
                >
                    {body}
                </div>
            )}
            {buttonText && buttonOnClick ? (
                <div>
                    <SharedButton onClick={buttonOnClick}>
                        {buttonText}
                    </SharedButton>
                </div>
            ) : null}
        </div>
    );
}

Banner.propTypes = {
    title: PropTypes.string,
    titleColor: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    bodyColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    buttonText: PropTypes.string,
    buttonOnClick: PropTypes.func,
};

Banner.defaultProps = {
    title: undefined,
    titleColor: "text-white",
    body: undefined,
    bodyColor: "text-white",
    backgroundColor: "bg-flychainPurple-dark",
    buttonText: undefined,
    buttonOnClick: undefined,
};

export default Banner;
