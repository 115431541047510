import React from "react";
import { SharedPageTitle } from "../shared/Text";

function SignUpFlowComplete() {
    return (
        <div className="flex flex-col justify-start p-[60px]">
            <div className="mb-[20px]">
                <SharedPageTitle>
                    You&apos;ve submitted all the information we need!
                </SharedPageTitle>
            </div>
            <div className="text-[15px] font-[300]">
                We&apos;re currently reviewing your information and we&apos;ll
                reach out via email within two business days with next steps. If
                you don&apos;t hear back from us within two business days,
                please send an email to{" "}
                <a href="mailto:jaime@flychain.us">jaime@flychain.us</a>
            </div>
        </div>
    );
}

export default SignUpFlowComplete;
