import React from "react";
import PropTypes from "prop-types";
import DownloadInterface from "./DownloadInterface";

function BusinessEntity({ businessEntity, provider }) {
    const displayName = () => {
        if (businessEntity.display_name) {
            return businessEntity.display_name;
        }
        if (businessEntity.dba) {
            return businessEntity.dba;
        }
        return businessEntity.legal_name;
    };

    return (
        <div className="flex flex-row justify-between items-center h-fit pt-[2px] gap-x-[10px]">
            <div className="flex flex-col h-fit mb-[3px]">
                <div className="text-[16px]">{displayName()}</div>
                <div className="text-sm text-flychainGray">
                    {businessEntity.id}
                </div>
            </div>
            <div className="flex flex-row gap-x-[15px] items-center h-fit shrink-0">
                <DownloadInterface
                    businessEntity={businessEntity}
                    provider={provider}
                />
            </div>
        </div>
    );
}

BusinessEntity.propTypes = {
    businessEntity: PropTypes.object.isRequired,
    provider: PropTypes.object.isRequired,
};

export default BusinessEntity;
