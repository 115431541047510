import React, { useState } from "react";
import PropTypes from "prop-types";
import CollectionTable from "./CollectionTable";
import {
    SharedAlert,
    SharedButton,
    SharedBackIconButton,
} from "../../../../shared";
import { SharedSectionTitle } from "../../../../shared/Text";
import CircleCheckSvg from "../../../../../assets/CircleCheck";
import TriangleAlertSvg from "../../../../../assets/TriangleAlert";

function Collection({
    selectedPayments,
    setSelectedPayments,
    setCurrentTabIndex,
}) {
    // TODO: add note to not reload the page

    const [collectedPaymentIds, setCollectedPaymentIds] = useState([]);

    const allPaymentsCollected = selectedPayments.every((payment) =>
        collectedPaymentIds.includes(payment.id)
    );

    const onClickContinue = () => {
        setCurrentTabIndex(4);
    };

    const onClickBack = () => {
        setCurrentTabIndex(2);
    };

    const renderProceedMessage = () => {
        if (allPaymentsCollected) {
            return (
                <SharedAlert
                    icon={
                        <CircleCheckSvg fill="#00B894" width={18} height={18} />
                    }
                    message="All payments have been collected. Please proceed."
                    borderColor="border-flychainGreen"
                />
            );
        }

        return (
            <SharedAlert
                icon={
                    <TriangleAlertSvg fill="#D32F2F" width={18} height={18} />
                }
                message="Some payments have not been collected. Please collect all payments before proceeding."
                borderColor="border-flychainError"
            />
        );
    };

    return (
        <div className="flex flex-col gap-y-[30px] px-[30px] pb-[30px]">
            <div className="flex flex-row items-center gap-x-[20px] ">
                <SharedBackIconButton onClick={onClickBack} />
                <SharedSectionTitle>Collections</SharedSectionTitle>
            </div>
            <CollectionTable
                selectedPayments={selectedPayments}
                setSelectedPayments={setSelectedPayments}
                collectedPaymentIds={collectedPaymentIds}
                setCollectedPaymentIds={setCollectedPaymentIds}
            />
            {renderProceedMessage()}
            <SharedButton
                onClick={onClickContinue}
                disabled={!allPaymentsCollected}
            >
                Proceed to Post-Collection
            </SharedButton>
        </div>
    );
}

Collection.propTypes = {
    selectedPayments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setSelectedPayments: PropTypes.func.isRequired,
    setCurrentTabIndex: PropTypes.func.isRequired,
};

export default Collection;
