import React from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { YYYY_MM_DD_FORMAT } from "../../../../utils";
import LedgerTable from "../../LedgerTable";
import { SharedUnverifiedDataWarningBanner } from "../../../shared";
import { SharedDateRangePicker } from "../../../shared/Dates";
import {
    selectDateRange,
    updateDateRange,
} from "../../../../redux/slices/dateRange";
import useGetDataForTopExpensesGraphQuery from "../../../../hooks/queriesAndMutations/useGetDataForTopExpensesGraphQuery";
import useGetFinancialReportsValidUpUntilQuery from "../../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useValidateDateRange from "../../../../hooks/useValidateDateRange";

const HEADERS = ["Category", "Percentage of Spend", "Total Spend", ""];

function ExpensesByCategory() {
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(newDateRange));
    };

    const { data: expenseData } = useGetDataForTopExpensesGraphQuery();
    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const dateRangeUnvalidated = useValidateDateRange(
        validUpUntilData,
        dateRange
    );

    if (!expenseData || !validUpUntilData) return null;

    const maxDate = dayjs().format(YYYY_MM_DD_FORMAT);

    return expenseData ? (
        <div className="flex flex-col gap-y-[20px]">
            <div className="flex flex-row justify-between pr-[20px]">
                <SharedDateRangePicker
                    dateRange={dateRange}
                    onChange={setDateRange}
                    maxDate={maxDate}
                    validUpUntil={validUpUntilData.valid_up_until}
                />
            </div>
            {dateRangeUnvalidated && (
                <SharedUnverifiedDataWarningBanner
                    validUpUntil={validUpUntilData.valid_up_until}
                />
            )}
            <LedgerTable data={expenseData} headers={HEADERS} />
        </div>
    ) : null;
}

export default ExpensesByCategory;
