import React from "react";
import CreateOffer from "./CreateOffer";
import ViewOffers from "./ViewOffers";
import { SharedHorizontalTabs } from "../../../shared";
import { SharedPageTitle } from "../../../shared/Text";

const TABS = [
    { key: "viewOffers", label: "View Offers" },
    { key: "createOffer", label: "Create Offer" },
];

// Render the content for the active tab
function renderDrawsContent(currentTabIndex) {
    switch (currentTabIndex) {
        case 0:
            return <ViewOffers />;
        case 1:
            return <CreateOffer />;
        default:
            return <ViewOffers />;
    }
}

function AdminDraws() {
    const { currentTabIndex, setSearchParams } =
        SharedHorizontalTabs.useGetTabIndex(TABS);

    // Tab change handler to be passed to the HorizontalTabs component
    const handleTabChange = (index) => {
        setSearchParams({ tab: TABS[index].key }, { replace: true });
    };

    return (
        <div className="flex flex-col h-full p-[60px] gap-y-[30px]">
            <SharedPageTitle>Manage Draws</SharedPageTitle>
            <SharedHorizontalTabs.Component
                tabs={TABS.map((tab) => tab.label)}
                initialIndex={currentTabIndex}
                onChange={handleTabChange}
            />
            {renderDrawsContent(currentTabIndex)}
        </div>
    );
}

export default AdminDraws;
