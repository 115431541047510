import React, { useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SharedCard } from "../../../../shared";
import DownCaret from "../../../../../assets/DownCaret.svg";
import RightCaret from "../../../../../assets/RightCaret.svg";
import { getCurrencyString, MMMM_D_YYYY_FORMAT } from "../../../../../utils";
import useGetBusinessEntityQuery from "../../../../../hooks/queriesAndMutations/useGetBusinessEntityQuery";

// Add the UTC plugin to dayjs
dayjs.extend(utc);

function BusinessEntityPayments({
    providerId,
    businessEntityId,
    businessEntityPayments,
    selectedPayments,
    setSelectedPayments,
}) {
    const [showBusinessEntityPayments, setShowBusinessEntityPayments] =
        useState(false);
    const { data: businessEntity } = useGetBusinessEntityQuery({
        providerId,
        businessEntityId,
    });

    if (!businessEntity) {
        return null;
    }

    const sortedBusinessEntityPayments = businessEntityPayments.sort((a, b) =>
        dayjs(a.earliest_payment_date).diff(dayjs(b.earliest_payment_date))
    );

    const toggleCheckbox = (payment) => {
        if (selectedPayments.includes(payment)) {
            // remove payment from payments
            setSelectedPayments(selectedPayments.filter((p) => p !== payment));
        } else {
            // add payment to payments
            setSelectedPayments([...selectedPayments, payment]);
        }
    };

    return (
        <SharedCard
            showTab={false}
            className={
                selectedPayments.filter(
                    (payment) => payment.business_entity_id === businessEntityId
                ).length > 0
                    ? "border-flychainPurple-light"
                    : ""
            }
        >
            <div className="flex flex-col gap-y-[10px]">
                <div className="flex flex-col">
                    <div className="font-semibold text-[20px]">
                        {businessEntity.legal_name}
                    </div>
                    <div className="text-[14px] text-flychainGray">
                        ID: {businessEntity.id}
                    </div>
                </div>
                <div
                    className="flex flex-row gap-x-[8px] items-center w-fit cursor-pointer"
                    onClick={() =>
                        setShowBusinessEntityPayments(
                            !showBusinessEntityPayments
                        )
                    }
                >
                    <div className="font-semibold">
                        Payments ({sortedBusinessEntityPayments.length})
                    </div>
                    <img
                        src={
                            showBusinessEntityPayments ? DownCaret : RightCaret
                        }
                        alt="Toggle"
                        className="w-[10px] h-[10px] brightness-0"
                    />
                </div>
                {showBusinessEntityPayments && (
                    <>
                        <div className="w-full border" />
                        <div className="flex flex-col">
                            <div className="grid grid-cols-[2.5fr_1fr_1.5fr_2.5fr_1fr] gap-x-[10px] text-[14px] font-semibold border-b border-gray-200 py-[8px]">
                                <div>Payment ID</div>
                                <div>Amount</div>
                                <div>Earliest Payment Date</div>
                                <div>Draw ID</div>
                                <div className="flex justify-center w-full">
                                    Select for Collection
                                </div>
                            </div>
                            {sortedBusinessEntityPayments.map((payment) => (
                                <div
                                    key={payment.id}
                                    className="grid grid-cols-[2.5fr_1fr_1.5fr_2.5fr_1fr] gap-x-4 text-sm items-center border-b border-gray-200 py-[8px]"
                                >
                                    <div>{payment.id}</div>
                                    <div>
                                        {getCurrencyString(
                                            payment.payment_amount
                                        )}
                                    </div>
                                    <div>
                                        {dayjs(payment.earliest_payment_date)
                                            .utc()
                                            .format(MMMM_D_YYYY_FORMAT)}
                                    </div>
                                    <div>{payment.draw_id}</div>
                                    <div className="flex justify-center w-full">
                                        <Checkbox
                                            checked={selectedPayments.includes(
                                                payment
                                            )}
                                            onChange={() =>
                                                toggleCheckbox(payment)
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </SharedCard>
    );
}

BusinessEntityPayments.propTypes = {
    providerId: PropTypes.string.isRequired,
    businessEntityId: PropTypes.string.isRequired,
    businessEntityPayments: PropTypes.array.isRequired,
    selectedPayments: PropTypes.array.isRequired,
    setSelectedPayments: PropTypes.func.isRequired,
};

export default BusinessEntityPayments;
