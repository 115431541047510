import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import REPORT_TYPES from "../../constants";
import LinesTable from "./LinesTable";
import getDateRangeForAllTimeToEndDate from "../utils";
import { getCurrencyString, YYYY_MM_DD_FORMAT } from "../../../../utils";
import RecategorizeTransactions from "./RecategorizeTransactions";
import ExportReportButton from "../../ExportReportButton";
import { SharedUnverifiedDataWarningBanner } from "../../../shared";
import { SharedLabeledText } from "../../../shared/Text";
import { SharedDateRangePicker } from "../../../shared/Dates";
import useGetReportType from "../../../../hooks/useGetReportType";
import {
    selectDateRange,
    updateDateRange,
} from "../../../../redux/slices/dateRange";
import useGetFinancialReportsValidUpUntilQuery from "../../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useValidateDateRange from "../../../../hooks/useValidateDateRange";

function Lines({ ledgerLinesData }) {
    const reportType = useGetReportType();
    const selectedDateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(newDateRange));
    };

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const dateRangeUnvalidated = useValidateDateRange(
        validUpUntilData,
        selectedDateRange
    );

    if (!validUpUntilData) return null;

    const dateRange =
        reportType === REPORT_TYPES.BALANCE_SHEET
            ? getDateRangeForAllTimeToEndDate(validUpUntilData.valid_up_until)
            : selectedDateRange;

    const maxDate = dayjs().format(YYYY_MM_DD_FORMAT);

    const label =
        ledgerLinesData.ledger_name === "No data available for this date range"
            ? ""
            : `${ledgerLinesData.ledger_name} Total`;

    return (
        <div className="flex flex-col gap-y-[30px]">
            <div className="flex flex-col gap-y-[20px]">
                {reportType !== REPORT_TYPES.BALANCE_SHEET && (
                    <SharedDateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        maxDate={maxDate}
                        validUpUntil={validUpUntilData.valid_up_until}
                    />
                )}
                {dateRangeUnvalidated &&
                    reportType !== REPORT_TYPES.BALANCE_SHEET && (
                        <SharedUnverifiedDataWarningBanner
                            validUpUntil={validUpUntilData.valid_up_until}
                        />
                    )}
                <div className="flex flex-row justify-between items-center">
                    <SharedLabeledText
                        label={label}
                        text={getCurrencyString(
                            ledgerLinesData.ledger_amount_cents
                        )}
                    />
                    {
                        // TODO: remove the casing below once we implement export for revenue by category
                    }
                    {![
                        REPORT_TYPES.REVENUE_BY_CATEGORY,
                        REPORT_TYPES.EXPENSES_BY_CATEGORY,
                    ].includes(reportType) && <ExportReportButton />}
                </div>
            </div>
            <RecategorizeTransactions />
            <LinesTable lines={ledgerLinesData.ledger_lines} />
        </div>
    );
}

Lines.propTypes = {
    ledgerLinesData: PropTypes.object.isRequired,
};

export default Lines;
