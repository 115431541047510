import React from "react";
import { SharedPageTitle } from "../../shared/Text";
import TaxBenefitsCard from "./TaxBenefitsCard";
import TrophySvg from "../../../assets/Trophy";
import BadgeDollarSignSvg from "../../../assets/BadgeDollarSign";
import CombineSvg from "../../../assets/Combine";
import LightBulbSvg from "../../../assets/LightBulb";
import ShieldSvg from "../../../assets/Shield";
import FileCheckSvg from "../../../assets/FileCheck";

const cards = [
    {
        title: "Deep Expertise",
        body: "The team at Rivet has spent a decade working as auditors at the IRS and as heads of private practice.",
        icon: <TrophySvg fill="#8D58FF" height={28} width={28} />,
    },
    {
        title: "Fixed Price Engagements",
        body: "Rivet gives upfront and transparent quotes so incentives are aligned and you don't receive any surprise invoices.",
        icon: <BadgeDollarSignSvg fill="#8D58FF" height={28} width={28} />,
    },
    {
        title: "Corporate & Personal",
        body: "Both corporate & personal tax returns can be combined into one engagement for any legal entity including C Corps, S Corps, LLCs, and Sole Prop LLCs.",
        icon: <CombineSvg fill="#8D58FF" height={28} width={28} />,
    },
    {
        title: "R&D Credits",
        body: "Secure R&D credits and other tax refunds at both the federal & state level to maximize your tax savings.",
        icon: <LightBulbSvg fill="#8D58FF" height={28} width={28} />,
    },
    {
        title: "Audit Defenses",
        body: "Rivet will help with audit defenses even if a different CPA prepared the original return.",
        icon: <ShieldSvg fill="#8D58FF" height={28} width={28} />,
    },
    {
        title: "Seamless Filing",
        body: "Flychain works directly with Rivet to take as much of the tax filing process off your plate.",
        icon: <FileCheckSvg fill="#8D58FF" height={28} width={28} />,
    },
];

function TaxBenefits() {
    return (
        <div className="p-[60px]">
            <div className="text-center mb-[50px]">
                <SharedPageTitle>
                    A Seamless Tax Filing Experience
                </SharedPageTitle>
            </div>
            <div className="grid grid-cols-3 gap-[24px] w-full">
                {cards.map((card, i) => (
                    <TaxBenefitsCard
                        key={i}
                        title={card.title}
                        body={card.body}
                        icon={card.icon}
                    />
                ))}
            </div>
        </div>
    );
}

export default TaxBenefits;
