import React from "react";
import ValuationRange from "./ValuationRange";
import { SharedButton } from "../../shared";
import { SharedPageTitle } from "../../shared/Text";
import useGetValuationQuery from "../../../hooks/queriesAndMutations/useGetValuationQuery";

export const MERTZ_TAGGART_INTRO_CALL_URL =
    "https://calendar.app.google/DktvgyNeuoQnBGMW8";

function ValuationEstimate() {
    const { data: valuationData } = useGetValuationQuery();
    return valuationData ? (
        <div className="flex flex-row p-[60px] gap-x-[50px] bg-[#f9f8fc]">
            <div className="flex flex-col w-1/3">
                <SharedPageTitle className="mb-[30px]">
                    Valuation Range
                </SharedPageTitle>
                <div className="font-light text-[15px] mb-[50px]">
                    This is our best estimate of the Valuation Range of your
                    practice based on your financial data. The Valuation Range
                    that we calculate is an estimate of your business&apos;s
                    valuation. For a comprehensive valuation, please schedule a
                    free valuation below with our trusted M&A partner.
                </div>
                <SharedButton
                    onClick={() =>
                        window.open(MERTZ_TAGGART_INTRO_CALL_URL, "_blank")
                    }
                >
                    Schedule a Free Valuation
                </SharedButton>
            </div>
            <div className="flex flex-col w-2/3">
                <ValuationRange />
            </div>
        </div>
    ) : null;
}

export default ValuationEstimate;
