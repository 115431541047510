import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import CalendarSvg from "../../../../assets/Calendar.svg";
import ChevronsUpDownSvg from "../../../../assets/ChevronsUpDown.svg";

function DatePickerDisplay({ date, onClick }) {
    return (
        <div
            className="flex flex-row items-center p-[10px] cursor-pointer shadow-md rounded-[12px] bg-white"
            onClick={onClick}
        >
            <img
                className="w-[15px] mr-[15px]"
                src={CalendarSvg}
                alt="Calendar"
            />
            <div className="mr-[15px] text-base font-normal">
                {dayjs(date).format("MMM D, YYYY")}
            </div>
            <img
                className="w-[7px]"
                src={ChevronsUpDownSvg}
                alt="ChevronsUpDown"
            />
        </div>
    );
}

DatePickerDisplay.propTypes = {
    date: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default DatePickerDisplay;
