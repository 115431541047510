import React from "react";
import PropTypes from "prop-types";
import useGetProviderQuery from "../../../../../hooks/queriesAndMutations/useGetProviderQuery";
import {
    useGetDrawPaymentCrosscheckQuery,
    useGetFinanceReportForProviderQuery,
} from "../../../../../redux/slices/adminApi";
import PostCollectionTable from "./PostCollectionTable";
import PostCollectionFinanceReport from "./PostCollectionFinanceReport";
import PostCollectionDrawsTable from "./PostCollectionDrawsTable";
import {
    SharedAlert,
    SharedButton,
    SharedCard,
    SharedBackIconButton,
} from "../../../../shared";
import { SharedSectionTitle } from "../../../../shared/Text";
import CircleCheckSvg from "../../../../../assets/CircleCheck";
import TriangleAlertSvg from "../../../../../assets/TriangleAlert";

const renderCrosscheckMessage = (crosscheckPassed, crosscheckData) => {
    if (crosscheckPassed) {
        return (
            <SharedAlert
                icon={<CircleCheckSvg fill="#00B894" width={18} height={18} />}
                message={`All payments are balanced. Draws checked: ${crosscheckData.draws_checked}`}
                borderColor="border-flychainGreen"
            />
        );
    }

    return (
        <SharedAlert
            icon={<TriangleAlertSvg fill="#D32F2F" width={18} height={18} />}
            message={`Payments are unbalanced. Draws checked: ${crosscheckData.draws_checked}`}
            borderColor="border-flychainError"
        />
    );
};

function PostCollection({ selectedPayments, setCurrentTabIndex }) {
    const providerId = selectedPayments[0].provider_id;
    const { data: provider } = useGetProviderQuery({
        providerId,
    });
    const { data: crosscheckData } =
        useGetDrawPaymentCrosscheckQuery(providerId);
    const { data: financeReport } =
        useGetFinanceReportForProviderQuery(providerId);

    if (!provider || !crosscheckData || !financeReport) {
        return null;
    }

    const crosscheckPassed = crosscheckData.unbalanced_draws.length === 0;

    const onClickBack = () => {
        setCurrentTabIndex(3);
    };

    return (
        <div className="flex flex-col gap-y-[30px] px-[30px] pb-[30px]">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row items-center gap-x-[20px] ">
                    <SharedBackIconButton onClick={onClickBack} />
                    <SharedSectionTitle>Post-Collection</SharedSectionTitle>
                </div>
                <SharedButton
                    onClick={() => window.location.reload()}
                    disabled={!crosscheckPassed}
                >
                    End Collection
                </SharedButton>
            </div>
            <SharedCard className="flex flex-col gap-y-[20px]">
                <PostCollectionTable
                    selectedPayments={selectedPayments}
                    tableTitle="Collected Payments"
                />
                {renderCrosscheckMessage(crosscheckPassed, crosscheckData)}
            </SharedCard>
            <PostCollectionFinanceReport
                financeReport={financeReport}
                provider={provider}
            />
            <SharedCard>
                <PostCollectionDrawsTable draws={financeReport.draws} />
            </SharedCard>
            <SharedCard>
                <PostCollectionTable
                    selectedPayments={financeReport.payments}
                    tableTitle="Previous Collections"
                    emptyArrayMessage="No previous collections found for the provider"
                />
            </SharedCard>
            <SharedCard>
                <PostCollectionTable
                    selectedPayments={financeReport.upcoming_payments}
                    tableTitle="Upcoming Collections"
                    emptyArrayMessage="No upcoming collections found for the provider"
                />
            </SharedCard>
        </div>
    );
}

PostCollection.propTypes = {
    selectedPayments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setCurrentTabIndex: PropTypes.func.isRequired,
};

export default PostCollection;
