import dayjs from "dayjs";
import { YYYY_MM_DD_FORMAT } from "../../../../utils";

const adjustEndDate = (startDate, endDate, maxDate) => {
    const parsedMaxDate = dayjs(maxDate);
    return parsedMaxDate.isBetween(startDate, endDate, "day", "[]")
        ? parsedMaxDate
        : endDate;
};

const getDateRange = (startDate, endDate, maxDate) => ({
    startDate: startDate.format(YYYY_MM_DD_FORMAT),
    endDate: adjustEndDate(startDate, endDate, maxDate).format(
        YYYY_MM_DD_FORMAT
    ),
});

const getQuarterDates = (year, quarter) => {
    const startMonth = quarter * 3;
    const startDate = dayjs(
        `${year}-${String(startMonth + 1).padStart(2, "0")}-01`
    );
    const endDate = startDate.add(2, "month").endOf("month");

    return { startDate, endDate };
};

export const getDateRangeForAnnual = (year, maxDate) => {
    const startDate = dayjs().year(year).startOf("year");
    const endDate = startDate.endOf("year");
    return getDateRange(startDate, endDate, maxDate);
};

export const getDateRangeForMonth = (year, month, maxDate) => {
    const startDate = dayjs().year(year).month(month).startOf("month");
    const endDate = startDate.endOf("month");
    return getDateRange(startDate, endDate, maxDate);
};

export const getDateRangeForQuarter = (year, quarter, maxDate) => {
    const { startDate, endDate: quarterEndDate } = getQuarterDates(
        year,
        quarter
    );
    return getDateRange(startDate, quarterEndDate, maxDate);
};
