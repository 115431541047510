/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import PlaidAccount from "../../../PlaidAccount";

function PlaidAccountRadio({ account, onChange, currentValue }) {
    const { account_id } = account;
    return (
        <div className="flex flex-row items-center">
            <Radio
                checked={currentValue === account_id}
                onChange={onChange}
                value={account_id}
            />
            <div className="flex-1">
                <PlaidAccount account={account} />
            </div>
        </div>
    );
}

PlaidAccountRadio.propTypes = {
    account: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    currentValue: PropTypes.string.isRequired,
};

export default PlaidAccountRadio;
