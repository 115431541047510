/* eslint-disable camelcase */
import React, { useState } from "react";
import getPostBody from "./utils";
import { SharedButton } from "../shared";
import { SharedPageTitle } from "../shared/Text";
import { SharedPlaidAccountsRadioGroup } from "../shared/Inputs";
import {
    useAddProviderBankAccountMutation,
    useGetPlaidItemsWithOnlyCheckingAccountsQuery,
} from "../../redux/slices/api";

function SelectBankAccount() {
    const { data: plaidItemsWithOnlyCheckingAccounts } =
        useGetPlaidItemsWithOnlyCheckingAccountsQuery();

    const [currentExternalPlaidAccountId, setCurrentExternalPlaidAccountId] =
        useState("");

    const handleChange = (event) => {
        setCurrentExternalPlaidAccountId(event.target.value);
    };

    const [addProviderBankAccount] = useAddProviderBankAccountMutation();

    const onSubmit = () => {
        const body = getPostBody(
            plaidItemsWithOnlyCheckingAccounts,
            currentExternalPlaidAccountId
        );
        addProviderBankAccount(body);
    };

    return (
        <div className="flex flex-col justify-start p-[60px] gap-y-[30px]">
            <SharedPageTitle>Select Primary Checking Account</SharedPageTitle>
            <div className="text-[15px] font-[300]">
                Please select your primary checking account. If you choose to
                use our line of credit, this is where we&apos;ll deposit the
                funds. This does not commit you in any way to using our line of
                credit and you can change this account selection at any time.
            </div>
            {plaidItemsWithOnlyCheckingAccounts &&
                (plaidItemsWithOnlyCheckingAccounts.length > 0 ? (
                    <SharedPlaidAccountsRadioGroup
                        plaidItems={plaidItemsWithOnlyCheckingAccounts}
                        onChange={handleChange}
                        currentValue={currentExternalPlaidAccountId}
                    />
                ) : (
                    <div className="text-[15px] font-[300]">
                        No checking accounts were detected. For assistance,
                        please contact{" "}
                        <a href="mailto:jaime@flychain.us">jaime@flychain.us</a>
                    </div>
                ))}
            <SharedButton
                onClick={onSubmit}
                disabled={currentExternalPlaidAccountId.length === 0}
            >
                Submit
            </SharedButton>
        </div>
    );
}

export default SelectBankAccount;
