import React, { useState } from "react";
import { CALENDLY_URLS } from "../CalendlyModal/constants";
import CalendlyModal from "../CalendlyModal";
import Video from "../Video";
import MOCK_CASH_FLOW_REPORT from "./mockData";
import Section from "../FinancialReports/CashFlow/Report/Section";
import { SharedButton, SharedCard } from "../shared";
import { SharedPageTitle } from "../shared/Text";

const FLYCHAIN_DEMO_URL =
    "https://www.loom.com/embed/fb48afdb1e0840c4b4bfbd7d68e963c2";

function PaywallContent() {
    const [showCalendlyModal, setShowCalendlyModal] = useState(false);

    const inflows = MOCK_CASH_FLOW_REPORT.inflows.record;
    const outflows = MOCK_CASH_FLOW_REPORT.outflows.record;

    return (
        <div className="flex relative flex-col justify-start h-full gap-y-[20px]">
            <Section name={inflows.name}>{inflows.children}</Section>
            <Section name={outflows.name}>{outflows.children}</Section>
            <div className="absolute z-[1] w-full h-full top-0 left-0 bg-white opacity-[0.5] backdrop-blur-md flex flex-row justify-center items-center">
                <div className="w-4/5">
                    <SharedCard
                        showTab
                        tabText="Schedule Demo"
                        tabColor="green"
                    >
                        <div className="flex flex-col gap-y-[20px]">
                            <SharedPageTitle>
                                The Financial Operating System for Your Practice
                            </SharedPageTitle>
                            <div className="text-[16px] font-[400] mb-[20px]">
                                Optimize the financial health of your practice
                                with our suite of financial tools. Let us handle
                                your financial workflows so you can spend less
                                time in spreadsheets and more time with
                                patients.
                            </div>
                            <div className="flex flex-row justify-center">
                                <div className="w-[90%] h-[40vh]">
                                    <Video
                                        src={FLYCHAIN_DEMO_URL}
                                        title="Flychain Demo"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row justify-center">
                                <SharedButton
                                    onClick={() => setShowCalendlyModal(true)}
                                >
                                    Schedule a Demo
                                </SharedButton>
                            </div>
                        </div>
                    </SharedCard>
                </div>
            </div>
            {showCalendlyModal && (
                <CalendlyModal
                    onClose={() => setShowCalendlyModal(false)}
                    calendlyUrl={CALENDLY_URLS.FLYCHAIN_TEAM}
                />
            )}
        </div>
    );
}

export default PaywallContent;
