export const getUTCDateString = (date) => {
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
    };
    return date.toLocaleString("en-US", options);
};

export const YYYY_MM_DD_FORMAT = "YYYY-MM-DD";
export const MMM_D_YYYY_FORMAT = "MMM D, YYYY";
export const MMMM_D_YYYY_FORMAT = "MMMM D, YYYY";

export const getDateString = (date) => {
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    return date.toLocaleString("en-US", options);
};

export const getCurrencyString = (cents) => {
    const dollars = cents / 100;
    return dollars.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
};

// Takes a string and returns a friendly string by replacing underscores with spaces and capitalizing the first letter of each word and lowercasing the rest.
// Example: "business_entity" -> "Business Entity", "BEHAVIORAL_HEALTH" -> "Behavioral Health"
export const getFriendlyString = (str) =>
    str
        .toString()
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/\b\w/g, (letter) => letter.toUpperCase());

// Converts an empty string or a string with only whitespace to null, otherwise returns the value.
export const blankStringToNull = (str) => {
    if (!str || str.trim() === "") return null;
    return str;
};

// Returns true if the string is not empty or only whitespace, otherwise returns false.
export const notBlankString = (str) => str.trim().length > 0;
