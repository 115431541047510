import React from "react";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ExpensesByCategory from "./ExpensesByCategory";
import ExpensesByMerchant from "./ExpensesByMerchant";
import StackedBarGraph from "./StackedBarGraph";
import ValidUpUntil from "../ValidUpUntil";
import { SharedBadge, SharedHorizontalTabs } from "../../shared";
import { SharedPageTitle, SharedSectionTitle } from "../../shared/Text";
import useIsAdminDashboard from "../../../hooks/useIsAdminDashboard";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";

const TABS = [
    { key: "byCategory", label: "By Category" },
    {
        key: "byMerchant",
        label: (
            <div className="flex flex-row items-center gap-x-[10px]">
                <span>By Merchant</span>
                <SharedBadge color="green">
                    <div className="text-xs font-normal text-white">Beta</div>
                </SharedBadge>
            </div>
        ),
    },
];

function Expenses() {
    const isAdmin = useIsAdminDashboard();
    const { currentTabIndex, setSearchParams } =
        SharedHorizontalTabs.useGetTabIndex(TABS);
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: false,
    });

    if (!businessEntities) return null;

    const multipleBusinessEntities = businessEntities.length > 1;

    // Tab change handler to be passed to the HorizontalTabs component
    const handleTabChange = (index) => {
        setSearchParams({ tab: TABS[index].key }, { replace: true });
    };

    return (
        <div className="flex flex-col px-[60px] pb-[60px] pt-[30px] gap-y-[30px]">
            {multipleBusinessEntities ? (
                <div className="flex flex-row gap-x-[30px]">
                    <ValidUpUntil />
                    <BusinessEntitySelect inline />
                </div>
            ) : (
                <ValidUpUntil />
            )}
            <div className="flex flex-col gap-y-[30px]">
                <SharedPageTitle>Expenses By Category</SharedPageTitle>
                <div className="flex flex-col gap-y-[20px]">
                    <SharedSectionTitle>By Month</SharedSectionTitle>
                    <StackedBarGraph />
                </div>
                <div className="flex flex-col gap-y-[20px]">
                    <SharedSectionTitle>For Date Range</SharedSectionTitle>
                    {
                        // TODO: remove the conditional code below once we've refined ExpensesByMerchant
                        isAdmin ? (
                            <>
                                <SharedHorizontalTabs.Component
                                    tabs={TABS.map((tab) => tab.label)}
                                    initialIndex={currentTabIndex}
                                    onChange={handleTabChange}
                                />
                                {currentTabIndex === 0 ? (
                                    <ExpensesByCategory />
                                ) : (
                                    <ExpensesByMerchant />
                                )}
                            </>
                        ) : (
                            <ExpensesByCategory />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Expenses;
