import React from "react";
import PropTypes from "prop-types";

function AccountsConnected({ numberOfAccounts }) {
    return (
        <div className="text-[14px] font-[400] text-flychainGreen">
            {`${numberOfAccounts} connected`}
        </div>
    );
}

AccountsConnected.propTypes = {
    numberOfAccounts: PropTypes.number.isRequired,
};

export default AccountsConnected;
