import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HelpSvg from "../../assets/Help.svg";
import SignOutSvg from "../../assets/SignOut.svg";
import SupportModal from "../SupportModal";
import signOut from "../../redux/slices/authentication/thunks/signOut";

function SideBarBottomMenu() {
    const [showSupportModal, setShowSupportModal] = useState(false);

    const openSupportModal = () => {
        setShowSupportModal(true);
    };

    const closeSupportModal = () => {
        setShowSupportModal(false);
    };

    const isUserSignedIn = useSelector(
        (state) => state.authentication.isUserSignedIn
    );

    const dispatch = useDispatch();

    const onClickSignOut = () => {
        dispatch(signOut());
    };

    return (
        <div className="flex flex-col p-[10px]">
            <div
                className="flex flex-row items-center cursor-pointer h-[30px] gap-x-[5px]"
                onClick={openSupportModal}
            >
                <img className="w-[15px]" src={HelpSvg} alt="Help" />
                <div className="text-[14px] font-[400] text-white">Help</div>
            </div>
            {showSupportModal && <SupportModal onClose={closeSupportModal} />}
            {isUserSignedIn ? (
                <div
                    className="flex flex-row items-center cursor-pointer h-[30px] gap-x-[5px]"
                    onClick={onClickSignOut}
                >
                    <img className="w-[15px]" src={SignOutSvg} alt="Sign Out" />
                    <div className="text-[14px] font-[400] text-white">
                        Sign Out
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default SideBarBottomMenu;
