import React from "react";
import { useNavigate } from "react-router-dom";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import BusinessEntityCard from "./BusinessEntityCard";
import { SharedButton } from "../../shared";
import useGetProviderPath from "../../../hooks/useGetProviderPath";

function BusinessEntityOverview() {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: true,
    });
    const navigate = useNavigate();
    const providerPath = useGetProviderPath();

    return businessEntities ? (
        <>
            <SharedButton
                onClick={() =>
                    navigate(`${providerPath}/settings/createEntity`)
                }
            >
                Add Business Entity
            </SharedButton>
            <div className="flex flex-col gap-y-[20px]">
                {businessEntities.map((businessEntity) => (
                    <BusinessEntityCard
                        key={businessEntity.id}
                        businessEntity={businessEntity}
                    />
                ))}
            </div>
        </>
    ) : null;
}

export default BusinessEntityOverview;
