import { useLazyDownloadGeneralLedgerAsyncQuery as useAdminLazyDownloadGeneralLedgerAsyncQuery } from "../../../redux/slices/adminApi";

const useLazyDownloadGeneralLedgerAsyncQuery = () => {
    const [adminTrigger, adminResult] =
        useAdminLazyDownloadGeneralLedgerAsyncQuery();

    const triggerWithParams = (params) => adminTrigger(params);

    return [triggerWithParams, adminResult];
};

export default useLazyDownloadGeneralLedgerAsyncQuery;
