import React from "react";
import PropTypes from "prop-types";
import { SharedCard } from "../shared";

function PlaidAccount({ account }) {
    const { name, mask, type } = account;

    return (
        <SharedCard padding={10}>
            <div className="flex flex-row">
                <div>{name}</div>
                {mask && (
                    <div className="ml-[30px]">
                        <div>{`(••••${mask})`}</div>
                    </div>
                )}
            </div>
            <div className="text-sm text-flychainGray">
                {type.toUpperCase()}
            </div>
        </SharedCard>
    );
}

PlaidAccount.propTypes = {
    account: PropTypes.object.isRequired,
};

export default PlaidAccount;
