import React, { useState } from "react";
import Content from "../../../Content";
import CollectionTabs from "./CollectionTabs";
import Selection from "./Selection";
import LastPaymentCheck from "./LastPaymentCheck";
import BalanceCheck from "./BalanceCheck";
import Collection from "./Collection";
import PostCollection from "./PostCollection";

const getContent = (
    selectedPayments,
    setSelectedPayments,
    currentTabIndex,
    setCurrentTabIndex
) => {
    switch (currentTabIndex) {
        case 0:
            return (
                <Selection
                    selectedPayments={selectedPayments}
                    setSelectedPayments={setSelectedPayments}
                    setCurrentTabIndex={setCurrentTabIndex}
                />
            );
        case 1:
            return (
                <LastPaymentCheck
                    selectedPayments={selectedPayments}
                    setSelectedPayments={setSelectedPayments}
                    setCurrentTabIndex={setCurrentTabIndex}
                />
            );
        case 2:
            return (
                <BalanceCheck
                    selectedPayments={selectedPayments}
                    setCurrentTabIndex={setCurrentTabIndex}
                />
            );
        case 3:
            return (
                <Collection
                    selectedPayments={selectedPayments}
                    setSelectedPayments={setSelectedPayments}
                    setCurrentTabIndex={setCurrentTabIndex}
                />
            );
        case 4:
            return (
                <PostCollection
                    selectedPayments={selectedPayments}
                    setCurrentTabIndex={setCurrentTabIndex}
                />
            );
        default:
            throw new Error("No content for state");
    }
};

function CollectionFlow() {
    const [selectedPayments, setSelectedPayments] = useState([]);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    return (
        <div>
            <CollectionTabs currentTabIndex={currentTabIndex} />
            <Content>
                {getContent(
                    selectedPayments,
                    setSelectedPayments,
                    currentTabIndex,
                    setCurrentTabIndex
                )}
            </Content>
        </div>
    );
}

export default CollectionFlow;
