import React from "react";
import PropTypes from "prop-types";
import useGetMonthlyLedgerTotalsQuery from "../../../hooks/queriesAndMutations/useGetMonthlyLedgerTotalsQuery";
import { SharedSectionTitle } from "../../shared/Text";
import SharedMonthlyGraph from "../../Graphs/MonthlyGraph";

function LedgerLinesMonthlyGraph({ ledgerName }) {
    const { data } = useGetMonthlyLedgerTotalsQuery();

    return data ? (
        <div className="flex flex-col gap-y-[20px]">
            <SharedSectionTitle>Monthly Totals</SharedSectionTitle>
            <SharedMonthlyGraph data={data} ledgerName={ledgerName} />
        </div>
    ) : null;
}

LedgerLinesMonthlyGraph.propTypes = {
    ledgerName: PropTypes.string.isRequired,
};

export default LedgerLinesMonthlyGraph;
