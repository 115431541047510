import React, { useState } from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../utils";
import { SharedAlert, SharedButton, SharedCard } from "../../../../shared";
import { SharedSectionTitle } from "../../../../shared/Text";
import TriangleAlertSvg from "../../../../../assets/TriangleAlert";
import CopySvg from "../../../../../assets/Copy";

function PostCollectionFinanceReport({ financeReport, provider }) {
    const [copiedReport, setCopiedReport] = useState(false);

    const copyFinanceReport = () => {
        setCopiedReport(true);
        const overview = financeReport.balance_overview;
        const reportText = [
            `Total Principal\t${getCurrencyString(overview.total_principal)}`,
            `Total Fee\t${getCurrencyString(overview.total_fee)}`,
            `Total Payment\t${getCurrencyString(overview.total_payment)}`,
            `Outstanding Balance\t${getCurrencyString(overview.balance)}`,
        ].join("\n");

        navigator.clipboard.writeText(reportText);
        setTimeout(() => setCopiedReport(false), 100);
    };

    return (
        <SharedCard className="flex flex-col gap-y-[20px]">
            <SharedSectionTitle className="text-flychainGray">
                {`${provider.legal_name} Finance Report`}
            </SharedSectionTitle>
            <div className="flex flex-row items-center gap-x-[60px]">
                <div className="flex flex-col gap-y-[10px] items-center w-fit">
                    <p className="font-semibold text-flychainGray text-[16px]">
                        Total Principal
                    </p>
                    <p className="font-semibold text-[24px]">
                        {getCurrencyString(
                            financeReport.balance_overview.total_principal
                        )}
                    </p>
                </div>
                <div className="flex flex-col gap-y-[10px] items-center w-fit">
                    <p className="font-semibold text-flychainGray text-[16px]">
                        Total Fee
                    </p>
                    <p className="font-semibold text-[24px]">
                        {getCurrencyString(
                            financeReport.balance_overview.total_fee
                        )}
                    </p>
                </div>
                <div className="flex flex-col gap-y-[10px] items-center w-fit">
                    <p className="font-semibold text-flychainGray text-[16px]">
                        Total Payment
                    </p>
                    <p className="font-semibold text-[24px]">
                        {getCurrencyString(
                            financeReport.balance_overview.total_payment
                        )}
                    </p>
                </div>
                <div className="flex flex-col gap-y-[10px] items-center w-fit">
                    <p className="font-semibold text-flychainGray text-[16px]">
                        Outstanding Balance
                    </p>
                    <p className="font-semibold text-[24px]">
                        {getCurrencyString(
                            financeReport.balance_overview.balance
                        )}
                    </p>
                </div>
                <SharedButton
                    onClick={copyFinanceReport}
                    className={`inline-block rounded-full px-[16px] py-[12px] w-fit h-fit text-center ${
                        copiedReport && "opacity-[75%]"
                    }`}
                >
                    <div className="flex flex-row items-center gap-x-[10px]">
                        <CopySvg fill="white" height={16} width={16} />
                        Copy report
                    </div>
                </SharedButton>
            </div>
            <SharedAlert
                icon={
                    <TriangleAlertSvg fill="#8D58FF" width={18} height={18} />
                }
                message={
                    <div>
                        IMPORTANT! Please email{" "}
                        {<b>{provider.contact_email}</b> || "the provider"} with
                        the finance report details above.
                    </div>
                }
                borderColor="border-flychainPurple-light"
            />
        </SharedCard>
    );
}

PostCollectionFinanceReport.propTypes = {
    provider: PropTypes.shape({
        id: PropTypes.string.isRequired,
        legal_name: PropTypes.string.isRequired,
        contact_email: PropTypes.string,
    }).isRequired,
    financeReport: PropTypes.shape({
        balance_overview: PropTypes.shape({
            total_principal: PropTypes.number.isRequired,
            total_fee: PropTypes.number.isRequired,
            total_payment: PropTypes.number.isRequired,
            balance: PropTypes.number.isRequired,
        }).isRequired,
        draws: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
        payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
        upcoming_payments: PropTypes.arrayOf(PropTypes.object.isRequired)
            .isRequired,
    }).isRequired,
};

export default PostCollectionFinanceReport;
