import React from "react";
import { useNavigate } from "react-router-dom";
import { SharedPageTitle } from "../../shared/Text";
import CapitalProduct from "./CapitalProduct";
import CAPITAL_PRODUCT_PATHS from "./constants";

function CapitalProducts() {
    const navigate = useNavigate();

    const onClickForAdvancedPaymentOnClaims = () => {
        navigate(CAPITAL_PRODUCT_PATHS.ADVANCED_PAYMENT_ON_CLAIMS);
    };

    const onClickForTermLoan = () => {
        navigate(CAPITAL_PRODUCT_PATHS.TERM_LOAN);
    };

    const onClickForSBAPrograms = () => {
        navigate(CAPITAL_PRODUCT_PATHS.SBA_PROGRAMS);
    };

    const advancedPaymentOnClaims = (
        <CapitalProduct
            title="Advanced Payment on Claims"
            body="Enroll in our Advanced Payment on Claims program to bring forward your insurance reimbursements so you don't have to wait 1-4 months to get paid. Almost all providers qualify and we can fund within 2 business days."
            onClick={onClickForAdvancedPaymentOnClaims}
        />
    );

    const termLoan = (
        <CapitalProduct
            title="Term Loan"
            body="Get a term loan to fund your next big growth initiative. We offer term loans with repayment periods of 6 months to 2 years and we can fund within 3 business days."
            onClick={onClickForTermLoan}
        />
    );

    const sbaPrograms = (
        <CapitalProduct
            title="SBA Programs"
            body="Apply for an SBA loan to get access to some of the most favorable rates in the country! In addition, the SBA Express program can get you funded within 5 business days."
            onClick={onClickForSBAPrograms}
        />
    );

    const acquisitionFinancing = (
        <CapitalProduct
            title="Acquisition Financing"
            body="Expand your practice to new locations and states by leveraging our acquisition financing. Our program is purpose-built for healthcare and is intended to help take your practice to the next level."
            comingSoon
        />
    );

    return (
        <div className="flex flex-col items-center p-[60px]">
            <SharedPageTitle className="mb-[30px]">
                Our Capital Products
            </SharedPageTitle>
            <div className="flex flex-col gap-[40px] w-full">
                <div className="flex flex-row justify-center gap-[40px]">
                    {advancedPaymentOnClaims}
                    {termLoan}
                </div>
                <div className="flex flex-row justify-center gap-[40px]">
                    {sbaPrograms}
                    {acquisitionFinancing}
                </div>
            </div>
        </div>
    );
}

export default CapitalProducts;
