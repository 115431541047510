import { useParams } from "react-router-dom";
import { useCreateBusinessEntityMutation as useAdminCreateBusinessEntityMutation } from "../../../redux/slices/adminApi";
import { useCreateBusinessEntityMutation as useProviderCreateBusinessEntityMutation } from "../../../redux/slices/api";

const useCreateBusinessEntityMutation = (params) => {
    // Get the providerId from the params
    let providerId = params ? params.providerId : null;

    const urlParams = useParams();

    // If providerId is not provided in function params, try to get it from the url params
    if (!providerId) {
        providerId = urlParams.providerId || null;
    }

    // If providerId is provided via function params or url params, use the admin mutation
    if (providerId) {
        const [trigger, ...rest] = useAdminCreateBusinessEntityMutation();
        return [(body) => trigger({ providerId, body }), ...rest]; // Wrap the trigger function in a function that passes in the providerId
    }

    // If providerId is not provided at all, use the provider mutation
    return useProviderCreateBusinessEntityMutation();
};

export default useCreateBusinessEntityMutation;
