/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../utils";

const getFlex = (outstandingBalanceCents, availableBalanceCents) => {
    const percentage =
        outstandingBalanceCents /
        (outstandingBalanceCents + availableBalanceCents);
    // Round to two decimal places
    const rounded = Math.round(percentage * 100) / 100;
    // Cap the value at 1
    return Math.min(rounded, 1);
};

function Line({ outstandingBalanceCents, availableBalanceCents }) {
    const outstandingBalanceLineStyle = {
        flex: getFlex(outstandingBalanceCents, availableBalanceCents),
    };
    return (
        <div className="flex flex-col gap-y-[16px]">
            <div className="h-[16px] bg-white rounded-lg flex flex-row">
                <div
                    className="bg-[#CDE6F0] rounded-lg"
                    style={outstandingBalanceLineStyle}
                />
            </div>
            <div className="flex flex-row justify-between">
                <div>
                    <div className="text-[11px] font-normal text-flychainGray">
                        Outstanding balance
                    </div>
                    <div className="text-[19px] font-semibold">
                        {getCurrencyString(outstandingBalanceCents)}
                    </div>
                </div>
                <div className="text-right">
                    <div className="text-[11px] font-normal text-flychainGray">
                        Available funding
                    </div>
                    <div className="text-[19px] font-semibold">
                        {getCurrencyString(availableBalanceCents)}
                    </div>
                </div>
            </div>
        </div>
    );
}

Line.propTypes = {
    outstandingBalanceCents: PropTypes.number.isRequired,
    availableBalanceCents: PropTypes.number.isRequired,
};

export default Line;
