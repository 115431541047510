import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SharedSectionTitle } from "../../../../shared/Text";
import { getCurrencyString, MMMM_D_YYYY_FORMAT } from "../../../../../utils";

// Add the UTC plugin to dayjs
dayjs.extend(utc);

function BalanceCheckTable({ selectedPayments }) {
    return (
        <div className="flex flex-col">
            <SharedSectionTitle className="mb-[12px] text-flychainGray">
                Payments to Collect
            </SharedSectionTitle>
            <div className="grid grid-cols-[2.5fr_1fr_2fr_2.5fr] gap-x-[10px] text-[14px] font-semibold border-b border-gray-200 py-[8px]">
                <div>Payment ID</div>
                <div>Amount</div>
                <div>Earliest Payment Date</div>
                <div>Draw ID</div>
            </div>
            {selectedPayments
                .sort((a, b) =>
                    dayjs(a.earliest_payment_date).diff(
                        dayjs(b.earliest_payment_date)
                    )
                )
                .map((payment) => (
                    <div
                        key={payment.id}
                        className="grid grid-cols-[2.5fr_1fr_2fr_2.5fr] gap-x-[10px] text-sm items-center border-b border-gray-200 py-[8px]"
                    >
                        <div>{payment.id}</div>
                        <div>{getCurrencyString(payment.payment_amount)}</div>
                        <div>
                            {dayjs(payment.earliest_payment_date)
                                .utc()
                                .format(MMMM_D_YYYY_FORMAT)}
                        </div>
                        <div>{payment.draw_id}</div>
                    </div>
                ))}
        </div>
    );
}

BalanceCheckTable.propTypes = {
    selectedPayments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default BalanceCheckTable;
