import React from "react";
import PropTypes from "prop-types";

function Subheading({ children, className }) {
    return (
        <div className={`text-[18px] text-flychainGray ${className}`}>
            {children}
        </div>
    );
}

Subheading.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

Subheading.defaultProps = {
    className: "",
};

export default Subheading;
