import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import getReadableReportType from "../utils";
import { YYYY_MM_DD_FORMAT } from "../../../utils";
import REPORT_TYPES from "../constants";
import {
    SharedButton,
    SharedModal,
    SharedUnverifiedDataWarningBanner,
} from "../../shared";
import {
    SharedPageTitle,
    SharedLabel,
    SharedLabeledText,
} from "../../shared/Text";
import { SharedDateRangePicker, SharedDatePicker } from "../../shared/Dates";
import {
    SharedFormTextInput,
    SharedRequiredMessage,
} from "../../shared/Inputs";
import Checkmark from "../../../assets/Checkmark.svg";
import useLazyDownloadReportAsyncQuery from "../../../hooks/useLazyDownloadReportAsyncQuery";
import useGetReportType from "../../../hooks/useGetReportType";
import useGetBusinessEntityQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntityQuery";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useValidateDateRange from "../../../hooks/useValidateDateRange";
import { selectDateRange } from "../../../redux/slices/dateRange";

const defaultValues = {
    email: "",
};

const schema = yup.object({
    email: yup.string().email().required(SharedRequiredMessage),
});

function AsyncModal({ onClose }) {
    const { businessEntityId } = useParams();
    const reportType = useGetReportType();
    const dateRange = useSelector(selectDateRange);
    const [currentDateRange, setCurrentDateRange] = useState(dateRange);
    const [trigger] = useLazyDownloadReportAsyncQuery();

    const { data: businessEntity } = useGetBusinessEntityQuery({
        businessEntityId,
    });

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const [currentBalanceSheetEndDate, setCurrentBalanceSheetEndDate] =
        useState(validUpUntilData.valid_up_until);

    const dateRangeUnvalidated = useValidateDateRange(
        validUpUntilData,
        currentDateRange
    );

    const handleDateRangeChange = (newDateRange) => {
        setCurrentDateRange(newDateRange);
    };

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [successMessage, setSuccessMessage] = useState(null);

    const handleFormSubmit = async (formData) => {
        try {
            setSuccessMessage(null);

            const { email } = formData;

            const { startDate } = currentDateRange;
            let { endDate } = currentDateRange;
            if (reportType === REPORT_TYPES.BALANCE_SHEET) {
                endDate = currentBalanceSheetEndDate;
            }

            await trigger({
                startDate,
                endDate,
                email,
            });

            setSuccessMessage(
                "The report will be sent to your email shortly! If you don't receive an email within 5 minutes, please try downloading the report again."
            );
        } catch (error) {
            console.error("Error downloading report:", error);
            setSuccessMessage(null);
        }
    };

    if (!validUpUntilData || !businessEntity) {
        return null;
    }

    const businessEntityName =
        businessEntity.display_name || businessEntity.legal_name;

    return (
        <SharedModal
            onClose={onClose}
            cardClassName="h-fit min-w-[500px]"
            centerVertical={false}
        >
            <div className="flex flex-col gap-y-[24px] max-w-[550px]">
                <SharedPageTitle>{`Download ${getReadableReportType(
                    reportType
                )}`}</SharedPageTitle>
                <SharedLabeledText
                    label="Business Entity Name"
                    text={businessEntityName}
                />
                <div>
                    {reportType === REPORT_TYPES.BALANCE_SHEET ? (
                        <>
                            <div className="mb-[8px]">
                                <SharedLabel>
                                    Please confirm the end date
                                </SharedLabel>
                            </div>
                            <SharedDatePicker
                                date={currentBalanceSheetEndDate}
                                onChange={setCurrentBalanceSheetEndDate}
                                maxDate={validUpUntilData.valid_up_until}
                            />
                        </>
                    ) : (
                        <>
                            <div className="mb-[8px]">
                                <SharedLabel>
                                    Please confirm the date range
                                </SharedLabel>
                            </div>
                            <SharedDateRangePicker
                                dateRange={currentDateRange}
                                onChange={handleDateRangeChange}
                                maxDate={dayjs().format(YYYY_MM_DD_FORMAT)}
                                validUpUntil={validUpUntilData.valid_up_until}
                            />
                        </>
                    )}
                </div>
                {dateRangeUnvalidated && (
                    <div>
                        <SharedUnverifiedDataWarningBanner
                            validUpUntil={validUpUntilData.valid_up_until}
                        />
                    </div>
                )}
                <div className="mb-[8px]">
                    <div className="mb-[8px]">
                        <SharedLabel>
                            We&apos;ll send the report to this email
                        </SharedLabel>
                    </div>
                    <div className="w-2/3">
                        <SharedFormTextInput
                            className="w-full"
                            name="email"
                            placeholder="Enter your email"
                            control={control}
                        />
                    </div>
                </div>
                <div className="flex flex-row mr-[32px]">
                    <div className="mr-[16px]">
                        <SharedButton
                            onClick={handleSubmit(handleFormSubmit)}
                            disabled={!isValid}
                        >
                            Download
                        </SharedButton>
                    </div>
                    <SharedButton color="secondary" onClick={onClose}>
                        Done
                    </SharedButton>
                </div>
                {successMessage && (
                    <div className="flex flex-row items-start mt-[16px]">
                        <img
                            className="w-[20px] mr-[8px]"
                            src={Checkmark}
                            alt="Checkmark"
                        />
                        <div className="flex flex-col">
                            <p className="text-[14px] font-bold text-flychainPurple-dark">
                                The report will be sent to your email shortly!
                            </p>
                            <p className="text-[14px] font-medium text-flychainPurple-dark">
                                If you don&apos;t receive an email within 5
                                minutes, please try downloading the report
                                again.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </SharedModal>
    );
}

AsyncModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default AsyncModal;
