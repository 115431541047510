import React, { useState } from "react";
import DeleteUserModal from "./DeleteUserModal";
import { SharedButton } from "../../shared";
import { SharedPageTitle, SharedSubheading } from "../../shared/Text";
import {
    useGetProvidersQuery,
    useGetTestUsersQuery,
    useDeleteUserMutation,
} from "../../../redux/slices/adminApi";

function TestUsers() {
    const { data: testUsers } = useGetTestUsersQuery();

    const { data: providerIdToProvider } = useGetProvidersQuery();

    const [deleteUser] = useDeleteUserMutation();

    const [provider, setProvider] = useState(null);
    const [user, setUser] = useState(null);

    const onClickForModal = () => {
        deleteUser(user.id);
        setUser(null);
        setProvider(null);
    };

    const onCloseForModal = () => {
        setUser(null);
        setProvider(null);
    };

    return testUsers && providerIdToProvider ? (
        <div>
            <SharedPageTitle>Test Users</SharedPageTitle>
            <SharedSubheading>
                You can delete any of the users below
            </SharedSubheading>
            <div className="my-[30px]">
                <table className="border-separate border-spacing-x-[16px] border-spacing-y-[32px]">
                    <thead>
                        <tr>
                            <th>
                                <div>Email</div>
                            </th>
                            <th>
                                <div>ID</div>
                            </th>
                            <th>
                                <div>Provider Name</div>
                            </th>
                            <th>
                                <div>Provider DBA</div>
                            </th>
                            <th>
                                <div>Provider ID</div>
                            </th>
                            <th>
                                <div>Delete User?</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {testUsers.map((testUser) => {
                            const providerForTestUser = testUser.provider_id
                                ? providerIdToProvider[testUser.provider_id]
                                : null;

                            const onClick = () => {
                                setUser(testUser);
                                if (providerForTestUser) {
                                    setProvider(providerForTestUser);
                                } else {
                                    setProvider(null);
                                }
                            };
                            return (
                                <tr key={testUser.id}>
                                    <td>
                                        <div>{testUser.email}</div>
                                    </td>
                                    <td>
                                        <div>{testUser.id}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {providerForTestUser
                                                ? providerForTestUser.legal_name
                                                : ""}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {providerForTestUser
                                                ? providerForTestUser.dba || ""
                                                : ""}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {providerForTestUser
                                                ? providerForTestUser.id
                                                : ""}
                                        </div>
                                    </td>
                                    <td>
                                        <SharedButton onClick={onClick}>
                                            Delete user
                                        </SharedButton>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {user && (
                <DeleteUserModal
                    user={user}
                    provider={provider}
                    onClick={onClickForModal}
                    onClose={onCloseForModal}
                />
            )}
        </div>
    ) : null;
}

export default TestUsers;
