import React from "react";
import PropTypes from "prop-types";
import { SharedCard } from "../../../shared";

function ExclusiveBenefitsCard({ icon, title, body, action }) {
    return (
        <SharedCard showTab={false} className="flex flex-col w-[35%]">
            <div className="flex flex-col justify-between items-center h-full gap-y-[40px]">
                <div className="flex flex-col items-center">
                    {icon}
                    <div className="text-xl font-semibold mt-[16px] text-center">
                        {title}
                    </div>
                    <div className="text-base font-normal text-center text-flychainGray mt-[20px]">
                        {body}
                    </div>
                </div>
                {action}
            </div>
        </SharedCard>
    );
}

ExclusiveBenefitsCard.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    action: PropTypes.node,
};

ExclusiveBenefitsCard.defaultProps = {
    action: undefined,
};

export default ExclusiveBenefitsCard;
