import { useParams } from "react-router-dom";
import { useGetProviderQuery as useAdminGetProviderQuery } from "../../../redux/slices/adminApi";
import { useGetProviderQuery as useProviderGetProviderQuery } from "../../../redux/slices/api";

const useGetProviderQuery = (params) => {
    // Get the providerId from the params
    let providerId = params ? params.providerId : null;

    const urlParams = useParams();

    // If providerId is not provided in function params, try to get it from the url params
    if (!providerId) {
        providerId = urlParams.providerId || null;
    }

    if (providerId) {
        return useAdminGetProviderQuery(providerId);
    }

    return useProviderGetProviderQuery();
};

export default useGetProviderQuery;
