import React from "react";
import MercuryBillPayDashboardSvg from "../../../assets/MercuryBillPayDashboard.svg";
import Logos from "../HeaderSection/Logos";
import SignupAndScheduleDemo from "../SignupAndScheduleDemo";
import { SharedPageTitle } from "../../shared/Text";

function BillPayHeader() {
    return (
        <div className="flex flex-row gap-x-[50px] p-[60px] bg-gradient-to-tr from-[#e6f0f4] from-28% to-flychainGreen-light to-90%">
            <div className="flex-[5] flex flex-col">
                <div className="mb-[40px]">
                    <Logos />
                </div>
                <div className="mb-[20px]">
                    <SharedPageTitle>
                        Unlimited Bill Pay For Free
                    </SharedPageTitle>
                </div>
                <div className="mb-[40px] text-[12px] font-normal">
                    Mercury&apos;s
                    <sup className="font-normal text-[8px]">1</sup> Bill Pay
                    solution is built right into your bank account to simplify
                    operations and optimize your cash flow. Leverage
                    Mercury&apos;s sophisticated accounts payable automation to
                    stay on top of bill management, process bills faster, and
                    avoid duplicate bills. Get started today with unlimited bill
                    payments at zero cost.
                </div>
                <SignupAndScheduleDemo />
            </div>
            <div className="flex-[4] flex flex-col justify-center">
                <img src={MercuryBillPayDashboardSvg} alt="MercuryDashboard" />
            </div>
        </div>
    );
}

export default BillPayHeader;
