import React, { useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import SearchableBusinessEntitySelect from "./SearchableBusinessEntitySelect";
import BusinessAvailableCreditLimitDisplay from "./BusinessAvailableCreditLimitDisplay";
import { SharedAlert, SharedButton, SharedCard } from "../../../../shared";
import { SharedSectionTitle } from "../../../../shared/Text";
import {
    SharedStateSelectInput,
    SharedStateNumericInput,
    SharedRequiredMessage,
} from "../../../../shared/Inputs";
import TriangleAlertSvg from "../../../../../assets/TriangleAlert";
import { notBlankString } from "../../../../../utils";
import { OfferType } from "../../../../../constants";
import {
    useGetAllBusinessEntitiesQuery,
    useCreateOfferMutation,
} from "../../../../../redux/slices/adminApi";

const defaultValues = {
    businessEntityLegalName: "",
    offerType: "",
    principal: "",
    numberOfPayments: "",
    duration: "",
};

const offerTypeOptions = [
    { value: OfferType.INSTALLMENTS, label: "Installments" },
    { value: OfferType.SINGLE_PAYMENT, label: "Single Payment" },
];

const schema = yup
    .object({
        businessEntityLegalName: yup
            .string()
            .required(SharedRequiredMessage)
            .test(
                "not-whitespace-only",
                "Please select a valid business entity",
                notBlankString
            ),
        offerType: yup
            .string()
            .required(SharedRequiredMessage)
            .oneOf(
                [OfferType.INSTALLMENTS, OfferType.SINGLE_PAYMENT],
                "Please select a valid offer type"
            ),
        principal: yup
            .number()
            .typeError("Principal must be a valid number")
            .min(0.01, "Principal must be greater than 0")
            .required(SharedRequiredMessage),
        numberOfPayments: yup.mixed().when("offerType", {
            is: OfferType.INSTALLMENTS,
            then: () =>
                yup
                    .number()
                    .typeError("Number of payments must be a valid number")
                    .min(1, "Number of payments must be at least 1")
                    .required(SharedRequiredMessage),
            otherwise: () => yup.string(),
        }),
        duration: yup.mixed().when("offerType", {
            is: OfferType.SINGLE_PAYMENT,
            then: () =>
                yup
                    .number()
                    .typeError("Duration must be a valid number")
                    .min(1, "Duration must be at least 1 day")
                    .required(SharedRequiredMessage),
            otherwise: () => yup.string(),
        }),
    })
    .required();

function CreateOffer() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const { data: allBusinessEntities } = useGetAllBusinessEntitiesQuery();
    const [createOffer] = useCreateOfferMutation();

    const testForErrors = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return false;
        } catch (err) {
            // Handle Yup validation errors
            if (err.inner) {
                const newErrors = {};
                err.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
                setErrors(newErrors);
            }
            return true;
        }
    };

    const onSubmit = async () => {
        const hasErrors = await testForErrors();
        if (hasErrors) return;

        let body;
        if (formData.offerType === OfferType.INSTALLMENTS) {
            body = {
                legal_name: formData.businessEntityLegalName,
                offer_type: formData.offerType,
                principal: Math.round(parseFloat(formData.principal) * 100),
                number_of_payments: parseInt(formData.numberOfPayments, 10),
            };
        } else {
            body = {
                legal_name: formData.businessEntityLegalName,
                offer_type: formData.offerType,
                principal: Math.round(parseFloat(formData.principal) * 100),
                duration: parseInt(formData.duration, 10),
            };
        }

        try {
            await createOffer(body);
            navigate("/draws?tab=viewOffers", { replace: true });
        } catch (err) {
            // Intentionally empty - errors are handled elsewhere
        }
    };

    const handleOfferTypeSelect = (selection) => {
        setFormData({
            ...defaultValues,
            offerType: selection,
            businessEntityLegalName: formData.businessEntityLegalName,
        });
    };

    if (!allBusinessEntities) return null;

    return (
        <SharedCard>
            <div className="flex flex-col gap-y-[20px]">
                <SharedSectionTitle className="text-flychainGray">
                    Create Offer
                </SharedSectionTitle>
                <SearchableBusinessEntitySelect
                    formData={formData}
                    setFormData={setFormData}
                    defaultValues={defaultValues}
                    businessEntities={allBusinessEntities}
                    error={errors.businessEntityLegalName}
                />
                <SharedStateSelectInput
                    name="offerType"
                    label="Offer Type *"
                    options={offerTypeOptions}
                    currentValue={formData.offerType}
                    onChange={handleOfferTypeSelect}
                    error={errors.offerType}
                />
                {formData.offerType && formData.businessEntityLegalName && (
                    <>
                        <BusinessAvailableCreditLimitDisplay
                            businessEntityId={
                                allBusinessEntities.find(
                                    (be) =>
                                        be.legal_name ===
                                        formData.businessEntityLegalName
                                ).id
                            }
                        />
                        <SharedStateNumericInput
                            name="principal"
                            label="Principal *"
                            currentValue={formData.principal}
                            onChange={(value) =>
                                setFormData({
                                    ...formData,
                                    principal: value,
                                })
                            }
                            error={errors.principal}
                            decimal
                            currency
                        />
                        {formData.offerType === OfferType.INSTALLMENTS ? (
                            <SharedStateNumericInput
                                name="numberOfPayments"
                                label="Number of Payments *"
                                currentValue={formData.numberOfPayments}
                                onChange={(value) =>
                                    setFormData({
                                        ...formData,
                                        numberOfPayments: value,
                                    })
                                }
                                error={errors.numberOfPayments}
                            />
                        ) : (
                            <SharedStateNumericInput
                                name="duration"
                                label="Duration (days) *"
                                currentValue={formData.duration}
                                onChange={(value) =>
                                    setFormData({
                                        ...formData,
                                        duration: value,
                                    })
                                }
                                error={errors.duration}
                            />
                        )}
                    </>
                )}
                <div className="flex flex-col gap-y-[30px] mt-[10px]">
                    {Object.keys(errors).length > 0 && (
                        <SharedAlert
                            icon={
                                <TriangleAlertSvg
                                    fill="#D32F2F"
                                    width={18}
                                    height={18}
                                />
                            }
                            message="There was an error in the form. Errors are shown under each field."
                            borderColor="border-flychainError"
                        />
                    )}
                    <SharedButton onClick={onSubmit}>Create Offer</SharedButton>
                </div>
            </div>
        </SharedCard>
    );
}

export default CreateOffer;
