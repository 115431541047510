import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import * as Sentry from "@sentry/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import signOut from "./authentication/thunks/signOut";
import { buildDestinations, BUILD_DESTINATION } from "../../buildDestination";

// Initialize the dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const prepareHeaders = (idToken) => (headers) => {
    headers.set("authorization", `Bearer ${idToken}`);
    headers.set("Client-Timezone", "UTC"); // TODO: Figure out a strategy to handle dayjs.tz.guess()
    return headers;
};

const createBaseQuery = (isAdmin) => {
    const reactAppApiUrl =
        BUILD_DESTINATION === buildDestinations.STAGING
            ? process.env.REACT_APP_STAGING_API_URL
            : process.env.REACT_APP_API_URL;
    const baseUrl = isAdmin
        ? `${reactAppApiUrl}/admin`
        : `${reactAppApiUrl}/api`;

    return async (args, api, extraOptions) => {
        // Try to get the token
        let idToken;
        try {
            idToken = await firebase.auth().currentUser.getIdToken();
        } catch (error) {
            Sentry.captureException(error);
            api.dispatch(signOut());
        }

        // Make the request with the token
        const fetchQuery = fetchBaseQuery({
            baseUrl,
            prepareHeaders: prepareHeaders(idToken),
        });
        return fetchQuery(args, api, extraOptions);
    };
};

export default createBaseQuery;
