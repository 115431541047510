import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../utils";
import { useGetOatfiBusinessBalanceQuery } from "../../../../../redux/slices/adminApi";

const BALANCE_BUFFER = 0.8;

function BusinessAvailableCreditLimitDisplay({ businessEntityId }) {
    const { data: businessBalanceData } = useGetOatfiBusinessBalanceQuery({
        businessEntityId,
    });

    if (!businessBalanceData) {
        return null;
    }

    return (
        <div className="flex flex-col gap-y-[10px] py-[12px]">
            <div className="flex flex-row gap-x-[12px] items-center w-fit">
                <p className="font-semibold text-flychainGray text-[16px]">
                    Available Credit Limit:
                </p>
                <p className="font-bold text-[16px]">
                    {getCurrencyString(
                        businessBalanceData.totalAvailableCreditLimit
                    )}
                </p>
            </div>
            <div className="flex flex-row gap-x-[12px] items-center w-fit">
                <p className="font-semibold text-flychainGray text-[16px]">
                    Buffered Credit Limit ({BALANCE_BUFFER * 100}%):
                </p>
                <p className="font-bold text-[16px]">
                    {getCurrencyString(
                        businessBalanceData.totalAvailableCreditLimit *
                            BALANCE_BUFFER
                    )}
                </p>
            </div>
        </div>
    );
}

BusinessAvailableCreditLimitDisplay.propTypes = {
    businessEntityId: PropTypes.string.isRequired,
};

export default BusinessAvailableCreditLimitDisplay;
