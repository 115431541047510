import React from "react";
import SecurityCard from "./SecurityCard";
import { SharedSectionTitle } from "../../../shared/Text";
import LandmarkSvg from "../../../../assets/Landmark";
import ShieldCheckSvg from "../../../../assets/ShieldCheck";
import ScrollTextSvg from "../../../../assets/ScrollText";
import TrophySvg from "../../../../assets/Trophy";

function Security() {
    return (
        <div className="flex flex-col p-[60px] bg-flychainPurple-white">
            <SharedSectionTitle className="mb-[10px]">
                Your Funds Are Safe and Secure
            </SharedSectionTitle>
            <div className="mb-[30px] text-[14px]">
                Our treasury management solution has multiple layers of
                protection to keep your funds safe and secure.
            </div>
            <div className="grid grid-cols-2 gap-[20px]">
                <SecurityCard
                    className="bg-flychainOffWhite"
                    icon={<LandmarkSvg fill="#8D58FF" height={28} width={28} />}
                    title="Your Assets Sit In Your Own Account"
                    subtitle="Your securities are safely stored under your own
                            name in your own account with our third-party
                            custodian, Apex. Apex has more than $100 Bn in
                            custody and is used by companies like Betterment,
                            SoFi, and M1 Finance."
                />
                <SecurityCard
                    className="bg-flychainOffWhite"
                    icon={
                        <ShieldCheckSvg fill="#8D58FF" height={28} width={28} />
                    }
                    title="SIPC Insured"
                    subtitle="SIPC Insurance is the equivalent of FDIC but for
                            brokerage accounts. SIPC protects against the loss
                            of cash and securities up to $500,000.00, which includes a $250,000.00 limit for cash."
                />
                <SecurityCard
                    className="bg-flychainOffWhite"
                    icon={
                        <ScrollTextSvg fill="#8D58FF" height={28} width={28} />
                    }
                    title="Registered With the SEC"
                    subtitle="Treasury management and investment is provided by Treasure Investment Management (“Treasure”), which is registered with the SEC."
                />
                <SecurityCard
                    className="bg-flychainOffWhite"
                    icon={<TrophySvg fill="#8D58FF" height={28} width={28} />}
                    title="35+ Years of Combined Investment Experience"
                    subtitle="Our treasury management team has managed billions of
                            dollars for large hedge funds, asset managers, and
                            pension funds."
                />
            </div>
        </div>
    );
}

export default Security;
