import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { SharedModal, SharedButton } from "../../shared";
import { SharedSectionTitle } from "../../shared/Text";
import useGetUsersQuery from "../../../hooks/queriesAndMutations/useGetUsersQuery";
import { MMMM_D_YYYY_FORMAT } from "../../../utils";

const formatDate = (dateString) => dayjs(dateString).format(MMMM_D_YYYY_FORMAT);

const openNewEmailTab = (emailTo, subject, body) => {
    window.open(
        `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
            emailTo
        )}&cc=${encodeURIComponent(
            "jaime@flychain.us"
        )}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`,
        "_blank", // This ensures it opens in a new tab
        "noopener,noreferrer" // This adds security best practices
    );
};

function DateUpdateEmailModal({ emailModalData, setEmailModalData }) {
    const { businessEntity, provider, validUpUntil, entriesStart } =
        emailModalData;
    const { data: users } = useGetUsersQuery({ providerId: provider.id });

    if (!users) return null;

    const emailTo = users
        .filter((user) => user.email && !user.email.endsWith("@test.ph"))
        .map((user) => user.email)
        .join(",");

    const handleNotifyForProvider = () => {
        const subject = `Bookkeeping Update for ${provider.legal_name}`;
        const body = `Hey ________,\n\nI am pleased to let you know that the books for all business entities under ${
            provider.legal_name
        } are complete for the date range ${formatDate(
            entriesStart
        )} through ${formatDate(validUpUntil)}.\n\n\nThank you,\n\n________`;
        openNewEmailTab(emailTo, subject, body);
        setEmailModalData(null);
    };

    const handleNotifyForBusinessEntity = () => {
        const subject = `Bookkeeping Update for ${businessEntity.legal_name}`;
        const body = `Hey ________,\n\nI am pleased to let you know that the books for the business entity ${
            businessEntity.legal_name
        } are complete for the date range ${formatDate(
            entriesStart
        )} through ${formatDate(validUpUntil)}.\n\n\nThank you,\n\n________`;
        openNewEmailTab(emailTo, subject, body);
        setEmailModalData(null);
    };

    const handleClose = () => {
        setEmailModalData(null);
    };

    return (
        <SharedModal onClose={handleClose} cardClassName="w-[600px] h-fit">
            <div className="flex flex-col gap-y-[20px]">
                <SharedSectionTitle>
                    Notify Client of Bookkeeping Date Update
                </SharedSectionTitle>
                <div className="text-[14px] text-flychainGray flex flex-col gap-y-[15px]">
                    <div>
                        You have updated the bookkeeping dates for the business
                        entity{" "}
                        <span className="font-semibold">
                            {businessEntity.legal_name}
                        </span>
                        .
                    </div>
                    <div className="flex flex-row gap-x-[10px]">
                        <div>
                            Entries Start:{" "}
                            <span className="font-semibold">
                                {formatDate(entriesStart)}
                            </span>
                        </div>
                        <div>
                            Valid Up Until:{" "}
                            <span className="font-semibold">
                                {formatDate(validUpUntil)}
                            </span>
                        </div>
                    </div>
                    <div>
                        If you would like to notify the client, please select
                        the email version you would like to send. Otherwise, you
                        can close this window.
                    </div>
                </div>
                <div className="flex flex-row gap-x-[30px] mt-[20px] justify-center">
                    <SharedButton onClick={handleNotifyForProvider}>
                        Notify for Provider
                    </SharedButton>
                    <SharedButton onClick={handleNotifyForBusinessEntity}>
                        Notify for Business Entity
                    </SharedButton>
                </div>
            </div>
        </SharedModal>
    );
}

export default DateUpdateEmailModal;

DateUpdateEmailModal.propTypes = {
    emailModalData: PropTypes.object.isRequired,
    setEmailModalData: PropTypes.func.isRequired,
};
