import { useParams } from "react-router-dom";
import { useConfirmOfferMutation as useAdminConfirmOfferMutation } from "../../../redux/slices/adminApi";
import { useConfirmOfferMutation as useProviderConfirmOfferMutation } from "../../../redux/slices/api";

const useConfirmOfferMutation = () => {
    const urlParams = useParams();
    const providerId = urlParams.providerId || null;

    // If providerId is provided via url params, use the admin mutation
    if (providerId) {
        return useAdminConfirmOfferMutation();
    }

    // If providerId is not provided at all, use the provider mutation
    return useProviderConfirmOfferMutation();
};

export default useConfirmOfferMutation;
