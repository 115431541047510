import React from "react";
import { SharedButton } from "../shared";
import { SharedPageTitle } from "../shared/Text";
import FlychainLogoSvg from "../../assets/FlychainLogo.svg";

function ErrorFallback() {
    const onClick = () => {
        window.location.href = window.location.origin; // Redirects to the root of the current domain
    };

    return (
        <div className="flex relative flex-col justify-center items-center w-screen h-screen bg-flychainPurple-white">
            <img
                className="absolute top-[20px] left-[20px] w-[15vw]"
                src={FlychainLogoSvg}
                alt="FlychainLogo"
            />
            <div className="flex flex-col gap-y-[20px] mb-[30px]">
                <SharedPageTitle>An unexpected error occured</SharedPageTitle>
                <div className="text-[16px] font-[400]">
                    Please refresh the page by clicking the button below
                </div>
            </div>
            <SharedButton onClick={onClick}>Refresh</SharedButton>
        </div>
    );
}

export default ErrorFallback;
