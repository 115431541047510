import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { SharedSectionTitle } from "../../../../shared/Text";
import { getCurrencyString, MMMM_D_YYYY_FORMAT } from "../../../../../utils";

function PostCollectionDrawsTable({ draws }) {
    const renderTableRows = () =>
        draws.map((draw) => (
            <div
                key={draw.id}
                className="grid grid-cols-[2.5fr_1fr_2fr_1.5fr] gap-x-4 text-sm items-center border-b border-gray-200 py-[12px]"
            >
                <div>{draw.id}</div>
                <div>{getCurrencyString(draw.fee)}</div>
                <div>
                    {dayjs(draw.ach_sent_date).format(MMMM_D_YYYY_FORMAT)}
                </div>
                <div>{getCurrencyString(draw.principal)}</div>
            </div>
        ));

    return (
        <div className="flex flex-col gap-y-[10px]">
            <SharedSectionTitle className="text-flychainGray">
                Draws
            </SharedSectionTitle>
            {draws.length > 0 ? (
                <div className="flex flex-col">
                    <div className="grid grid-cols-[2.5fr_1fr_2fr_1.5fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                        <div>Draw ID</div>
                        <div>Draw Fee</div>
                        <div>ACH Sent Date</div>
                        <div>Principal</div>
                    </div>
                    {renderTableRows()}
                </div>
            ) : (
                "No draws found for the provider"
            )}
        </div>
    );
}

PostCollectionDrawsTable.propTypes = {
    draws: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default PostCollectionDrawsTable;
