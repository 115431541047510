import React from "react";
import PropTypes from "prop-types";
import { SharedButton, SharedCard } from "../../../shared";
import { SharedSectionTitle } from "../../../shared/Text";

function CapitalProduct({ title, body, onClick, comingSoon }) {
    const content = (
        <>
            <SharedSectionTitle>{title}</SharedSectionTitle>
            <div className="flex flex-col justify-between items-center grow gap-y-[20px]">
                <div className="text-center mt-[20px] text-flychainGray font-normal">
                    {body}
                </div>
                {onClick ? (
                    <SharedButton onClick={onClick}>Learn More</SharedButton>
                ) : null}
            </div>
        </>
    );

    return comingSoon ? (
        <SharedCard
            showTab
            tabText="Coming Soon"
            tabColor="green"
            className="flex flex-col items-center w-full"
            tabContainerClassName="w-5/12"
        >
            {content}
        </SharedCard>
    ) : (
        <SharedCard
            showTab={false}
            className="flex flex-col items-center w-5/12"
        >
            {content}
        </SharedCard>
    );
}

CapitalProduct.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    comingSoon: PropTypes.bool,
};

CapitalProduct.defaultProps = {
    onClick: undefined,
    comingSoon: false,
};

export default CapitalProduct;
