import React, { useState } from "react";
import PropTypes from "prop-types";
import BusinessEntity from "./BusinessEntity";
import { SharedCard } from "../../../shared";
import { SharedSectionTitle } from "../../../shared/Text";
import DownCaret from "../../../../assets/DownCaret.svg";
import RightCaret from "../../../../assets/RightCaret.svg";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";

function Provider({ provider, setEmailModalData }) {
    const [showBusinessEntities, setShowBusinessEntities] = useState(false);
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId: provider.id,
        includeIncomplete: false,
    });

    if (!businessEntities) {
        return null;
    }

    return (
        <SharedCard padding={15} className="flex flex-col gap-y-[15px]">
            <div className="flex flex-row justify-between items-center gap-x-[10px]">
                <SharedSectionTitle>{provider.legal_name}</SharedSectionTitle>
                <div className="text-sm text-flychainGray">{provider.id}</div>
            </div>
            <div className="flex flex-col gap-y-[15px]">
                <div
                    className="flex flex-row gap-x-[8px] items-center w-fit cursor-pointer"
                    onClick={() =>
                        setShowBusinessEntities(!showBusinessEntities)
                    }
                >
                    <div className="font-semibold">
                        Business Entities ({businessEntities.length})
                    </div>
                    <img
                        src={showBusinessEntities ? DownCaret : RightCaret}
                        alt="Toggle"
                        className="w-[10px] h-[10px] brightness-0"
                    />
                </div>
                {showBusinessEntities &&
                    businessEntities.map((businessEntity) => (
                        <div
                            key={businessEntity.id}
                            className="flex flex-col pt-[15px] border-t-[1px] border-gray-300"
                        >
                            <BusinessEntity
                                businessEntity={businessEntity}
                                provider={provider}
                                setEmailModalData={setEmailModalData}
                            />
                        </div>
                    ))}
            </div>
        </SharedCard>
    );
}

Provider.propTypes = {
    provider: PropTypes.object.isRequired,
    setEmailModalData: PropTypes.func.isRequired,
};

export default Provider;
