import React from "react";
import TrustedAdvisorStat from "./TrustedAdvisorStat";
import { SharedSectionTitle } from "../../../shared/Text";

// TODO: add more stats when the Treasure compliance team gets back to us.
function TrustedAdvisor() {
    return (
        <div className="flex flex-col p-[60px]">
            <SharedSectionTitle className="mb-[10px]">
                A Trusted and Credible Advisor
            </SharedSectionTitle>
            <div className="mb-[30px] text-[14px]">
                Our treasury management team has managed billions of dollars for
                large hedge funds, asset managers, and pension funds.
            </div>
            <div className="flex flex-row gap-x-[50px] justify-around">
                <TrustedAdvisorStat title="300+" subtitle="customers" />
                <TrustedAdvisorStat title="1,500+" subtitle="funds tracked" />
                <TrustedAdvisorStat
                    title="35+"
                    subtitle="years of combined investment experience"
                />
            </div>
        </div>
    );
}

export default TrustedAdvisor;
