import React from "react";
import PropTypes from "prop-types";
import CompleteSvg from "./SignUpFlowBadges/Complete.svg";
import CurrentSvg from "./SignUpFlowBadges/Current.svg";
import IncompleteSvg from "./SignUpFlowBadges/Incomplete.svg";

function Complete() {
    return (
        <img className="w-[30px] h-[30px]" src={CompleteSvg} alt="Complete" />
    );
}

function Current() {
    return <img className="w-[30px] h-[30px]" src={CurrentSvg} alt="Current" />;
}

function Incomplete() {
    return (
        <img
            className="w-[30px] h-[30px]"
            src={IncompleteSvg}
            alt="Incomplete"
        />
    );
}

export const TAB_STATES = {
    COMPLETE: "COMPLETE",
    CURRENT: "CURRENT",
    INCOMPLETE: "INCOMPLETE",
    LAST: "LAST",
};

const getStateBadge = (state) => {
    switch (state) {
        case TAB_STATES.COMPLETE:
            return <Complete />;
        case TAB_STATES.CURRENT:
            return <Current />;
        case TAB_STATES.INCOMPLETE:
            return <Incomplete />;
        case TAB_STATES.LAST:
            return <Complete />;
        default:
            return <Complete />;
    }
};

function SignUpFlowTab({ children, state }) {
    return (
        <div
            className={`flex flex-row items-center h-[60px] pl-[30px] gap-x-[10px] ${
                state === TAB_STATES.CURRENT || state === TAB_STATES.LAST
                    ? "bg-gradient-to-tr from-flychainBlue to-flychainPurple-light"
                    : ""
            }`}
        >
            {getStateBadge(state)}
            <div className="text-white">{children}</div>
        </div>
    );
}

SignUpFlowTab.propTypes = {
    children: PropTypes.string.isRequired,
    state: PropTypes.oneOf(Object.values(TAB_STATES)).isRequired,
};

export default SignUpFlowTab;
