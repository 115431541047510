import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import LeftCaretSvg from "../../../assets/LeftCaret.svg";

function BackIconButton({ onClick }) {
    const navigate = useNavigate();
    const defaultOnClick = () => {
        navigate(-1);
    };

    return (
        <div
            className="w-[20px] h-[20px] cursor-pointer hover:opacity-50 brightness-0 items-center justify-center"
            onClick={onClick || defaultOnClick}
        >
            <img className="w-[10px]" src={LeftCaretSvg} alt="LeftCaret" />
        </div>
    );
}

BackIconButton.propTypes = {
    onClick: PropTypes.func,
};

BackIconButton.defaultProps = {
    onClick: undefined,
};

export default BackIconButton;
