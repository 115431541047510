/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { getCurrencyString } from "../../../utils";
import { SharedTable } from "../../shared";
import RightCaretSvg from "../../../assets/RightCaret.svg";

function LedgerTable({ data, headers }) {
    const navigate = useNavigate();

    const rows = data.map((entry) => {
        const { ledger_name, proportion, amount_cents, ledger_id } = entry;
        const onClick = () => {
            navigate(`ledgers/${ledger_id}`);
        };
        return [
            <span className="text-xs font-medium text-flychainGray">
                {ledger_name}
            </span>,
            `${proportion.toFixed(2)}%`,
            getCurrencyString(amount_cents),
            <div className="flex flex-row justify-center items-center w-full">
                <img
                    className="h-[15px] cursor-pointer"
                    src={RightCaretSvg}
                    alt="RightCaretSvg"
                    onClick={onClick}
                />
            </div>,
        ];
    });

    return <SharedTable headers={headers} rows={rows} />;
}

LedgerTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default LedgerTable;
