import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { SharedButton, SharedCard } from "../../../shared";
import { SharedSectionTitle } from "../../../shared/Text";
import Select from "../../../Select";
import ChevronDownSvg from "../../../../assets/ChevronDown";
import ChevronRightSvg from "../../../../assets/ChevronRight";
import {
    useUpdatePlaidAccountMappingMutation,
    useLinkPlaidAccountToTealMutation,
} from "../../../../redux/slices/adminApi";

const businessEntityForPlaidAccount = (businessEntities, businessEntityId) => {
    const foundEntity = businessEntities.find(
        (businessEntity) => businessEntity.id === businessEntityId
    );
    return foundEntity;
};

const getBusinessEntityOptions = (businessEntities) =>
    businessEntities.map((businessEntity) => ({
        value: businessEntity.id,
        label: (
            <div className="flex flex-col">
                <div className="text-[14px]">{businessEntity.legal_name}</div>
                <div className="text-flychainGray text-[12px]">
                    {businessEntity.id}
                </div>
            </div>
        ),
    }));

function AccountMappingTable({
    title,
    defaultOpen,
    businessEntities,
    plaidAccountMappings,
}) {
    const { providerId } = useParams();
    const [showTable, setShowTable] = useState(defaultOpen);
    const [updatePlaidAccountMapping] = useUpdatePlaidAccountMappingMutation();
    const [linkPlaidAccountToTeal] = useLinkPlaidAccountToTealMutation();

    const updateAccountMapping = (
        newBusinessEntityId,
        internalPlaidAccountId
    ) => {
        updatePlaidAccountMapping({
            providerId,
            body: {
                new_business_entity_id: newBusinessEntityId,
                internal_plaid_account_id: internalPlaidAccountId,
            },
        });
    };

    const renderAccountMappingTableRows = () =>
        plaidAccountMappings.map((plaidAccountMapping) => {
            const {
                raw_plaid_account: rawPlaidAccount,
                internal_teal_instance_id: internalTealInstanceId,
                business_entity_id: businessEntityId,
            } = plaidAccountMapping;
            const businessEntity = businessEntityForPlaidAccount(
                businessEntities,
                businessEntityId
            );

            return (
                <div
                    key={plaidAccountMapping.id}
                    className="grid grid-cols-[2fr_1.5fr_1fr_2fr_1fr] gap-x-[10px] text-sm items-center border-b border-gray-200 py-[12px]"
                >
                    <div>{plaidAccountMapping.id}</div>
                    <div>{rawPlaidAccount.name}</div>
                    <div className="flex justify-center w-full">
                        (••••{rawPlaidAccount.mask})
                    </div>
                    {internalTealInstanceId ? (
                        <>
                            <div className="flex flex-col">
                                <div className="text-[14px]">
                                    {businessEntity.legal_name}
                                </div>
                                <div className="text-flychainGray text-[12px]">
                                    {businessEntity.id}
                                </div>
                            </div>
                            <div className="flex justify-center w-full">
                                <Tooltip
                                    title={`Internal Teal Instance ID: ${internalTealInstanceId}`}
                                >
                                    <div className="font-semibold cursor-help text-flychainGreen">
                                        Linked
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex justify-center items-center w-fit">
                                <Select
                                    label="Select Business Entity"
                                    value={businessEntity.id}
                                    options={getBusinessEntityOptions(
                                        businessEntities
                                    )}
                                    onChange={(event) =>
                                        updateAccountMapping(
                                            event.value,
                                            plaidAccountMapping.id
                                        )
                                    }
                                    alignment="right"
                                />
                            </div>
                            <div className="flex justify-center items-center w-full">
                                <SharedButton
                                    onClick={() =>
                                        linkPlaidAccountToTeal({
                                            internal_plaid_account_id:
                                                plaidAccountMapping.id,
                                        })
                                    }
                                >
                                    Link Account
                                </SharedButton>
                            </div>
                        </>
                    )}
                </div>
            );
        });

    const renderAccountMappingTable = () => {
        if (!showTable) {
            return null;
        }

        return (
            <div className="flex flex-col gap-y-[25px]">
                <div className="flex flex-col">
                    <div className="grid grid-cols-[2fr_1.5fr_1fr_2fr_1fr] gap-x-[10px] text-[14px] font-semibold border-b border-flychainGray py-[8px]">
                        <div>Plaid Account ID</div>
                        <div>Account Name</div>
                        <div className="flex justify-center w-full">Mask</div>
                        <div>Business Entity</div>
                        <div className="flex justify-center w-full">
                            Teal Link
                        </div>
                    </div>
                    {renderAccountMappingTableRows()}
                </div>
            </div>
        );
    };

    const renderDropdownIcon = () => {
        if (showTable) {
            return <ChevronDownSvg fill="#757575" width={24} height={24} />;
        }
        return <ChevronRightSvg fill="#757575" width={24} height={24} />;
    };

    return (
        <SharedCard>
            <div className="flex flex-col gap-y-[15px]">
                <div
                    className="flex flex-row items-center gap-x-[8px] cursor-pointer"
                    onClick={() => setShowTable(!showTable)}
                >
                    <SharedSectionTitle className="text-flychainGray">
                        {title}
                    </SharedSectionTitle>
                    {plaidAccountMappings.length > 0 && renderDropdownIcon()}
                </div>
                {plaidAccountMappings.length > 0 ? (
                    renderAccountMappingTable()
                ) : (
                    <div>No accounts found</div>
                )}
            </div>
        </SharedCard>
    );
}

AccountMappingTable.propTypes = {
    title: PropTypes.string.isRequired,
    defaultOpen: PropTypes.bool.isRequired,
    businessEntities: PropTypes.array.isRequired,
    plaidAccountMappings: PropTypes.array.isRequired,
};

export default AccountMappingTable;
