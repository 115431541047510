import React from "react";
import PropTypes from "prop-types";

function SectionTitle({ children, className }) {
    return (
        <div className={`font-semibold text-[20px] ${className}`}>
            {children}
        </div>
    );
}

SectionTitle.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
};

SectionTitle.defaultProps = {
    className: "",
};

export default SectionTitle;
