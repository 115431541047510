import React from "react";
import PropTypes from "prop-types";

function Alert({ icon, message, borderColor }) {
    return (
        <div
            className={`flex flex-row items-center w-full shadow-md p-[12px] rounded-[4px] border-[1px] ${borderColor} gap-x-[12px]`}
        >
            {icon}
            <div className="text-[14px] text-flychainPurple-dark">
                {message}
            </div>
        </div>
    );
}

Alert.propTypes = {
    icon: PropTypes.node.isRequired,
    message: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node.isRequired,
    ]).isRequired,
    borderColor: PropTypes.string.isRequired,
};

export default Alert;
