import React from "react";
import { useSavePlaidItemsMutation } from "../../redux/slices/api";
import SharedConnections from "../Connections";
import { SharedAlert, SharedButton } from "../shared";
import { SharedSectionTitle } from "../shared/Text";
import TriangleAlertSvg from "../../assets/TriangleAlert";
import useGetPlaidItemsQuery from "../../hooks/queriesAndMutations/useGetPlaidItemsQuery";

function ConnectPlaidItems() {
    const [savePlaidItems] = useSavePlaidItemsMutation();

    const onSaveAndContinue = () => {
        savePlaidItems();
    };

    const { data: plaidItems } = useGetPlaidItemsQuery();

    return (
        <div className="flex flex-col p-[60px] gap-y-[30px]">
            <SharedSectionTitle>
                Please connect your business bank accounts and corporate cards
            </SharedSectionTitle>
            <div className="text-[15px] font-[300]">
                Once you&apos;ve connected all business bank accounts and
                corporate cards, please click &quot;Save & Continue&quot;.
            </div>
            <SharedAlert
                icon={
                    <TriangleAlertSvg fill="#8D58FF" width={20} height={20} />
                }
                message="If you have more than one business entity, please connect the business bank accounts and corporate cards for all entities."
                borderColor="border-flychainPurple-light"
            />
            <SharedConnections />
            <SharedButton
                color="secondary"
                onClick={onSaveAndContinue}
                disabled={!plaidItems || plaidItems.length === 0}
            >
                Save & Continue
            </SharedButton>
        </div>
    );
}

export default ConnectPlaidItems;
