import React, { useState, useCallback, useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import { SharedButton, SharedModal } from "../shared";
import { SharedSectionTitle, SharedSubheading } from "../shared/Text";

function MobileModal() {
    const [showMobileModal, setShowMobileModal] = useState(false);

    useEffect(() => {
        if (isMobileOnly) {
            setShowMobileModal(true);
        }
    }, []);

    const onClose = useCallback(() => {
        setShowMobileModal(false);
    }, [setShowMobileModal]);

    return showMobileModal ? (
        <SharedModal onClose={onClose}>
            <div className="flex flex-col justify-start">
                <SharedSectionTitle>
                    Not optimized for mobile
                </SharedSectionTitle>
                <SharedSubheading>
                    This website is not currently optimized for mobile devices.
                    While this website will work on mobile, we recommend
                    accessing it on desktop or tablet instead.
                </SharedSubheading>
                <div className="my-[20px]">
                    <SharedButton onClick={onClose}>
                        Proceed on mobile device
                    </SharedButton>
                </div>
            </div>
        </SharedModal>
    ) : null;
}

export default MobileModal;
