import React from "react";
import PropTypes from "prop-types";
import { SharedCard, SharedModal } from "../../shared";
import { SharedSectionTitle } from "../../shared/Text";

function EBITDAMultiplesModal({ onClose }) {
    return (
        <SharedModal onClose={onClose} cardClassName="w-[60vw]">
            <div className="flex flex-col gap-y-[20px] items-center">
                <SharedSectionTitle className="text-center">
                    What Factors Affect Your EBITDA Multiples?
                </SharedSectionTitle>
                <div className="w-2/3 font-normal text-[14px] text-center mb-[10px]">
                    In healthcare, EBITDA multiples can range from 3x to as high
                    as 15x. The multiples that are ultimately applied when
                    valuing your practice depend on several key factors
                    including:
                </div>
                <div className="flex flex-row justify-center gap-x-[30px] w-full">
                    <SharedCard
                        showTab={false}
                        className="flex flex-col w-[15vw] gap-y-[20px]"
                    >
                        <div className="text-[14px] font-semibold">
                            Specialty
                        </div>
                        <div className="text-[12px] font-normal">
                            Different healthcare specialties have different
                            multiples. Some are more in demand than others among
                            buyers, which can significantly affect the valuation
                            multiple.
                        </div>
                    </SharedCard>
                    <SharedCard
                        showTab={false}
                        className="flex flex-col w-[15vw] gap-y-[20px]"
                    >
                        <div className="text-[14px] font-semibold">Revenue</div>
                        <div className="text-[12px] font-normal">
                            The larger your practice, the higher the multiples.
                            Bigger practices tend to be more attractive to
                            buyers because they bring in more consistent revenue
                            streams.
                        </div>
                    </SharedCard>
                    <SharedCard
                        showTab={false}
                        className="flex flex-col w-[15vw] gap-y-[20px]"
                    >
                        <div className="text-[14px] font-semibold">
                            Profitability
                        </div>
                        <div className="text-[12px] font-normal">
                            Highly profitable practices command higher premiums.
                            Buyers are willing to pay more for businesses that
                            demonstrate strong profitability over time.
                        </div>
                    </SharedCard>
                </div>
                <div className="flex flex-row justify-center gap-x-[30px] w-full">
                    <SharedCard
                        showTab={false}
                        className="flex flex-col w-[15vw] gap-y-[20px]"
                    >
                        <div className="text-[14px] font-semibold">
                            Growth Rate
                        </div>
                        <div className="text-[12px] font-normal">
                            Practices with faster growth rates typically receive
                            a valuation premium. A strong growth trajectory
                            shows potential buyers that the business has room to
                            expand.
                        </div>
                    </SharedCard>
                    <SharedCard
                        showTab={false}
                        className="flex flex-col w-[15vw] gap-y-[20px]"
                    >
                        <div className="text-[14px] font-semibold">
                            Other Factors
                        </div>
                        <div className="text-[12px] font-normal">
                            Other factors, such as payer mix, the tenure of your
                            staff, and patient satisfaction, also play into your
                            valuation.
                        </div>
                    </SharedCard>
                </div>
            </div>
        </SharedModal>
    );
}

EBITDAMultiplesModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default EBITDAMultiplesModal;
