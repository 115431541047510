import React from "react";
import { SharedBanner } from "../../shared";

function Disclaimers() {
    return (
        <SharedBanner
            body={
                <div className="flex flex-col w-full text-[14px] font-normal gap-y-[20px] text-start">
                    <p className="font-semibold">Disclaimers</p>
                    <p>
                        1. Mercury is a fintech company,{" "}
                        <a
                            href="https://mercury.com/how-mercury-works"
                            target="_blank"
                            rel="noreferrer"
                        >
                            not an FDIC-insured bank
                        </a>
                        . Banking services provided by{" "}
                        <a
                            href="https://co-mercury-prod.s3.amazonaws.com/legal/Choice+-+Mercury+Deposit+Program+Disclosure+Statement+-+12_21_22.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Choice Financial Group
                        </a>{" "}
                        and{" "}
                        <a
                            href="https://co-mercury-prod.s3.amazonaws.com/legal/Evolve+-+Deposit+Program+Custodial+Agreement+-+12_21_22.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Evolve Bank & Trust ®
                        </a>
                        , Members FDIC. Deposit insurance covers the failure of
                        an insured bank.
                    </p>
                    <p>
                        2. Mercury is a fintech company, not an FDIC-insured
                        bank. Deposits in checking and savings accounts are held
                        by Mercury&apos;s banking services partners,{" "}
                        <a
                            href="https://co-mercury-prod.s3.amazonaws.com/legal/Choice+-+Mercury+Deposit+Program+Disclosure+Statement+-+12_21_22.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Choice Financial Group
                        </a>{" "}
                        and{" "}
                        <a
                            href="https://co-mercury-prod.s3.amazonaws.com/legal/Evolve+-+Deposit+Program+Custodial+Agreement+-+12_21_22.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Evolve Bank & Trust ®
                        </a>
                        , Members FDIC. Deposit insurance covers the failure of
                        an insured bank. Certain conditions must be satisfied
                        for pass-through insurance to apply. Learn more{" "}
                        <a
                            href="https://mercury.com/blog/understanding-bank-sweep-network"
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>
                        .
                    </p>
                    <p>
                        3. The Mercury Debit Cards are issued by Choice
                        Financial Group and Evolve Bank & Trust, Members FDIC,
                        pursuant to licenses from Mastercard. The IO Card is
                        issued by Patriot Bank, Member FDIC, pursuant to a
                        license from Mastercard.
                    </p>
                </div>
            }
        />
    );
}

export default Disclaimers;
